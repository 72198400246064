import React, { useCallback, useEffect } from "react";
import { useGoogleMaps } from "react-hook-google-maps";
import { Geometry } from "geojson";
import useTranslation from "src/translations/useTranslation";
import mapDefaultParameters from "@utils/parameters";
import { ContainerMapFixedPointCoord } from "./styles";

type MapFixedPointCoordProps = {
  mapHeight: number,
  geometry: Geometry
}
const MapFixedRegion = React.memo<MapFixedPointCoordProps>(({ mapHeight, geometry }) => {

  const { i18n } = useTranslation();

  const GoogleApiKey = localStorage.getItem("@Fleet:google");
  const { ref, map } = useGoogleMaps(
    `${GoogleApiKey}&libraries=places,drawing&language=${i18n.language}`,
    {
      center: mapDefaultParameters.center,
      zoom: 15
    }
  );

  /**
   * Draw map with point in coords passed in props
   */
  const drawGeometry = useCallback(async () => {
    if (geometry && map) {
      // Remove any existing data
      map.data.forEach((feature) => {
        map.data.remove(feature);
      });

      // Create a GeoJSON Feature
      const feature = {
        type: "Feature",
        geometry
      };

      // Add the GeoJSON Feature to the map
      map.data.addGeoJson(feature);

      // Optionally style the data layer
      map.data.setStyle({
        fillColor: "#000000",
        strokeColor: "#FFA500",
        strokeWeight: 2
      });

      // Fit map to the geometry's bounds
      const bounds = new google.maps.LatLngBounds();

      map.data.forEach((feature) => {
        feature.getGeometry().forEachLatLng((latlng) => {
          bounds.extend(latlng);
        });
      });
      map.fitBounds(bounds);
    }
  }, [geometry, map]);

  useEffect(() => {

    // When map is render, draw according point
    if (map) drawGeometry().then();

  }, // drawPoint function duplicates request to Google API if it's present in dependency array
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [map, geometry]);

  return (
    <ContainerMapFixedPointCoord ref={ref} style={{ width: "100%", height: mapHeight }} />
  );
});

export default MapFixedRegion;
