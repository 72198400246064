/* eslint-disable max-len */

import React, { useCallback, useEffect, useRef, useState } from "react";

// region Assets
import InConstructionBombState from "@assets/icons/timeline/in-construction-state.svg";
import PumpingBombState from "@assets/icons/timeline/pumping-state.svg";
import LazyBombState from "@assets/icons/timeline/lazy-state.svg";
import SummaryConstruction from "@assets/icons/timeline/summary-construction.svg";
import SummaryClock from "@assets/icons/timeline/summary-clock.svg";
import SummaryDriver from "@assets/icons/timeline/summary-driver.svg";
import BombIcon from "@assets/icons/bomba.svg";
import BTIncoming from "@assets/icons/timeline/bt-incoming.svg";
import BTInConstruction from "@assets/icons/timeline/bt-in-construction.svg";
import BTCharging from "@assets/icons/timeline/bt-charging.svg";
import BTTrustedLocation from "@assets/icons/timeline/bt-trusted-location.svg";
import PumpIncoming from "@assets/icons/timeline/pump-incoming.svg";
import PumpTrustedLocation from "@assets/icons/timeline/pump-trusted-location.svg";
import ItemLabelIndicator from "@assets/icons/timeline/itemLabelIndicator.svg";
// endregion Assets
// region Libraries
import Timeline, {
  CustomHeader,
  DateHeader,
  ReactCalendarItemRendererProps,
  TimelineGroupBase,
  TimelineHeaders,
  TimelineItemBase
} from "react-calendar-timeline";
import { useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import { TextField } from "unform-material-ui";
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import { ReactSVG } from "react-svg";
import moment from "moment";
import "moment/locale/pt-br";
import * as dateFns from "date-fns";
// endregion Libraries
// region Languages
import useTranslation from "src/translations/useTranslation";
import { GlobalMessages, PumpTimelineMessages, VehiclesStatesTypesMessages } from "@shared/languages/interfaces";
// endregion Languages
// region Store
import { VehiclesStatesObj } from "src/store/ducks/Vehicles/VehiclesStates/vehicles-states.type";
// endregion Store
// region Interfaces
import { Vehicle } from "@shared/interfaces/vehicle.interface";
import { Travel } from "@shared/interfaces/travel.interface";
import { VehicleState } from "@shared/interfaces/vehicle-state.interface";
import { PumpStatus } from "@shared/interfaces/pump-status.interface";
// endregion Interfaces
// region Constants
import {
  PumpStatusTypes,
  PumpStatusTypesColors,
  PumpTimelineGroupsHeights,
  PumpTimelineTypes
} from "@shared/constants/pump-timeline-types.enum";
import { VehicleTypes, VehicleTypesID } from "@shared/constants/vehicle-types.enum";
import {
  VehicleStatesTypes,
  VehicleStatesTypesColors,
  VehicleStatesTypesID
} from "@shared/constants/vehicle-states-types.enum";
// endregion Constants
// region Components
import DialogLandscape from "@atoms/DialogLandscape";
import Span from "@atoms/Span";
import TimeLineItem from "@components/TimeLines/TimeLineVehicles/TimeLineVehiclesItem";
import FloatingMenu from "@components/FloatingMenu";
import Header from "@components/Header";
// endregion Components
// region Services
import api from "@services/api";
// endregion Services
// region Hooks
import { useToast } from "@hooks/useToast";
// endregion Hooks
// region Utils
import utils from "@utils/useful-functions";
import { formatHourMeter } from "@shared/utils/useful-functions";
// endregion Utils
// region Styles
import { BombHeader, CardsContainer, ChartContainer, Container, InfoLabel, LoadingContainer } from "./styles";
import "./Timeline.css";
// endregion Styles
// region Types
export type PumpAndMixerAssociationType = {
  [pumpId: string]: Vehicle[];
}

type PumpTimelineAccordionStateType = {
  [key: string]: boolean;
}
// endregion Types

// region Internal Interfaces
interface TimelineFormData {date: string}
// endregion Internal Interfaces

const PumpTimeline: React.FC = () => {

  // region States
  const filtersDashboard = useSelector(({ filtersDashboard: state }) => state);
  const vehiclesStates = useSelector(({ vehiclesStates: state }) => state);
  const screen = useSelector(({ screen: state }) => state);

  const [vehicles, setVehicles] = useState<VehiclesStatesObj>(vehiclesStates.all);
  const [vehiclesInRealTimeStates, setVehiclesInRealTimeStates] = useState<Vehicle[]>([]);
  const [pumpVehicles, setPumpVehicles] = useState<Vehicle[]>([]);
  const [concreteMixerVehicles, setConcreteMixerVehicles] = useState<Vehicle[]>([]);
  const [realTimePumpVehicles, setRealTimePumpVehicles] = useState<Vehicle[]>([]);
  const [realTimeConcreteMixerVehicles, setRealTimeConcreteMixerVehicles] = useState<Vehicle[]>([]);
  const [pumpAndMixerAssociations, setPumpAndMixerAssociations] = useState<PumpAndMixerAssociationType>({});
  const [timelineAccordionsStates, setTimelineAccordionsStates] = useState<PumpTimelineAccordionStateType>({});
  const [pumpVehiclesToShowCards, setPumpVehiclesToShowCards] = useState<Vehicle[]>([]);
  const [travels, setTravels] = useState<Travel[]>([]);
  const [realTime, setRealTime] = useState<boolean>(true);
  const [loading, setLoading] = useState(true);
  const [closeAllAccordions, setCloseAllAccordions] = useState<boolean>(false);

  // region React Calendar states
  const [timelineGroups, setTimelineGroups] = useState<TimelineGroupBase[]>([]);
  const prevTimelineGroups = useRef<TimelineGroupBase[]>();
  const prevVehiclesOnTimelineStates = useRef<Vehicle[]>();
  const [timelineItems, setTimelineItems] = useState<TimelineItemBase<number>[]>([]);
  const [visibleTimeStart, setVisibleTimeStart] = useState<number>(new Date().getTime() - 1000 * 60 * 60 * 10);
  const [visibleTimeEnd, setVisibleTimeEnd] = useState<number>(new Date().getTime() + 1000 * 60 * 60 * 10);
  const [clickedItems, setClickedItems] = useState<any>([]);
  const [screenOrientation, setScreenOrientation] = useState("");
  // endregion React Calendar states
  // endregion States

  // region Refs
  const formRef = useRef<FormHandles>(null);
  const timelineScrollRef = useRef<HTMLDivElement>(null);
  // endregion Refs

  // region Hooks
  const { addToast } = useToast();
  const { t, i18n } = useTranslation();
  // endregion Hooks

  moment().locale("pt-br");

  // region Useful functions

  // Function to read travels from API
  const readTravels = useCallback(async (formData: TimelineFormData): Promise<any> => {

    try {
      setLoading(true);

      const { data } = await api.get("/travels/get-travels-by-day", {
        params: {
          startDate: utils.convertDateToISOWithTimezone(new Date(formData.date)),
          finishDate: utils.convertDateToISOWithTimezone(dateFns.addDays(new Date(formData.date), 1))
        }
      });

      if (data.status === "success") {
        // Set timeline visible day to the selected date
        setVisibleTimeStart(new Date(formData.date).getTime() + 1000 * 60 * 60 * 10);
        setVisibleTimeEnd(new Date(formData.date).getTime() + 1000 * 60 * 60 * 18);

        // Set travels to state
        setTravels(data.result as Travel[]);
      } else {
        addToast({ type: "info", title: t(GlobalMessages.alert), description: data.message });
      }

    } catch (error) {

      if (!error.response) addToast({ type: "error", title: t(GlobalMessages.error), description: t(GlobalMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

    } finally {
      setLoading(false);
    }

  }, [addToast, t]);

  // Function to handle date change
  const handleDateChange = useCallback(async (event: React.ChangeEvent) => {

    const date = (event.target as HTMLInputElement).value.toString();

    setTimelineItems([]);
    setTimelineGroups([]);
    setPumpVehicles([]);
    setRealTimePumpVehicles([]);
    setRealTimeConcreteMixerVehicles([]);
    setConcreteMixerVehicles([]);
    setTimelineAccordionsStates({});
    setPumpVehiclesToShowCards([]);
    prevVehiclesOnTimelineStates.current = [];
    setVehiclesInRealTimeStates([]);
    setCloseAllAccordions(true);

    setRealTime(date === new Date().toISOString().slice(0, 10));

    await readTravels({ date });

  }, [readTravels]);

  /**
   * Function to clear timeline and render again when language changes
   * Also get the travels of the selected date
   */
  const handleLanguageChange = useCallback(async () => {

    setLoading(true);

    setTimelineItems([]);
    setTimelineGroups([]);
    setPumpVehicles([]);
    setRealTimePumpVehicles([]);
    setRealTimeConcreteMixerVehicles([]);
    setConcreteMixerVehicles([]);
    setTimelineAccordionsStates({});
    setPumpVehiclesToShowCards([]);
    prevVehiclesOnTimelineStates.current = [];
    setVehiclesInRealTimeStates([]);
    setCloseAllAccordions(true);

    // Get value of date input to read travels
    const dateInput = formRef.current?.getFieldValue("date");

    // Read travels of the selected date
    await readTravels({ date: dateInput });

    setLoading(false);
  }, [readTravels, formRef]);

  /* Get mixers with same destination as Pump Vehicle and set pump and mixer associations
   @param pumpVehicle: Vehicle
   @param pumpVehicleTravels: Travel[]
   */
  const getAssociatedMixers = useCallback((pumpVehicle: Vehicle, pumpVehicleTravels: Travel[]) => {

    let associatedMixers: Vehicle[] = [];
    let associatedMixersTravels: Travel[] = [];

    pumpVehicleTravels.forEach((travel) => {

      // Get mixers with same destination as Pump Vehicle
      const mixersToTheSameDestination = concreteMixerVehicles.filter((mixer) => {

        // Get the finished travels of the mixer
        const mixerTravels = travels.filter((travel) => travel.vehicle.id_vehicle === mixer.id_vehicle
          && travel.finish_date !== null);

        // Check if the mixer has travels to the same destination as the pump
        return mixerTravels.some(
          (mixerTravel) => mixerTravel?.destination?.id_location === travel?.destination?.id_location
            || travel?.related_mixers_travels?.some(
              (relatedMixerTravel) => relatedMixerTravel?.id_travel === mixerTravel?.id_travel
            )
        );
      });

      associatedMixers = [...associatedMixers, ...mixersToTheSameDestination];

      // Real time treatments
      if (realTime) {
        // Get mixers with same destination as Pump Vehicle
        const associatedMixersRealTime = realTimeConcreteMixerVehicles.filter(
          (mixerVehicle) => mixerVehicle.current_travel?.destination?.id_location
            && ((mixerVehicle.current_travel?.destination?.id_location
                === pumpVehicle?.current_travel?.destination?.id_location)
              || (pumpVehicle?.current_travel?.related_mixers_travels?.some(
                (relatedMixerTravel) => relatedMixerTravel?.id_travel === mixerVehicle?.current_travel?.id_travel
              )))
        );

        associatedMixers = [...associatedMixers, ...associatedMixersRealTime];

        // Get the travels of the real time associated mixers that have the same destination as the pump vehicle travel
        // or that are related to the pump vehicle current travel
        associatedMixersRealTime.forEach((mixerVehicle) => {
          if (mixerVehicle.current_travel?.destination?.id_location
            && ((mixerVehicle.current_travel?.destination?.id_location
                === pumpVehicle?.current_travel?.destination?.id_location)
              || (pumpVehicle?.current_travel?.related_mixers_travels?.some(
                (relatedMixerTravel) => relatedMixerTravel?.id_travel === mixerVehicle?.current_travel?.id_travel
              )))
          ) {

            // Add the mixer current travel to the array of associated mixers travels
            associatedMixersTravels = [...associatedMixersTravels, mixerVehicle.current_travel];
          }
        });
      }

      // Remove duplicates
      associatedMixers = associatedMixers.filter(
        (mixer, index) => associatedMixers.findIndex((mixerToFind) => mixerToFind.id_vehicle === mixer.id_vehicle)
          === index
      );

      // Get the travels of the associated mixers that have the same destination as the pump vehicle travel
      // Or that are related to the pump vehicle travel
      const mixersTravelsWithTheSameDestination = travels.filter((item) => associatedMixers.some(
        (mixer) => mixer.id_vehicle === item.vehicle.id_vehicle
          && ((item.destination?.id_location === travel?.destination?.id_location)
            || (travel?.related_mixers_travels?.some(
              (relatedMixerTravel) => relatedMixerTravel?.id_travel === item?.id_travel
            )))
      ));

      associatedMixersTravels = [...associatedMixersTravels, ...mixersTravelsWithTheSameDestination];

      // Sort the associated mixers travels by registration date of the oldest "A CAMINHO" state
      associatedMixersTravels = associatedMixersTravels.sort((a, b) => {

        // Get the travel states of the travels (in reverse to get the oldest first)
        const aTravelStates = a?.states?.reverse();
        const bTravelStates = b?.states?.reverse();

        if (aTravelStates && bTravelStates) {
          const aTravelOnTheWayState = aTravelStates?.find(
            (state) => state.status.id_vehicle_state_type === VehicleStatesTypesID.A_CAMINHO
          );
          const bTravelOnTheWayState = bTravelStates?.find(
            (state) => state.status.id_vehicle_state_type === VehicleStatesTypesID.A_CAMINHO
          );

          return new Date(aTravelOnTheWayState.registration_date).getTime()
            - new Date(bTravelOnTheWayState.registration_date).getTime();
        }

        // If it is the current travel, use the vehicle states to sort (real time)
        const aMixer = realTimeConcreteMixerVehicles.find((mixer) => mixer.current_travel.id_travel === a.id_travel);
        const bMixer = realTimeConcreteMixerVehicles.find((mixer) => mixer.current_travel.id_travel === b.id_travel);

        const aMixerOnTheWayState = aMixer?.states?.reverse().find(
          (state) => state.status.id_vehicle_state_type === VehicleStatesTypesID.A_CAMINHO
        );
        const bMixerOnTheWayState = bMixer?.states?.reverse().find(
          (state) => state.status.id_vehicle_state_type === VehicleStatesTypesID.A_CAMINHO
        );

        if (aMixerOnTheWayState && bMixerOnTheWayState) {
          return new Date(aMixerOnTheWayState.registration_date).getTime()
            - new Date(bMixerOnTheWayState.registration_date).getTime();
        }

        return new Date(a.registration_date).getTime() - new Date(b.registration_date).getTime();
      });

      // Put the associated mixers in the same order as the associated mixers travels without duplicates, including
      // the ones that are in real time (get the vehicle from the real time vehicles)
      associatedMixers = associatedMixersTravels.map((travel) => travel.vehicle
        || realTimeConcreteMixerVehicles.find((mixer) => mixer.current_travel.id_travel === travel.id_travel)).filter(
        (mixer, index) => associatedMixersTravels.map((travel) => travel?.vehicle?.id_vehicle
          || realTimeConcreteMixerVehicles.find(
            (mixer) => mixer.current_travel.id_travel === travel.id_travel
          )?.id_vehicle)
          .indexOf(mixer.id_vehicle) === index
      );

    });

    // Set pump and mixer associations (in reverse order to display the oldest first)
    setPumpAndMixerAssociations(
      (prevState) => ({ ...prevState, [pumpVehicle.id_vehicle]: associatedMixers.reverse() })
    );

    return associatedMixers.reverse();

  }, [concreteMixerVehicles, travels, realTime, realTimeConcreteMixerVehicles]);

  // Function to create timeline items, based on the vehicle state (used to update timeline)
  const createTimelineItem = useCallback(
    (
      vehicle: Vehicle,
      groupID,
      state: VehicleState,
      subItem?: boolean,
      pumpStatus?: PumpStatus,
      pumpStatusType?: string,
      automaticTravel?: boolean
    ): TimelineItemBase<number> => {

      if (pumpStatus && pumpStatusType) {
        return {
          id: `${t(VehiclesStatesTypesMessages[pumpStatusType])}-${vehicle?.id_vehicle}--${pumpStatus.id_pump_status}`,
          group: groupID,
          start_time: automaticTravel
            ? new Date(state.registration_date).getTime()
            : new Date(pumpStatus.registration_date).getTime(),
          end_time: new Date().getTime(),
          canMove: false,
          canResize: false,
          title: `${t(GlobalMessages.vehicle)}: ${vehicle.code} | ${t(PumpTimelineMessages.state)}: ${t(VehiclesStatesTypesMessages[pumpStatusType])} | ${t(GlobalMessages.duration)}: ${moment.utc(
            new Date().getTime() - new Date(pumpStatus.start_date).getTime()
          ).format("H[h] mm[m]")}`
        };
      }

      return {
        id: subItem
          ? `${t(VehiclesStatesTypesMessages[state?.status.id_vehicle_state_type])}-${vehicle.id_vehicle},${state?.id_vehicle_state},${VehicleTypesID.BETONEIRA}`
          : `${t(VehiclesStatesTypesMessages[state?.status.id_vehicle_state_type])}-${vehicle?.id_vehicle}--${state?.id_vehicle_state}`,
        group: groupID,
        start_time: new Date(state.registration_date).getTime(),
        end_time: new Date().getTime(),
        canMove: false,
        canResize: false,
        title: `${t(GlobalMessages.vehicle)}: ${vehicle.code} | ${t(PumpTimelineMessages.state)}: ${t(VehiclesStatesTypesMessages[state?.status.id_vehicle_state_type])} | ${t(GlobalMessages.duration)}: ${moment.utc(
          new Date().getTime() - new Date(state?.registration_date).getTime()
        ).format("H[h] mm[m]")}`
      };
    }, [t]
  );

  /* Get vehicles items colors and icons related to their state
   @param itemState: string
   @param concreteMixer: boolean
   */
  const getVehiclesItemsColorsAndIcons = useCallback((itemState: string, concreteMixer?: boolean) => {

    let stateBackground;
    let stateIcon;

    if (concreteMixer) {
      switch (itemState) {
      case t(VehiclesStatesTypesMessages[VehicleStatesTypesID.A_CAMINHO]):
        stateBackground = VehicleStatesTypesColors.A_CAMINHO;
        stateIcon = BTIncoming;
        break;
      case t(VehiclesStatesTypesMessages[VehicleStatesTypesID.EM_LOCAL_CONFIAVEL]):
        stateBackground = "#9E9E9E";
        stateIcon = BTTrustedLocation;
        break;
      case t(VehiclesStatesTypesMessages[VehicleStatesTypesID.EM_OBRA]):
        stateBackground = VehicleStatesTypesColors.EM_OBRA;
        stateIcon = BTInConstruction;
        break;
      default:
        stateBackground = VehicleStatesTypesColors.DESCARREGANDO;
        stateIcon = BTCharging;
        break;
      }

      return { stateBackground, stateIcon };
    }

    switch (itemState) {
    case t(VehiclesStatesTypesMessages[PumpStatusTypes.OCIOSA]):
      stateBackground = PumpStatusTypesColors.OCIOSA;
      stateIcon = LazyBombState;
      break;
    case t(VehiclesStatesTypesMessages[VehicleStatesTypesID.EM_OBRA]):
      stateBackground = VehicleStatesTypesColors.EM_OBRA;
      stateIcon = InConstructionBombState;
      break;
    case t(VehiclesStatesTypesMessages[PumpStatusTypes.BOMBEANDO]):
      stateBackground = PumpStatusTypesColors.BOMBEANDO;
      stateIcon = PumpingBombState;
      break;
    case t(VehiclesStatesTypesMessages[VehicleStatesTypesID.A_CAMINHO]):
      stateBackground = VehicleStatesTypesColors.A_CAMINHO;
      stateIcon = PumpIncoming;
      break;
    case t(VehiclesStatesTypesMessages[VehicleStatesTypesID.EM_LOCAL_CONFIAVEL]):
      stateBackground = "#9E9E9E";
      stateIcon = PumpTrustedLocation;
      break;
    default:
      stateBackground = "#002951";
      break;
    }

    return { stateBackground, stateIcon };
  }, [t]);

  /* Create subGroups and place them in the correct position
   @param newSubGapPosition: number
   @param mixerVehicle: Vehicle
   @param updatedGroups: TimelineGroupBase[]
   */
  const createAndPlaceSubgroups = useCallback(
    (newSubGapPosition: number, mixerVehicle: Vehicle, updatedGroups, mixerTravel: Travel) => {

      const subGroups: TimelineGroupBase[] = [
        {
          id: newSubGapPosition,
          title: `${PumpTimelineTypes.SUBGROUP}-${mixerVehicle.id_vehicle}-${mixerTravel.id_travel}`,
          height: PumpTimelineGroupsHeights.SUBGROUP
        }
      ];

      // Insert subgroups and subGaps in correct position
      return [
        ...updatedGroups.slice(0, newSubGapPosition),
        ...subGroups,
        ...updatedGroups.slice(newSubGapPosition)
      ];
    }, []
  );

  // endregion Useful functions

  // region Timeline render functions
  // Custom renderer to timeline items
  const timelineItemRenderer = useCallback((props: ReactCalendarItemRendererProps) => {

    const itemState = props.item.id.toString().split("-")[0];
    let stateBackground;
    let stateIcon;

    if (props.item.id.toString().includes(`${VehicleTypes.BETONEIRA}`)) {
      // Select state color and icon
      ({ stateBackground, stateIcon } = getVehiclesItemsColorsAndIcons(itemState, true));
    } else {
      // Select state color and icon
      ({ stateBackground, stateIcon } = getVehiclesItemsColorsAndIcons(itemState));

    }

    const timelineItemContent = (itemState: string) => {

      if (itemState === PumpTimelineTypes.SUMMARY) {
        return (
          <div {
            ...props.getItemProps({
              style: {
                background: stateBackground,
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: 12,
                paddingTop: 12,
                paddingBottom: 12,
                paddingLeft: 5,
                borderRadius: 5,
                border: "none",
                overflow: "hidden",
                textOverflow: "ellipsis"
              }
            })
          }
          >
            { props.itemContext.dimensions.width > 200 && (

              <Span className="spanContainer">
                <div className="summaryItems">
                  <ReactSVG src={SummaryConstruction} />
                  { props?.item?.title?.toString().split("|")[1].split(":")[1]
                    || t(GlobalMessages.noNameRegistered) }
                </div>
                <div className="summaryItems">
                  <ReactSVG src={SummaryDriver} />
                  { props?.item?.title?.toString().split(`${t(PumpTimelineMessages.driver)}:`)[1]?.split("|")[0]
                    || t(GlobalMessages.noNameRegistered) }
                </div>
                <div className="summaryItems">
                  <ReactSVG src={SummaryClock} />
                  { formatHourMeter(
                    dateFns.differenceInMinutes(props.item.end_time, props.item.start_time)
                  ) }
                </div>
              </Span>
            ) }
          </div>
        );
      }

      if (props.item.id.toString().includes(`${VehicleTypes.BETONEIRA}`)) {

        return (
          <div {...props.getItemProps({
            style: {
              background: stateBackground,
              border: "none",
              borderRadius: 5,
              fontWeight: 500,
              fontSize: 12,
              minHeight: "20px",
              display: "flex",
              alignItems: "center",
              gap: 6,
              paddingLeft: 6
            }
          })}
          >
            { props.itemContext.dimensions.width > 30 && (
              <ReactSVG src={stateIcon} />
            ) }

            { props.itemContext.dimensions.width > 60 && (
              <p>{ formatHourMeter(
                dateFns.differenceInMinutes(props.item.end_time, props.item.start_time)
              ) }
              </p>
            ) }
          </div>
        );
      }

      return (
        <div {
          ...props.getItemProps({
            style: {
              background: stateBackground,
              display: "flex",
              flexDirection: itemState === PumpTimelineTypes.SUMMARY ? "row" : "column",
              alignItems: "flex-start",
              justifyContent: "center",
              paddingTop: 38,
              paddingBottom: 38,
              paddingLeft: 5,
              borderRadius: 5,
              border: "none"
            }
          })
        }
        >
          {
            // Display timeline item info only with a width greater than 80px
            props.itemContext.dimensions.width > 80 && (
              <>
                <ReactSVG src={stateIcon} />
                <div className="timelineText">
                  <span>{ itemState }</span>
                  <p>{ new Date(props.item.start_time).toLocaleTimeString("pt-br", {
                    hour: "2-digit",
                    minute: "2-digit"
                  }) } ► { new Date(props.item.end_time).toLocaleTimeString("pt-br", {
                    hour: "2-digit",
                    minute: "2-digit"
                  }) }
                  </p>
                  <p>{ formatHourMeter(
                    dateFns.differenceInMinutes(props.item.end_time, props.item.start_time)
                  ) }
                  </p>
                </div>
              </>
            )
          }
        </div>
      );
    };

    return timelineItemContent(itemState);
  }, [getVehiclesItemsColorsAndIcons, t]);

  const timelineComponent = useCallback(() => (
    <Timeline
      traditionalZoom
      canMove
      groups={timelineGroups}
      items={timelineItems}
      defaultTimeStart={new Date(visibleTimeStart)}
      defaultTimeEnd={new Date(visibleTimeEnd)}
      itemRenderer={timelineItemRenderer}
      sidebarWidth={0}
      timeSteps={{
        second: 1,
        minute: screen.platform === "desktop" ? 10 : 20,
        hour: 1,
        day: 1,
        month: 1,
        year: 1
      }}
      onItemClick={(itemId, e: any) => {
        const pumpsStatusList: string[] = [...Object.values(VehicleStatesTypes), ...Object.values(PumpStatusTypes)];

        if (pumpsStatusList.includes(itemId.toString().split("-")[0])
          && !itemId.toString().split(",").includes(VehicleTypes.BETONEIRA)) {
          setClickedItems((prevItems) => [...prevItems, { id: itemId, ...e.eventable.getRect() }]);
        }
      }}
      onTimeChange={(timeStart, timeEnd, updateScrollCanvas) => {
        const todayStartTime = new Date(visibleTimeStart).setHours(0, 0, 0, 0);
        const todayEndTime = new Date(visibleTimeEnd).setHours(23, 59, 59, 999);

        if (timeStart > todayStartTime && timeEnd < todayEndTime) {
          updateScrollCanvas(timeStart, timeEnd);
        }

        if (clickedItems) {
          setClickedItems([]);
        }
      }}
    >
      <TimelineHeaders style={{ background: "transparent", borderColor: "#D9D9D9" }}>

        {/* Define the first header */ }
        <DateHeader
          unit="hour"
          labelFormat="H[h]"
          style={{ background: "#D9D9D9", borderColor: "#D9D9D9", fontWeight: 500 }}
        />

        <CustomHeader unit="minute">
          { (props) => {
            const { intervals } = props!.headerContext;
            const rootProps = props!.getRootProps().style;

            const verticalLinesContainer = document.querySelector(".rct-vertical-lines");

            verticalLinesContainer?.querySelectorAll(".customVerticalLine").forEach((item) => {
              item.remove();
            });

            return (
              <div style={{ ...rootProps, backgroundColor: "#fff" }}>
                {
                  intervals.map((interval) => {

                    const intervalProps = props!.getIntervalProps({ interval });

                    const minutesVerticalLine = document.createElement("div");

                    minutesVerticalLine.style.position = "absolute";
                    minutesVerticalLine.style.width = `${intervalProps.style.width}px`;
                    minutesVerticalLine.style.left = `${intervalProps.style.left}px`;
                    minutesVerticalLine.classList.add("customVerticalLine");

                    if (verticalLinesContainer) {
                      verticalLinesContainer.append(minutesVerticalLine);
                    }

                    return (
                      <div
                        key={intervalProps.key}
                        style={{ ...intervalProps?.style, zIndex: 100 }}
                        className={`customHeader ${interval.startTime.format("mm") === "00" ? "solidLine" : "dashedLine"}`}
                      >
                        <span>{ interval.startTime.format("mm") }</span>
                      </div>
                    );
                  })
                }
              </div>
            );
          } }
        </CustomHeader>
      </TimelineHeaders>
    </Timeline>
  ),
  // timelineScrollRef is necessary to update the timeline scroll
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [timelineGroups, timelineItems, timelineItemRenderer, clickedItems, visibleTimeEnd, visibleTimeStart, screen, timelineScrollRef]);
  // endregion Timeline rendering functions

  // region Timeline update functions

  // Handle timeline accordions and subGroups (mixers)
  const handleSubTimeline = useCallback((pumpVehicle: Vehicle, stateAccordion: { [index: string]: boolean }) => {

    setCloseAllAccordions(false);

    let updatedGroups = [...timelineGroups];
    let updatedItems = [...timelineItems];

    const isOpen = stateAccordion[pumpVehicle.id_vehicle];

    setTimelineAccordionsStates((prevState) => ({ ...prevState, [pumpVehicle.id_vehicle]: isOpen }));

    // Get pump vehicle's travels that had destination
    const pumpVehicleTravels = travels
      .filter((travel) => travel.vehicle.id_vehicle === pumpVehicle.id_vehicle);

    // Get pump vehicle from store data
    const pumpVehicleRealTime = realTimePumpVehicles.find((vehicle) => vehicle.id_vehicle === pumpVehicle.id_vehicle);

    if (realTime && pumpVehicleRealTime) {
      pumpVehicleTravels.push(pumpVehicleRealTime.current_travel);
    }

    // Get mixers with same destination as Pump Vehicle
    const associatedMixers = getAssociatedMixers(pumpVehicle, pumpVehicleTravels);

    if (isOpen) {

      associatedMixers.forEach((mixerVehicle) => {

        let realTimeMixerVehicle;

        // Get mixer travels with the same destinations as pump vehicle
        const mixerTravels = travels
          .filter((travel) => travel.vehicle.id_vehicle === mixerVehicle.id_vehicle);

        // Get mixer current travel from store data
        const mixerVehicleRealTimeTravel = realTimeConcreteMixerVehicles
          .find((mixer) => mixer.id_vehicle === mixerVehicle.id_vehicle)?.current_travel;

        // Get real time pump vehicle from store data
        const realTimePumpVehicle = realTimePumpVehicles.find(
          (vehicle) => vehicle.id_vehicle === pumpVehicle.id_vehicle
        );

        if (realTime
          && realTimePumpVehicle
          && ((mixerVehicleRealTimeTravel?.destination?.id_location
              === realTimePumpVehicle?.current_travel?.destination?.id_location)
            || (realTimePumpVehicle?.current_travel?.related_mixers_travels.some(
              (mixerTravel) => mixerTravel?.id_travel === mixerVehicleRealTimeTravel?.id_travel
            )))) {
          mixerTravels.push(mixerVehicleRealTimeTravel!);

          // Get mixer vehicle from store data (to get current travel info)
          realTimeMixerVehicle = realTimeConcreteMixerVehicles
            .find((mixer) => mixer.id_vehicle === mixerVehicle.id_vehicle);
        }

        // Get only the travels that has the same destination as at least one of the pump vehicle travels
        const mixerTravelsWithSameDestinations = mixerTravels
          .filter((mixerTravel) => pumpVehicleTravels
            .some((pumpTravel) => pumpTravel?.destination?.id_location === mixerTravel?.destination?.id_location
              || pumpTravel?.related_mixers_travels.some(
                (item) => item?.id_travel === mixerTravel?.id_travel
              )));

        mixerTravelsWithSameDestinations.forEach((mixerTravel, mixerTravelIndex) => {

          // Get 'Data' group of the pump vehicle associated with the mixer vehicle
          const pumpDataGroup = updatedGroups.find(
            (group) => group?.title?.toString() === `${PumpTimelineTypes.DATA}-${pumpVehicle.id_vehicle}`
          );

          // New subGroups will be inserted after the 'Data' group of the pump vehicle,
          const newSubGapPosition = Number(pumpDataGroup?.id) + 1;

          // Verify if already exists a subGroup for this mixer vehicle
          const subGroupExists = updatedGroups.some(
            (group) => group?.title?.toString()
              === `${PumpTimelineTypes.SUBGROUP}-${mixerVehicle.id_vehicle}-${mixerTravel.id_travel}`
          );

          // If subGroup does not exist, create it and add it to timelineGroups
          if (mixerTravelIndex === 0 && !subGroupExists) {

            const updatedGroupsList = createAndPlaceSubgroups(newSubGapPosition, mixerVehicle, updatedGroups,
              mixerTravel);

            updatedGroups = updatedGroupsList.map((group, index) => ({ ...group, id: index }));

            // Insert subGap and subGroup in timelineGroups
            setTimelineGroups(updatedGroups);
          }

          // Update old items groups IDs that were positioned after the new subgroups
          const updatedItemsList = updatedItems.map((item) => {

            if (Number(item.group) >= newSubGapPosition && mixerTravelIndex === 0) {
              return { ...item, group: Number(item.group) + 1 };
            }

            return item;
          });

          // Create subItems
          const subItemsToInsert: TimelineItemBase<number>[] = [];

          const realTimePumpVehicle = realTimePumpVehicles.find(
            (vehicle) => vehicle.id_vehicle === pumpVehicle.id_vehicle
          );

          const travelStates = realTime
          && realTimeMixerVehicle
          && ((realTimeMixerVehicle?.current_travel?.destination?.id_location
              === realTimePumpVehicle?.current_travel?.destination?.id_location)
            || (realTimePumpVehicle?.current_travel?.related_mixers_travels.some(
              (mixerTravel) => mixerTravel?.id_travel === realTimeMixerVehicle?.current_travel?.id_travel
            )))
          && realTimeMixerVehicle?.current_travel?.id_travel === mixerTravel?.id_travel
            ? mixerTravel?.states?.concat(realTimeMixerVehicle?.states) || realTimeMixerVehicle?.states
            : mixerTravel?.states;

          // Get all states of the mixer vehicle
          // Go through BT states to create items
          travelStates?.forEach((state) => {

            // Verify state to create a new item
            if (state.status.id_vehicle_state_type === VehicleStatesTypesID.A_CAMINHO
              || state.status.id_vehicle_state_type === VehicleStatesTypesID.EM_OBRA
              || state.status.id_vehicle_state_type === VehicleStatesTypesID.DESCARREGANDO
              || state.status.id_vehicle_state_type === VehicleStatesTypesID.EM_LOCAL_CONFIAVEL) {

              // Verify if item already exists
              const itemExists = updatedItems.find(
                (item) => item.id
                  === `${t(VehiclesStatesTypesMessages[state.status.id_vehicle_state_type])}-${mixerVehicle.id_vehicle}-${state.id_vehicle_state}-${
                    VehicleTypes.BETONEIRA}-${mixerTravel?.id_travel}`
              );

              if (itemExists) return;

              // Create a new item
              const subItem: TimelineItemBase<number> = {
                id: `${t(VehiclesStatesTypesMessages[state.status.id_vehicle_state_type])}-${mixerVehicle.id_vehicle}-${state.id_vehicle_state}-${
                  VehicleTypes.BETONEIRA}-${mixerTravel?.id_travel}`,
                group: newSubGapPosition,
                start_time: new Date(state.registration_date).getTime(),
                end_time: state?.finish_date ? new Date(state.finish_date).getTime() : new Date().getTime(),
                canMove: false,
                canResize: false,
                title: `${t(GlobalMessages.vehicle)}: ${mixerVehicle.code} | ${t(PumpTimelineMessages.state)}: ${t(VehiclesStatesTypesMessages[state?.status.id_vehicle_state_type])} | ${t(GlobalMessages.duration)}: ${moment.utc(
                  (state?.finish_date
                    ? new Date(state.finish_date).getTime() : new Date().getTime())
                  - new Date(state.registration_date).getTime()
                ).format("H[h] mm[m]")}`
              };

              // Add subItem to list of items to insert
              subItemsToInsert.push(subItem);

            }
          });

          updatedItems = [...updatedItemsList, ...subItemsToInsert].sort((a, b) => a.start_time - b.start_time);

          setTimelineItems(updatedItems);

        });
      });

    } else {
      // Remove subgroups and items related to that pump vehicle when accordion is closed

      // Get index of 'Data' group in timelineGroups
      const dataGroupPosition = timelineGroups.findIndex(
        (group) => group?.title?.toString().slice(0, 4) === PumpTimelineTypes.DATA && group?.title?.toString().slice(5)
          === pumpVehicle.id_vehicle
      );

      // Subgroups to remove will be the ones after the 'Data' group of the pump vehicle,
      // the number of subgroups to remove is equal to the number of associated BTs times 2 (subGap and subGroup)
      const subGroupsToRemove = timelineGroups.slice(dataGroupPosition + 1,
        dataGroupPosition + 1 + associatedMixers.length);

      // Get IDs of subgroups to remove
      const subGroupsToRemoveIds = subGroupsToRemove.map((group) => group.id);

      // Get IDs of items to remove
      const itemsToRemoveIds = timelineItems.filter((item) => subGroupsToRemoveIds.includes(item.group))
        .map((item) => item.id);

      // Remove subgroups and rewrite IDs in sequence
      setTimelineGroups((groups) => {
        const updatedGroupsList = groups.filter((group) => !subGroupsToRemoveIds.includes(group.id));
        const newList = updatedGroupsList.map((group, index) => ({ ...group, id: index }));

        prevTimelineGroups.current = newList;

        return newList;
      });

      // Remove items and update groups IDs of items according to groups after removing the subgroups
      setTimelineItems((items) => {
        const updatedItems = items.filter((item) => !itemsToRemoveIds.includes(item.id));

        return updatedItems.map((item) => {
          if (Number(item.group) > dataGroupPosition) {
            return { ...item, group: Number(item.group) - subGroupsToRemove.length };
          }

          return item;
        });
      });
    }

    return associatedMixers;
  }, [
    timelineGroups,
    timelineItems,
    travels,
    getAssociatedMixers,
    realTime,
    realTimePumpVehicles,
    realTimeConcreteMixerVehicles,
    createAndPlaceSubgroups,
    t
  ]);

  // Add mixer vehicles to show in timeline (only if associated pump vehicle accordion is open)
  const addMixerVehiclesToTimeline = useCallback(
    (mixerVehiclesToAdd: Vehicle[], travels: Travel[], realTime?: boolean) => {
      let updatedGroups: TimelineGroupBase[] = timelineGroups;
      let updatedItems: TimelineItemBase<number>[] = timelineItems;

      mixerVehiclesToAdd.forEach((mixerVehicle, index) => {

        // Get pump vehicle that has the same destination of the mixer vehicle to be added
        const pumpVehicle = pumpVehicles.find(
          (pumpVehicle) => pumpVehicle?.current_travel?.destination?.id_location
            === mixerVehicle?.current_travel?.destination?.id_location
            || pumpVehicle?.current_travel?.related_mixers_travels.some(
              (relatedMixerTravel) => relatedMixerTravel.id_travel === mixerVehicle?.current_travel?.id_travel
            )
        );

        // Verify if the accordion of the pump vehicle is open, if it is, create subgroups and subItems for the mixer
        if (pumpVehicle && timelineAccordionsStates[pumpVehicle.id_vehicle]) {

          // Get id of the pump vehicle GAP group
          const pumpVehicleGapGroup = updatedGroups.find(
            (group) => group.title === `${PumpTimelineTypes.GAP}-${pumpVehicle.id_vehicle}`
          );

          // Calculate new position of the subgroups
          const newSubGapPosition = Number(pumpVehicleGapGroup?.id) + (index);

          // Verify if the mixer vehicle already has subGroups on timeline (get subGroup of the mixer vehicle)
          const mixerVehicleSubGroup = updatedGroups.find(
            (group) => group.title === `${PumpTimelineTypes.SUBGROUP}-${mixerVehicle.id_vehicle}-${
              mixerVehicle.current_travel?.id_travel}`
          );

          if (!mixerVehicleSubGroup) {

            // Create subgroups and subGaps and place them in correct position
            const updatedGroupsList = createAndPlaceSubgroups(newSubGapPosition, mixerVehicle, updatedGroups,
              mixerVehicle.current_travel);

            // Rewrite IDs in sequence
            updatedGroups = updatedGroupsList.map((group, index) => ({ ...group, id: index }));

            setTimelineGroups(updatedGroups);

          }

          const mixerVehicleTravels = realTime
            ? [mixerVehicle.current_travel]
            : travels.filter((travel) => travel.vehicle.id_vehicle === mixerVehicle.id_vehicle);

          mixerVehicleTravels.forEach((mixerTravel) => {

            // Create subItems
            const subItemsToInsert: TimelineItemBase<number>[] = [];

            // Go through BT states to create items
            mixerTravel?.states?.forEach((state) => {

              // Verify state to create a new item
              if (state.status.id_vehicle_state_type === VehicleStatesTypesID.A_CAMINHO
                || state.status.id_vehicle_state_type === VehicleStatesTypesID.EM_OBRA
                || state.status.id_vehicle_state_type === VehicleStatesTypesID.DESCARREGANDO
                || state.status.id_vehicle_state_type === VehicleStatesTypesID.EM_LOCAL_CONFIAVEL) {

                // Verify if item already exists
                const itemExists = timelineItems.find(
                  (item) => item.id
                    === `${t(VehiclesStatesTypesMessages[state.status.id_vehicle_state_type])}-${mixerVehicle.id_vehicle}-${state.id_vehicle_state}-${
                      VehicleTypes.BETONEIRA}-${mixerTravel?.id_travel}`
                );

                if (itemExists) return;

                // Create a new item
                const subItem: TimelineItemBase<number> = {
                  id: `${t(VehiclesStatesTypesMessages[state.status.id_vehicle_state_type])}-${mixerVehicle.id_vehicle}-${state.id_vehicle_state}-${
                    VehicleTypes.BETONEIRA}-${mixerTravel?.id_travel}`,
                  group: mixerVehicleSubGroup ? Number(mixerVehicleSubGroup.id) : newSubGapPosition + 1,
                  start_time: new Date(state.registration_date).getTime(),
                  end_time: state?.finish_date ? new Date(state.finish_date).getTime() : new Date().getTime(),
                  canMove: false,
                  canResize: false,
                  title: `${t(GlobalMessages.vehicle)}: ${mixerVehicle.code} | ${t(PumpTimelineMessages.state)}: ${t(VehiclesStatesTypesMessages[state?.status.id_vehicle_state_type])} | ${t(GlobalMessages.duration)}: ${moment.utc(
                    (state?.finish_date
                      ? new Date(state.finish_date).getTime() : new Date().getTime())
                    - new Date(state.registration_date).getTime()
                  ).format("H[h] mm[m]")}`
                };

                // Add subItem to list of items to insert
                subItemsToInsert.push(subItem);

                updatedItems = [...updatedItems, ...subItemsToInsert];

              }
            });

            const updatedItemsList = updatedItems.map((item) => {
              if (Number(item.group) > newSubGapPosition) {
                return { ...item, group: Number(item.group) + 1 };
              }

              return item;
            });

            setTimelineItems(updatedItemsList);

          });

          // Add mixer vehicle to pump vehicle associations
          setPumpAndMixerAssociations((prevAssociations) => ({
            ...prevAssociations,
            [pumpVehicle.id_vehicle]: [...prevAssociations[pumpVehicle.id_vehicle], mixerVehicle]
          }));

        }

      });

    }, [timelineAccordionsStates, timelineGroups, pumpVehicles, timelineItems, createAndPlaceSubgroups, t]
  );

  // Add pump vehicles to show in timeline (also used to show initial pump vehicles)
  const addPumpVehiclesToTimeline = useCallback(
    (pumpVehiclesToAdd: Vehicle[], travels: Travel[], realTime?: boolean) => {

      let groups: TimelineGroupBase[] = timelineGroups;
      let items: TimelineItemBase<number>[] = timelineItems;

      // Go through pump vehicles to add and create groups and items for each one
      pumpVehiclesToAdd.forEach((vehicle) => {

        const itemsToInsert: TimelineItemBase<number>[] = [];

        // Verify if the pump vehicle already has groups on timeline (get summary group)
        const pumpVehicleSummaryGroup = groups.find(
          (group) => group.title === `${PumpTimelineTypes.SUMMARY}-${vehicle.id_vehicle}`
        );

        // If the pump vehicle group does not exist, create the groups
        if (!pumpVehicleSummaryGroup) {

          // Create new groups and items for the pump vehicle
          const summaryGroup: TimelineGroupBase = {
            id: groups.length,
            title: `${PumpTimelineTypes.SUMMARY}-${vehicle.id_vehicle}`,
            height: PumpTimelineGroupsHeights.SUMMARY
          };

          const dataGroup: TimelineGroupBase = {
            id: groups.length + 1,
            title: `${PumpTimelineTypes.DATA}-${vehicle.id_vehicle}`,
            height: PumpTimelineGroupsHeights.DATA
          };

          const gapGroup: TimelineGroupBase = {
            id: groups.length + 2,
            title: `${PumpTimelineTypes.GAP}-${vehicle.id_vehicle}`,
            height: PumpTimelineGroupsHeights.GAP
          };

          // New groups will be inserted after the last pump vehicle group (no need to sort, as they are already
          // sorted by the ID)
          const updatedGroups = [...groups, summaryGroup, dataGroup, gapGroup];

          // Update groups
          groups = updatedGroups;

          // Update timelineGroups state
          setTimelineGroups(updatedGroups);

        }

        // Filter the travels of the pump vehicle and go through them to create items
        const pumpVehicleTravels = realTime
          ? [vehicle?.current_travel]
          : travels.filter((travel) => travel?.vehicle?.id_vehicle === vehicle?.id_vehicle);

        pumpVehicleTravels.forEach((travel) => {

          // Create new items for the travel
          const summaryItem: TimelineItemBase<number> = {
            id: `${PumpTimelineTypes.SUMMARY}-${vehicle.id_vehicle}--${travel.id_travel}`,
            group: pumpVehicleSummaryGroup ? pumpVehicleSummaryGroup.id : groups.length - 3,
            start_time: new Date(travel.registration_date).getTime(),
            end_time: travel.finish_date ? new Date(travel.finish_date).getTime() : new Date().getTime(),
            canMove: false,
            canResize: false,
            title: `${t(GlobalMessages.vehicle)}: ${vehicle.code} | ${t(PumpTimelineMessages.destination)}: ${travel?.destination?.name
            || t(GlobalMessages.noNameRegistered)} | ${t(PumpTimelineMessages.driver)}: ${travel?.driver?.name || vehicle?.driver?.name
            || t(GlobalMessages.noNameRegistered)} | ${t(GlobalMessages.duration)}: ${moment.utc((travel.finish_date
              ? new Date(travel.finish_date).getTime()
              : new Date().getTime()) - new Date(travel.registration_date).getTime()).format("H[h] mm[m]")}`
          };

          itemsToInsert.push(summaryItem);

          items = [...items, ...itemsToInsert];

          // Get all pump status of the travel
          const pumpStatus = travel.pump_status;

          // Sort pump status by start date
          pumpStatus.sort(
            (a, b) => (a.start_date && b.start_date ? new Date(a.start_date).getTime()
              - new Date(b.start_date).getTime()
              : 0)
          );

          // Get the index of the first pump status that is active (discharge)
          const firstOccurrenceOfDischargeIndex = pumpStatus.findIndex((status) => status.active);

          // If there was a discharge, create items for the discharge and the pump status after it
          if (firstOccurrenceOfDischargeIndex !== -1) {

            const pumpStatusAfterDischarge = travel.pump_status.slice(firstOccurrenceOfDischargeIndex);

            // Go through the pump status after the discharge (including the discharge)
            pumpStatusAfterDischarge.forEach((status) => {

              const pumpStatusItem: TimelineItemBase<number> = {
                id: status.active
                  ? `${t(VehiclesStatesTypesMessages[PumpStatusTypes.BOMBEANDO])}-${vehicle.id_vehicle}-${travel.id_travel}-${status.id_pump_status}`
                  : `${t(VehiclesStatesTypesMessages[PumpStatusTypes.OCIOSA])}-${vehicle.id_vehicle}-${travel.id_travel}-${status.id_pump_status}`,
                group: pumpVehicleSummaryGroup ? Number(pumpVehicleSummaryGroup.id) + 1 : groups.length - 2,
                start_time: status?.start_date ? new Date(status.start_date).getTime() : new Date().getTime(),
                end_time: status?.finish_date ? new Date(status.finish_date).getTime() : new Date().getTime(),
                canMove: false,
                title: `${t(GlobalMessages.vehicle)}: ${vehicle.code} | ${t(PumpTimelineMessages.state)}: ${status.active
                  ? t(VehiclesStatesTypesMessages[PumpStatusTypes.BOMBEANDO])
                  : t(VehiclesStatesTypesMessages[PumpStatusTypes.OCIOSA])} | ${t(GlobalMessages.duration)}: ${moment.utc((travel.finish_date
                  ? new Date(travel.finish_date).getTime() : new Date().getTime()) - (status?.start_date
                  ? new Date(status.start_date).getTime()
                  : new Date().getTime())).format("H[h] mm[m]")}`
              };

              itemsToInsert.push(pumpStatusItem);

              items = [...items, ...itemsToInsert];
            });
          }

          // Get only states with finish date
          const travelStates = realTime
            ? vehicle.states
            : travel.states?.filter((state) => state.finish_date);

          // Go through the states of the travel and create items for them
          travelStates.forEach((state) => {

            // If the state is not "A caminho", "Em obra" or "Em Local Confiável", skip to next state
            if (state.status.id_vehicle_state_type !== VehicleStatesTypesID.A_CAMINHO && state.status.id_vehicle_state_type
              !== VehicleStatesTypesID.EM_OBRA && state.status.id_vehicle_state_type
              !== VehicleStatesTypesID.EM_LOCAL_CONFIAVEL) return;

            // Check if there is already an item for the state, if so, skip
            if (itemsToInsert.some(
              (item) => item.id.toString()
                .includes(
                  `${t(VehiclesStatesTypesMessages[state.status.id_vehicle_state_type])}-${vehicle.id_vehicle}-${travel.id_travel}-${state.id_vehicle_state}`
                )
            )) return;

            const stateItem: TimelineItemBase<number> = {
              id: state.status.id_vehicle_state_type === VehicleStatesTypesID.A_CAMINHO
                ? `${t(VehiclesStatesTypesMessages[VehicleStatesTypesID.A_CAMINHO])}-${vehicle.id_vehicle}-${travel.id_travel}-${state.id_vehicle_state}`
                : state.status.id_vehicle_state_type === VehicleStatesTypesID.EM_LOCAL_CONFIAVEL
                  ? `${t(VehiclesStatesTypesMessages[VehicleStatesTypesID.EM_LOCAL_CONFIAVEL])}-${vehicle.id_vehicle}-${travel.id_travel}-${state.id_vehicle_state}`
                  : `${t(VehiclesStatesTypesMessages[VehicleStatesTypesID.EM_OBRA])}-${vehicle.id_vehicle}-${travel.id_travel}-${state.id_vehicle_state}`,
              group: pumpVehicleSummaryGroup ? Number(pumpVehicleSummaryGroup.id) + 1 : groups.length - 2,
              start_time: new Date(state.registration_date).getTime() >= new Date(travel.registration_date).getTime()
                ? new Date(state.registration_date).getTime()
                : new Date(travel.registration_date).getTime(),
              end_time: state.finish_date ? new Date(state.finish_date).getTime() : new Date().getTime(),
              canMove: false,
              title: `${t(GlobalMessages.vehicle)}: ${vehicle.code} | ${t(PumpTimelineMessages.state)}:${t(VehiclesStatesTypesMessages[state?.status.id_vehicle_state_type])} | ${t(GlobalMessages.duration)}: ${moment.utc(
                (state.finish_date
                  ? new Date(state.finish_date).getTime() : new Date().getTime())
                - (new Date(state.registration_date).getTime()
                >= new Date(travel.registration_date).getTime()
                  ? new Date(state.registration_date).getTime()
                  : new Date(travel.registration_date).getTime())
              ).format("H[h] mm[m]")}`
            };

            itemsToInsert.push(stateItem);

            items = [...items, ...itemsToInsert];
          });

          // Set timeline items but only the items with different IDs from the previous items
          setTimelineItems(() => {
            const newItems = [...items];

            return newItems.filter((item, index) => newItems.findIndex((i) => i.id === item.id) === index);
          });
        });

      });

    }, [timelineGroups, timelineItems, t]
  );

  // Update timeline items according to the vehicle status (update most recent item or create a new item, if status has
  // changed)
  const updateTimelineItems = useCallback((vehicle: Vehicle, groups: TimelineGroupBase[]) => {

    // Go through each group related to the vehicle
    groups.forEach((group) => {

      // If the group is a gap or a subGap, skip it
      if (group?.title?.toString().includes(PumpTimelineTypes.GAP)) return;

      // Update timelineItems
      setTimelineItems((timelineItems) => {

        const newItemsToInsert: TimelineItemBase<number>[] = [];

        // Get the item of the group with the most recent start date
        const lastItem = timelineItems.filter((item) => item.group === group.id)
          .sort((a, b) => b.start_time - a.start_time)[0];

        // Get the type of the last item
        const lastItemType = lastItem?.id?.toString().slice(0, lastItem.id.toString().indexOf("-"));

        // Go through items of the group and update the last item according to the vehicle status
        const updatedItems = timelineItems.map((item) => {

          // If is the last item of the group
          if (item.id === lastItem?.id) {

            // Get vehicle's states and order by registration date
            const vehiclesStatesSorted = vehicle.states.sort(
              (a, b) => new Date(b.registration_date).getTime() - new Date(a.registration_date).getTime()
            );

            // Get the last state of the vehicle
            const lastState = vehiclesStatesSorted?.[0];

            // Get vehicle's current travel pump status and order by most recent start date
            const vehiclesPumpStatusSorted = vehicle.current_travel?.pump_status?.sort(
              (a, b) => new Date(b.start_date).getTime() - new Date(a.start_date).getTime()
            );

            // Get the last pump status of the vehicle
            const lastPumpStatus = vehiclesPumpStatusSorted?.[0];

            // If the vehicle is a mixer, update the subItems
            if (vehicle.type.id_vehicle_type === VehicleTypesID.BETONEIRA) {

              // Get mixer vehicle from store
              const realTimeMixerVehicle = realTimeConcreteMixerVehicles.find(
                (v) => v.id_vehicle === vehicle.id_vehicle
              );

              // Verify if real time mixer vehicle current travel is the same as any real time pump vehicle current
              // travel
              const realTimePumpVehicle = realTimePumpVehicles.find(
                (v) => (v.current_travel?.destination?.id_location
                    === realTimeMixerVehicle?.current_travel?.destination?.id_location)
                  || (v.current_travel?.related_mixers_travels?.some(
                    (mixerTravel) => mixerTravel?.id_travel === realTimeMixerVehicle?.current_travel?.id_travel
                  ))
              );

              // If the vehicle is a mixer and there is a pump vehicle with the same current destination, update
              if (realTimeMixerVehicle && realTimePumpVehicle) {

                // If the last state is not the same as the last subItem, create a new subItem and update the end time
                // of the last subItem with the finish date of the second to last state
                if (vehiclesStatesSorted
                  && lastState
                  && (t(VehiclesStatesTypesMessages[lastState?.status?.id_vehicle_state_type]) !== lastItemType || !item?.id?.toString().includes(
                    lastState?.id_vehicle_state
                  ))
                  && lastState?.status.id_vehicle_state_type !== VehicleStatesTypesID.RETORNANDO) {

                  const newSubItem = createTimelineItem(vehicle, group.id, lastState, true);

                  // Add the new subItem to the array of new items to insert
                  newItemsToInsert.push(newSubItem);

                  // Verify if the item refers to the current travel of the mixer vehicle
                  if (item?.id?.toString().includes(realTimeMixerVehicle?.current_travel.id_travel)) {

                    // If so, update the end time of the last subItem (now it's the second to last state)
                    return {
                      ...item,
                      end_time: vehiclesStatesSorted[1].finish_date
                        ? new Date(vehiclesStatesSorted[1].finish_date).getTime()
                        : new Date().getTime()
                    };
                  }

                  // If the subItem refers to a different travel, return the subItem itself (the subItem referring to
                  // the new travel was created earlier)
                  return item;

                }

                // If the last state is 'Retornando', do not update the end_time of the last item (which should be
                // 'Descarregando')
                if (lastState?.status.id_vehicle_state_type === VehicleStatesTypesID.RETORNANDO) return item;

                // If the last state is the same as the last item, only update the end time of the last item
                return { ...item, end_time: new Date().getTime() };

              }

              // If the vehicle is a mixer and there is no pump vehicle with the same current destination, keep the same
              return item;

            }

            // From 'A caminho' to 'Em Local Confiável'
            if (vehiclesStatesSorted
              && lastItemType === t(VehiclesStatesTypesMessages[VehicleStatesTypesID.A_CAMINHO])
              && lastState?.status?.id_vehicle_state_type === VehicleStatesTypesID.EM_LOCAL_CONFIAVEL) {

              // If the last state is not the same as the last item, create a new item and update the end time of
              // the last item
              const newItem = createTimelineItem(vehicle, group.id, lastState);

              // Add the new item to the array of new items to insert
              newItemsToInsert.push(newItem);

              // Return the last item with the end time updated
              return {
                ...item,
                end_time: vehiclesStatesSorted[1].finish_date
                  ? new Date(vehiclesStatesSorted[1].finish_date).getTime()
                  : new Date().getTime()
              };

            }

            // From 'Em Local Confiável' to 'A caminho'
            if (vehiclesStatesSorted
              && lastItemType === t(VehiclesStatesTypesMessages[VehicleStatesTypesID.EM_LOCAL_CONFIAVEL])
              && lastState?.status?.id_vehicle_state_type === VehicleStatesTypesID.A_CAMINHO) {

              const newItem = createTimelineItem(vehicle, group.id, lastState);

              // Add the new item to the array of new items to insert
              newItemsToInsert.push(newItem);

              // Return the last item with the end time updated
              return {
                ...item,
                end_time: vehiclesStatesSorted[1].finish_date
                  ? new Date(vehiclesStatesSorted[1].finish_date).getTime()
                  : new Date().getTime()
              };
            }

            // From 'A caminho' to 'Em obra'
            if (vehiclesStatesSorted
              && lastItemType === t(VehiclesStatesTypesMessages[VehicleStatesTypesID.A_CAMINHO])
              && lastState?.status?.id_vehicle_state_type === VehicleStatesTypesID.EM_OBRA) {

              const newItem = createTimelineItem(vehicle, group.id, lastState);

              // Add the new item to the array of new items to insert
              newItemsToInsert.push(newItem);

              // Return the last item with the end time updated
              return {
                ...item,
                end_time: vehiclesStatesSorted[1].finish_date
                  ? new Date(vehiclesStatesSorted[1].finish_date).getTime()
                  : new Date().getTime()
              };

            }

            // Treatment for automatic travel (when the duration of the state 'Em Obra' is too short)
            // From 'A caminho' to 'Bombeando'
            if (vehiclesStatesSorted
              && lastItemType === t(VehiclesStatesTypesMessages[VehicleStatesTypesID.A_CAMINHO])
              && lastState?.status?.id_vehicle_state_type === VehicleStatesTypesID.DESCARREGANDO
              && lastPumpStatus.active) {

              // Create new item for the state 'Em Obra', which is the second to last state
              const newStateItem = createTimelineItem(vehicle, group.id, vehiclesStatesSorted[1]);

              // Add the new state item to the array of new items to insert
              newItemsToInsert.push(newStateItem);

              // Create new item for the pump status 'Bombeando', using the start date of the state 'Descarregando'
              const newPumpStatusItem = createTimelineItem(vehicle, group.id, lastState, false, lastPumpStatus,
                PumpStatusTypes.BOMBEANDO, true);

              // Add the new pump status item to the array of new items to insert
              newItemsToInsert.push(newPumpStatusItem);

              // Return the last item (A caminho) with the end time updated (using the finish date of the state 'A
              // caminho', which is the third to last state)
              return { ...item, end_time: new Date(vehiclesStatesSorted[2]?.finish_date).getTime() };
            }

            // From 'Em obra' to 'Bombeando'
            if (vehiclesStatesSorted
              && lastItemType === t(VehiclesStatesTypesMessages[VehicleStatesTypesID.EM_OBRA])
              && lastState?.status?.id_vehicle_state_type === VehicleStatesTypesID.DESCARREGANDO
              && lastPumpStatus.active) {

              const newItem = createTimelineItem(vehicle, group.id, lastState, false, lastPumpStatus,
                PumpStatusTypes.BOMBEANDO);

              // Add the new item to the array of new items to insert
              newItemsToInsert.push(newItem);

              // Return the last item with the end time updated
              return { ...item, end_time: new Date().getTime() };
            }

            // From 'Bombeando' to 'Ociosa'
            if (vehiclesPumpStatusSorted
              && lastItemType === t(VehiclesStatesTypesMessages[PumpStatusTypes.BOMBEANDO])
              && !lastPumpStatus.active) {

              const newItem = createTimelineItem(vehicle, group.id, lastState, false, lastPumpStatus,
                PumpStatusTypes.OCIOSA);

              // Add the new item to the array of new items to insert
              newItemsToInsert.push(newItem);

              // Return the last item with the end time updated
              return { ...item, end_time: new Date().getTime() };
            }

            // From 'Ociosa' to 'Bombeando'
            if (vehiclesPumpStatusSorted
              && lastItemType === t(VehiclesStatesTypesMessages[PumpStatusTypes.OCIOSA])
              && lastPumpStatus.active) {

              const newItem = createTimelineItem(vehicle, group.id, lastState, false, lastPumpStatus,
                PumpStatusTypes.BOMBEANDO);

              // Add the new item to the array of new items to insert
              newItemsToInsert.push(newItem);

              // Return the last item with the end time updated
              return { ...item, end_time: new Date().getTime() };
            }

            // If the last state is the same as the last item, update the end time of the last item
            return { ...item, end_time: new Date().getTime() };

          }

          // If is not the last item, return the item without changes
          return item;
        });

        // Add the new items to the array of items to return
        const updatedItemsWithNewItemsToInsert = [...updatedItems, ...newItemsToInsert];

        return updatedItemsWithNewItemsToInsert.sort((a, b) => a.start_time - b.start_time);

      });

    });
  }, [createTimelineItem, realTimeConcreteMixerVehicles, realTimePumpVehicles, t]);

  // Get the info label to show when an item is clicked
  const getInfoLabel = useCallback(() => clickedItems.map((clickedItem) => {
    const selectedItem = timelineItems.find((item) => item.id === clickedItem.id);

    if (clickedItem.width <= 80 && selectedItem) {
      const itemState = clickedItem.id.toString().split("-")[0];

      const formattedDuration = moment.utc(selectedItem.end_time - selectedItem.start_time).format("H[h] mm[m]");

      const { stateBackground, stateIcon } = getVehiclesItemsColorsAndIcons(itemState);

      return (
        <InfoLabel stateColor={stateBackground} key={clickedItem.id}>
          <div
            className="container"
            // Position container in center of item and correctly height
            style={{
              top: clickedItem.top - clickedItem.height - (clickedItem.height - 10) / 2 + 15,
              // 60 is half of svg width
              left: clickedItem.left + clickedItem.width / 2 - 60
            }}
          >
            <ReactSVG src={stateIcon} />
            <div className="timelineText">
              <span>{ itemState }</span>
              <p>{ new Date(selectedItem.start_time).toLocaleTimeString("pt-br", {
                hour: "2-digit",
                minute: "2-digit"
              }) } ► { moment(selectedItem.end_time).format("HH:mm") }
              </p>
              <p>{ formattedDuration }</p>

            </div>

          </div>
          <ReactSVG
            src={ItemLabelIndicator}
            className="itemIndicator"
            // Position svg above the container
            style={{
              top: clickedItem.top - (clickedItem.height - 10) / 2 + 15,
              // 9 is half of svg width
              left: clickedItem.left + clickedItem.width / 2 - 9,
              position: "absolute"
            }}
          />
        </InfoLabel>
      );
    }

    return <> </>;
  }), [clickedItems, timelineItems, getVehiclesItemsColorsAndIcons]);

  // Verify if there are new vehicles to be added to the timeline
  const verifyVehiclesToAdd = useCallback(() => {

    const pumpVehiclesToAdd: Vehicle[] = [];
    const pumpVehiclesCurrentTravelsToAdd: Travel[] = [];
    const mixerVehiclesToAdd: Vehicle[] = [];
    const mixerVehiclesCurrentTravelsToAdd: Travel[] = [];

    // If the number of vehicles in the timeline states is greater than the number of vehicles in the previous state
    // of the vehicles, it means that there are new vehicles to be added to the timeline
    if (prevVehiclesOnTimelineStates?.current
      && prevVehiclesOnTimelineStates?.current?.length < vehiclesInRealTimeStates.length) {

      // Get the vehicles that are in vehiclesInRealTimeStates but not in prevVehiclesOnTimelineStates (vehicles to add)
      const vehiclesToAdd = vehiclesInRealTimeStates.filter(
        (vehicle) => !prevVehiclesOnTimelineStates?.current?.find(
          (prevVehicle) => prevVehicle.id_vehicle === vehicle.id_vehicle
        )
      );

      // Add the pump vehicles to the array of pump vehicles to add
      pumpVehiclesToAdd.push(
        ...vehiclesToAdd.filter((vehicle) => vehicle.type.id_vehicle_type === VehicleTypesID.CAMINHAO_BOMBA)
      );

      // Add the pump vehicles current travels to the array of pump vehicles current travels to add
      pumpVehiclesCurrentTravelsToAdd.push(
        ...vehiclesToAdd.filter((vehicle) => vehicle.type.id_vehicle_type === VehicleTypesID.CAMINHAO_BOMBA)
          .map((vehicle) => vehicle.current_travel)
      );

      // Add the mixer vehicles to the array of mixer vehicles to add
      mixerVehiclesToAdd.push(
        ...vehiclesToAdd.filter((vehicle) => vehicle.type.id_vehicle_type === VehicleTypesID.BETONEIRA)
      );

      // Add the mixer vehicles current travels to the array of mixer vehicles current travels to add
      mixerVehiclesCurrentTravelsToAdd.push(
        ...vehiclesToAdd.filter((vehicle) => vehicle.type.id_vehicle_type === VehicleTypesID.BETONEIRA)
          .map((vehicle) => vehicle.current_travel)
      );
    }

    // If there are pump vehicles to add, add them
    if (pumpVehiclesToAdd.length > 0) {
      addPumpVehiclesToTimeline(pumpVehiclesToAdd, pumpVehiclesCurrentTravelsToAdd, true);
    }

    // If there are mixer vehicles to add, add them
    if (mixerVehiclesToAdd.length > 0) {
      addMixerVehiclesToTimeline(mixerVehiclesToAdd, mixerVehiclesCurrentTravelsToAdd, true);
    }

  }, [addMixerVehiclesToTimeline, addPumpVehiclesToTimeline, vehiclesInRealTimeStates]);

  // endregion Timeline update functions

  // region Effects

  // Get pump and mixer vehicles in real time states from store
  useEffect(() => {
    if (vehicles.list) {
      const vehiclesInTimelineStates = [
        ...(vehicles.list[VehicleStatesTypes.A_CAMINHO] || []),
        ...(vehicles.list[VehicleStatesTypes.EM_OBRA] || []),
        ...(vehicles.list[VehicleStatesTypes.DESCARREGANDO] || []),
        ...(vehicles.list[VehicleStatesTypes.EM_LOCAL_CONFIAVEL] || []),
        ...(vehicles.list[VehicleStatesTypes.RETORNANDO] || [])
      ];

      if (!loading && realTime) {
        // Filter only pump and mixer vehicles that have current travel
        setVehiclesInRealTimeStates(vehiclesInTimelineStates.filter((vehicle) => vehicle.current_travel?.id_travel
          && vehicle?.current_travel?.destination?.id_location
          && (vehicle?.type?.id_vehicle_type === VehicleTypesID.CAMINHAO_BOMBA || vehicle?.type?.id_vehicle_type
            === VehicleTypesID.BETONEIRA)));

        // Filter pump and mixer vehicles and sort them by current travel registration date
        setRealTimePumpVehicles(vehiclesInTimelineStates
          .filter((vehicle) => vehicle?.type?.id_vehicle_type === VehicleTypesID.CAMINHAO_BOMBA
            && vehicle?.current_travel?.id_travel && vehicle?.current_travel?.destination?.id_location));

        setRealTimeConcreteMixerVehicles(vehiclesInTimelineStates
          .filter((vehicle) => vehicle?.type?.id_vehicle_type === VehicleTypesID.BETONEIRA
            && vehicle?.current_travel?.id_travel && vehicle?.current_travel?.destination?.id_location));
      }
    }
  }, [vehicles.list, loading, realTime, t]);

  // When the travels are read, add vehicles to the timeline
  useEffect(() => {
    if (travels.length > 0) {

      const travelVehicles = travels.map((travel) => travel.vehicle);

      // Remove duplicate vehicles
      const uniqueTravelVehicles = travelVehicles.filter(
        (vehicle, index, self) => index === self.findIndex((v) => v.id_vehicle === vehicle.id_vehicle)
      );

      const pumpTravelVehicles = uniqueTravelVehicles.filter(
        (vehicle) => vehicle.type.id_vehicle_type === VehicleTypesID.CAMINHAO_BOMBA
      );

      const mixerTravelVehicles = uniqueTravelVehicles.filter(
        (vehicle) => vehicle.type.id_vehicle_type === VehicleTypesID.BETONEIRA
      );

      setPumpVehicles(pumpTravelVehicles);

      setConcreteMixerVehicles(mixerTravelVehicles);

      addPumpVehiclesToTimeline(pumpTravelVehicles, travels);
    }
  },
  // Don't need more dependencies
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [travels]);

  // When new data is received, update the timeline accordingly (only if real time is active - current day selected)
  useEffect(() => {

    if (realTime) {
      verifyVehiclesToAdd();

      vehiclesInRealTimeStates.forEach((vehicle) => {
        const groupsOfVehicleCurrentlyInTimeline = timelineGroups.filter(
          (group) => group?.title?.toString().includes(vehicle.id_vehicle)
        );

        if (groupsOfVehicleCurrentlyInTimeline.length > 0) {
          updateTimelineItems(vehicle, groupsOfVehicleCurrentlyInTimeline);
        }
      });

    }

  },
  // Don't be called if verifyVehiclesToAdd is changed
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [timelineGroups, vehiclesInRealTimeStates, updateTimelineItems, realTime, loading]);

  // Update the previous vehicles state (used to verify if there are vehicles to be added to/removed from the timeline)
  useEffect(() => {
    prevVehiclesOnTimelineStates.current = vehiclesInRealTimeStates;
  }, [pumpVehicles, vehiclesInRealTimeStates, updateTimelineItems, verifyVehiclesToAdd]);

  // Verify if filter is active to show filtered vehicles or all vehicles
  useEffect(() => {
    if (filtersDashboard.active) setVehicles(vehiclesStates.filtered as VehiclesStatesObj);
    else setVehicles(vehiclesStates.all);
  }, [filtersDashboard, vehiclesStates]);

  // Screen orientation control
  useEffect(() => {
    if (screen.width && screen.height) {

      if (screen.width > screen.height) {
        setScreenOrientation("landscape");
      } else {
        setScreenOrientation("portrait");
      }
    }
  }, [screen]);

  // Update the pump vehicles to show cards, filtering to remove duplicates
  useEffect(() => {
    setPumpVehiclesToShowCards((prev) => [...prev, ...pumpVehicles, ...realTimePumpVehicles]
      .filter((vehicle, index, self) => index === self.findIndex((v) => v.id_vehicle === vehicle.id_vehicle)));
  }, [pumpVehicles, realTimePumpVehicles]);

  // Render timeline when language is changed (also gets travels information at first render)
  useEffect(() => {
    handleLanguageChange().then();
  }, [i18n.language, handleLanguageChange]);

  // endregion Effects

  return (
    <>
      <Header title="" />
      <Container className="page">
        <CardsContainer
          onScroll={(e: any) => {
            // Scroll the timeline when the card container scroll. The math operation is to keep the alignment.
            if (timelineScrollRef.current) {
              timelineScrollRef.current.scrollTop = e.target.scrollTop > 100
                ? e.target.scrollTop + e.target.scrollTop / 100
                : e.target.scrollTop;
            }
          }}
        >
          <BombHeader>
            <Form className="form" ref={formRef} onSubmit={readTravels}>
              <div className="dayContainer">
                <h4>{t(GlobalMessages.date)}:</h4>
                <TextField
                  className="dateInput"
                  name="date"
                  type="date"
                  defaultValue={new Date().toISOString().slice(0, 10)}
                  InputLabelProps={{ shrink: true }}
                  onChange={handleDateChange}
                />
              </div>
            </Form>

            <div className="bombCountContainer">
              <ReactSVG src={BombIcon} style={{ fill: "#002951" }} />
              { pumpVehiclesToShowCards.length }
            </div>

          </BombHeader>

          { !vehicles.loading
            ? (
              <TimeLineItem
                vehicles={pumpVehiclesToShowCards}
                isInPumpTimeline
                isInRealTimePumpTimeline={realTime}
                onChange={(vehicle, stateAccordion) => handleSubTimeline(vehicle, stateAccordion)}
                pumpAndMixerAssociations={pumpAndMixerAssociations}
                showAllVehicles={false}
                closeAllTimelineAccordions={closeAllAccordions}
              />
            )
            : <> </> }
          { loading
            && (
              <LoadingContainer>
                <CircularProgress />
              </LoadingContainer>
            ) }
        </CardsContainer>
        <ChartContainer ref={timelineScrollRef}>
          { (!vehicles.loading && timelineGroups.length !== 0 && timelineItems.length !== 0) && timelineComponent() }
          { clickedItems && (getInfoLabel()) }
        </ChartContainer>
        {
          screen.platform === "desktop" && <FloatingMenu />
        }
        <DialogLandscape isOpen={screenOrientation === "portrait"} />
      </Container>
    </>
  );
};

export default PumpTimeline;
