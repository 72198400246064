/* eslint-disable max-len */

import React, { Fragment, useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { ProgressBar, Step } from "react-step-progress-bar";
import { ReactSVG } from "react-svg";
import "react-step-progress-bar/styles.css";
import "react-vertical-timeline-component/style.min.css";
import _ from "lodash";

import classNames from "classnames";

// region Imports - Utils
import utils from "@utils/useful-functions";
// endregion Imports - Utils
// region Imports - Services
import api from "@services/api";
// endregion Imports - Services
// region Imports - Icons
import {
  Caminho as IconCaminho,
  Descarregando as IconDescarregando,
  DescarregandoIndevido as IconDescarregamentoIndevido,
  GpsSignal as IconGpsSignal,
  HourMeter as IconHourMeter,
  Key,
  Locked as IconLocked,
  Obra as IconObra,
  Odometer as IconOdometer,
  Remove as IconRemove,
  Retornando as IconRetornando,
  SensorSignal as IconSensorSignal,
  Usina as IconUsina,
  IconInvoice,
  IconIgnitionOn,
  IconIgnitionOff,
  IconArrivalReturn
} from "@libraries/icons";
import InfoIcon from "@assets/icons/timeline/info.svg";
// endregion Imports - Icons
// region Imports - Material-UI
import { Accordion, AccordionDetails, AccordionSummary, Badge, Button, Divider, Tooltip } from "@libraries/mui";
import { CheckCircleOutlined } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
// endregion Imports - Material-UI
// region Imports - Languages
import useTranslation from "src/translations/useTranslation";
import {
  AlertTypeMessages,
  GlobalMessages, LocationTypeMessages,
  TimelineVehiclesItemMessages,
  VehicleBlockMessages,
  VehiclesStatesTypesMessages
} from "@shared/languages/interfaces";
// endregion Imports - Languages
// region Imports - Atoms
import Span from "@atoms/Span";
import SubVehicleOfPump from "src/atoms/SubVehicleOfPump";
// endregion Imports - Atoms
// region Imports - Components
import { ModalVehicleAndTravelDetails } from "@components/Modal/VehicleAndTravelDetails";
import { TimeLineItemMap } from "@components/TimeLines/TimeLineItemMap";
// endregion Imports - Components
// region Imports - Pages
import { PumpAndMixerAssociationType } from "@pages/PumpTimeline";
// endregion Imports - Pages
// region Imports - Shared
import { Status as StatusColor } from "@shared/constants/colors.enum";
import { HardwareTypesID } from "@shared/constants/hardware-types.enum";
import { InternalParams } from "@shared/constants/internal-params.enum";
import { VehicleStatesTypesID } from "@shared/constants/vehicle-states-types.enum";
import { VehicleTypesID } from "@shared/constants/vehicle-types.enum";
import { AlertTypes, AlertTypesID } from "@shared/constants/alert-types.enum";
import { Travel } from "@shared/interfaces/travel.interface";
import { Vehicle } from "@shared/interfaces/vehicle.interface";
import { VehicleState } from "@shared/interfaces/vehicle-state.interface";
import { formatOdometer } from "@shared/utils/useful-functions";
import { GoogleApiRequestTypes } from "@shared/constants/google-api-request-types.enum";
// endregion Imports - Shared
// region Imports - Hooks
import { useAuth } from "@hooks/useAuth";
import { useToast } from "@hooks/useToast";
// endregion Imports - Hooks
// region Imports - Styles
import { Container, IconBoxContainer } from "./styles";
// endregion Imports - Styles

interface ArrivalForecastData {
  distance: string;
  duration: string;
  isLoading?: boolean;
}

type TimeLineItemProps = {
  vehicles: Vehicle[],
  showAllVehicles?: boolean,
  showTimeLine?: boolean,
  showMap?: boolean,
  openDirect?: boolean,
  position?: "dashboard" | "menu",
  isInPumpTimeline?: boolean,
  isInRealTimePumpTimeline?: boolean,
  onChange?: (vehicle: Vehicle, stateAccordion: { [index: string]: boolean }) => void,
  pumpAndMixerAssociations?: PumpAndMixerAssociationType,
  closeAllTimelineAccordions?: boolean
};
const TimeLineItem: React.FC<TimeLineItemProps> = ({
  vehicles,
  showAllVehicles,
  showTimeLine,
  showMap,
  position = "dashboard",
  isInPumpTimeline = false,
  isInRealTimePumpTimeline = false,
  onChange,
  pumpAndMixerAssociations,
  closeAllTimelineAccordions
}) => {

  // region Hooks
  const { t } = useTranslation();
  const { addToast } = useToast();

  const improperDischargeAlerts = useSelector(({ alerts: state }) => state.all.list.filter(
    (alert) => alert.type.id_alert_type === AlertTypesID.DESCARREGAMENTO_INDEVIDO
  ));
  const screen = useSelector(({ screen: state }) => state);

  const { user } = useAuth();
  // endregion Hooks
  // region States
  const [stateAccordion, setStateAccordion] = useState<{ [index: string]: boolean }>({});
  const [selectedVehicle, setSelectedVehicle] = useState<Vehicle>({} as Vehicle);
  const [selectedTravel, setSelectedTravel] = useState<Travel>({} as Travel);
  const [arrivalForecast, setArrivalForecast] = useState<{[index: string]: ArrivalForecastData}>({});
  const [openModalVehicleAndTravelDetails, setOpenModalVehicleAndTravelDetails] = useState(false);
  // endregion States
  // region Refs
  // Last location of vehicle fetched to get arrival forecast. Location formatted in '{lat},{lng}'
  const lastLocationFetchedArrivalForecast = React.useRef<{[index: string]: string}>({});
  // endregion Refs
  // region Functions

  // Functions to define some items from vehicles
  const defineProps = {

    definePercentProgressAccordingState: (vehicleCurrentStateID: string): number => {

      switch (vehicleCurrentStateID) {
      case VehicleStatesTypesID.NA_USINA:
        return 0;
      case VehicleStatesTypesID.A_CAMINHO:
        return 25;
      case VehicleStatesTypesID.EM_OBRA:
        return 50;
      case VehicleStatesTypesID.DESCARREGANDO:
        return 75;
      case VehicleStatesTypesID.RETORNANDO:
        return 100;
      default:
        return 0;
      }
    },
    defineColorTimelineAccordingState: (vehicleCurrentStateID: string): string => {

      switch (vehicleCurrentStateID) {
      case VehicleStatesTypesID.NA_USINA:
        return "oneColor";
      case VehicleStatesTypesID.A_CAMINHO:
        return "oneColor";
      case VehicleStatesTypesID.EM_OBRA:
        return "twoColors";
      case VehicleStatesTypesID.DESCARREGANDO:
        return "threeColors";
      case VehicleStatesTypesID.RETORNANDO:
        return "fourColors";
      default:
        return "";
      }
    }
  };

  /**
   * Get arrival forecast from Google Maps API
   * @param origin Latitude and longitude of origin formatted in '{lat},{lng}'
   * @param destination Latitude and longitude of destination formatted in '{lat},{lng}'
   * @param idVehicle Vehicle id
   * @param callbackOrigin Origin of the call ("newRender" default)
   * @returns Arrival forecast formatted with unit
   */
  const refreshArrivalForecast = useCallback(async (
    origin: string,
    destination: string,
    idVehicle: string,
    callbackOrigin: "newRender" | "refreshButton" = "newRender") => {

    const isFirstRender = callbackOrigin === "newRender" && !lastLocationFetchedArrivalForecast.current[idVehicle];

    if (!isFirstRender) {

      if (callbackOrigin !== "refreshButton") return;

      // If the last location fetched is the same as the origin, simulate loading and do not call the API again
      if (lastLocationFetchedArrivalForecast.current?.[idVehicle] === origin) {
        simulateArrivalForecastLoading(idVehicle);

        return;
      }

      // Set loading to true to fetch new data
      setArrivalForecast({ ...arrivalForecast, [idVehicle]: { ...arrivalForecast[idVehicle], isLoading: true } });
    }

    lastLocationFetchedArrivalForecast.current[idVehicle] = origin;

    try {
      await utils.loadGoogleMapsAPI();

      const distanceMatrixService = new google.maps.DistanceMatrixService();
      const request: google.maps.DistanceMatrixRequest = {
        origins: [origin],
        destinations: [destination],
        travelMode: google.maps.TravelMode.DRIVING
      };

      distanceMatrixService.getDistanceMatrix(request, (response, status) => {
        if (status === google.maps.DistanceMatrixStatus.OK) {
          const distanceMeters = response.rows[0].elements[0].distance.value;
          const durationSeconds = response.rows[0].elements[0].duration.value;

          const duration = utils.calcDataRangeFromSeconds(durationSeconds);

          const distanceLabel = `${formatOdometer(distanceMeters, false)} km`;
          const durationLabel = `${utils.formatDateIfHave(duration, "durationDescriptiveTime")}`;

          setArrivalForecast({
            ...arrivalForecast, [idVehicle]: { distance: distanceLabel, duration: durationLabel }
          });

        } else {
          addToast({ type: "error", title: t(TimelineVehiclesItemMessages.errorLoadingArrivalForecast) });
        }
      });

    } catch (error) {
      addToast({ type: "error", title: t(TimelineVehiclesItemMessages.errorLoadingArrivalForecast) });
    } finally {
      try {
        await api.post("/logs/save", {
          context: "Frontend",
          description: GoogleApiRequestTypes.ARRIVAL_FORECAST,
          user: {
            name: user.name,
            email: user.email
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addToast, t, arrivalForecast]);

  /**
   * Simulate arrival forecast loading (0.3s ~ 0.75s)
   * @param idVehicle Vehicle id
   */
  const simulateArrivalForecastLoading = useCallback((idVehicle: string) => {
    setArrivalForecast({ ...arrivalForecast, [idVehicle]: { ...arrivalForecast[idVehicle], isLoading: true } });

    // Random delay between 300ms and 750ms
    const randomDelay = Math.floor(Math.random() * (750 - 300 + 1)) + 300;

    setTimeout(() => {
      setArrivalForecast({ ...arrivalForecast, [idVehicle]: { ...arrivalForecast[idVehicle], isLoading: false } });
    }, randomDelay);
  }, [arrivalForecast]);

  /**
   * Get hardware of tracker type
   * @param vehicle vehicle to search
   */
  const getVehicleTracker = useCallback((vehicle: Vehicle) => {
    const tracker = vehicle?.hardwares?.filter(
      (hardware) => hardware.type.id_hardware_type === HardwareTypesID.RASTREADOR
    )?.[0];

    if (!tracker) return null;

    return tracker;
  }, []);

  /*
   * Treatment to close all pump timeline accordions
   */
  if (isInPumpTimeline && closeAllTimelineAccordions) {
    vehicles.forEach((vehicle) => {
      stateAccordion[vehicle.id_vehicle] = false;
    });
  }

  /**
   * Control event of collapse panels
   * @param key Panel id to change
   */
  const handleAccordion = useCallback((key: string) => {

    if (onChange) {
      onChange(vehicles.find((vehicle) => vehicle.id_vehicle === key) as Vehicle,
        { ...stateAccordion, [key]: !stateAccordion[key] });
    }

    setStateAccordion({ ...stateAccordion, [key]: !stateAccordion[key] });
  }, [stateAccordion, onChange, vehicles]);

  /**
   * Open details or analytics of travel
   * @param vehicle Vehicle to open details
   */
  const handleOpenDetails = useCallback((vehicle: Vehicle) => {

    setSelectedVehicle({ ...vehicle, states: vehicle.states });
    setSelectedTravel(vehicle.current_travel);
    setOpenModalVehicleAndTravelDetails(true);

  }, []);

  /**
   * Use Memo to prevent multiples renderings of tooltip states
   * @param vehicle Vehicle to open details
   */
  const tooltipStates = useMemo(() => {

    interface GroupedStateInterface {[index: string]: VehicleState[]}

    // Group vehicle states by state ("Em local confiavel" is grouped in "A caminho" or "Retornando")
    const groupStates = (data: Vehicle) => {
      const states: GroupedStateInterface = {
        [VehicleStatesTypesID.NA_USINA]: [],
        [VehicleStatesTypesID.A_CAMINHO]: [],
        [VehicleStatesTypesID.EM_OBRA]: [],
        [VehicleStatesTypesID.DESCARREGANDO]: [],
        [VehicleStatesTypesID.RETORNANDO]: []
      };

      let going = true;

      data.states.forEach((state) => {
        if (state.status.id_vehicle_state_type === VehicleStatesTypesID.NA_USINA) {
          states[VehicleStatesTypesID.NA_USINA].push(state);
        } else if (state.status.id_vehicle_state_type === VehicleStatesTypesID.A_CAMINHO) {
          states[VehicleStatesTypesID.A_CAMINHO].push(state);
        } else if (state.status.id_vehicle_state_type === VehicleStatesTypesID.EM_OBRA) {
          states[VehicleStatesTypesID.EM_OBRA].push(state);
        } else if (state.status.id_vehicle_state_type === VehicleStatesTypesID.DESCARREGANDO) {
          states[VehicleStatesTypesID.DESCARREGANDO].push(state);
        } else if (state.status.id_vehicle_state_type === VehicleStatesTypesID.RETORNANDO) {
          states[VehicleStatesTypesID.RETORNANDO].push(state);
          going = false;
        } else if (state.status.id_vehicle_state_type === VehicleStatesTypesID.EM_LOCAL_CONFIAVEL && going) {
          states[VehicleStatesTypesID.A_CAMINHO].push(state);
        } else {
          states[VehicleStatesTypesID.RETORNANDO].push(state);
        }
      });

      return states;
    };

    const generateTooltipContent = (states: VehicleState[]) => {
      const tooltipContent = [] as JSX.Element[];

      states.forEach((state, idx) => {
        tooltipContent.push(
          <span key={state.id_vehicle_state}>
            {idx > 0 ? <br /> : null}
            {t(VehiclesStatesTypesMessages[state.status.id_vehicle_state_type])}
            <br />
            {t(GlobalMessages.start)}: {utils.formatDateIfHave(state.registration_date, "fullDate")}
            <br />
            {t(GlobalMessages.duration)}: {
              state.duration
                ? utils.formatDateIfHave(state.duration, "durationDescriptiveTime")
                : utils.formatDateIfHave(utils.calcDataRange(state?.registration_date || new Date(), new Date()),
                  "durationDescriptiveTime")
            }
            {states.length > 1 ? <br /> : null}
          </span>
        );
      });

      return (
        tooltipContent.length > 0
          ? <div style={{ fontSize: "1rem", lineHeight: "1rem" }}>{tooltipContent}</div>
          : null
      );
    };

    const groupedStates: { [index: string]: GroupedStateInterface; } = {};

    // Loop over vehicles and group by vehicle_id and state
    vehicles.forEach((data) => {
      groupedStates[data.id_vehicle] = groupStates(data);
    });

    const tooltipsComponent = {} as { [index: string]: { [index: string]: JSX.Element | null } };

    // Loop over grouped states and generate tooltip content
    Object.keys(groupedStates).forEach((key) => {
      if (!tooltipsComponent[key]) tooltipsComponent[key] = {};
      Object.keys(groupedStates[key]).forEach((state) => {
        tooltipsComponent[key][state] = generateTooltipContent(groupedStates[key][state]);
      });
    });

    return tooltipsComponent;
  }, [vehicles, t]);

  /**
   * Generate tooltip timeline content
   * @param idVehicle Vehicle id
   * @param accomplished
   * @param stateName
   * @param icon
   */
  const generateTimelineTooltip = useCallback((idVehicle: string, accomplished: boolean,
    stateTypeID: string, icon: string) => {

    // Icon representing state.
    const svgComponent = (
      <ReactSVG
        className={classNames("icon", accomplished ? "active" : "inactive")}
        src={icon}
      />
    );

    // In case don't have state "time", show only icon
    if (tooltipStates[idVehicle][stateTypeID] === null) return svgComponent;

    // In case have state "time", show icon and tooltip
    return (
      <Tooltip
        arrow
        disableFocusListener
        enterTouchDelay={0}
        leaveTouchDelay={0}
        placement="top"
        title={tooltipStates[idVehicle][stateTypeID] ?? ""}
      >
        {svgComponent}
      </Tooltip>
    );
  }, [tooltipStates]);

  /**
   * Generate box header item (Icon of vehicle)
   * @param vehicle Vehicle to generate header
   */
  const generateBoxHeaderItem = useCallback((vehicle: Vehicle) => {

    const hasImproperDischarge = vehicle.alerts.some(
      (alert) => alert.type.id_alert_type === AlertTypesID.DESCARREGAMENTO_INDEVIDO
    );
    const additionalClass = hasImproperDischarge ? "pulse" : "";

    return (
      <div
        className={`vehicle-summary__symbol ${additionalClass}`}
        style={{ backgroundColor: utils.getVehicleIconColor(vehicle, user, improperDischargeAlerts) }}
      >
        <Badge
          badgeContent={<><ReactSVG src={vehicle?.engine ? IconIgnitionOn : IconIgnitionOff} wrapper="svg" /> <span>{vehicle?.engine ? "on" : "off"}</span></>}
          className={vehicle?.engine ? "on" : "off"}
        >
          {utils.getVehicleIconAccordingTypeAndAlert(vehicle, improperDischargeAlerts)}
        </Badge>
      </div>
    );
  },
  // isInPumpTimeline and screen.platform are used in styled component
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [user, improperDischargeAlerts, isInPumpTimeline, screen.platform]);

  /**
   * Render blocked vehicle info
   * @param vehicle
   */
  const generateBlockedVehicleInfo = useCallback((vehicle: Vehicle) => {

    const blockAlerts = vehicle.alerts.filter((alert) => alert.type.description === AlertTypes.BLOQUEIO);
    const lastBlockAlert = blockAlerts[blockAlerts.length - 1];

    return (

      <div className="vehicle-details__row--blocked">
        <div className="vehicle-details__block--blocked">
          <div className="vehicle-details__icon">
            <ReactSVG
              src={IconLocked}
            />
          </div>
          <div
            className="vehicle-details__description"
            style={{
              color: StatusColor.ERROR,
              fontWeight: "bold"
            }}
          >
            {t(VehicleBlockMessages.blockedVehicle)} {utils.formatDateIfHave(lastBlockAlert?.occurrence_date, "fullDateWithoutSeconds")}
          </div>
        </div>
      </div>
    );

  }, [t]);

  /**
   * Render box icon (right side of card)
   * @param vehicleData Data of current vehicle
   */
  const generateBoxIconStatus = useCallback((vehicleData: Vehicle) => {

    const isPumpedOnCurrentTravel = !_.isEmpty(vehicleData?.current_travel?.destination?.id_location);

    // Set the invoice icon color to green(StatusColor.ACTIVE) if there is an invoice linked to the vehicle, default color is gray(StatusColor.INACTIVE)
    const getInvoiceColor = () => {
      if (vehicleData.current_travel && vehicleData.current_travel.num_doc) return StatusColor.ACTIVE;

      return StatusColor.INACTIVE;
    };

    if (isInPumpTimeline && isInRealTimePumpTimeline && isPumpedOnCurrentTravel) {
      return (
        <IconBoxContainer>
          <div>
            <Button
              disableRipple
              style={{ padding: 0, minWidth: 0, backgroundColor: "transparent" }}
              className="details"
              onClick={() => { handleOpenDetails(vehicleData); }}
            >
              <ReactSVG src={InfoIcon} />
            </Button>
          </div>
          <div className="vehicle-summary__hardwares">
            <div
              className="vehicle-summary__hardware-icon"
            >
              {(() => {
                const tracker = getVehicleTracker(vehicleData);

                if (tracker) {
                  return (
                    <ReactSVG
                      src={IconGpsSignal}
                      style={{ fill: utils.getHardwareDataStyle(tracker, "color") }}
                    />
                  );
                }

                return (<></>);
              })()}
            </div>
          </div>
        </IconBoxContainer>
      );
    }

    // Define color for speed info according to vehicle current speed
    const getSpeedColor = () => {

      if (!vehicleData.current_speed) return StatusColor.INACTIVE;

      if (vehicleData.maximum_speed_allowed
        && (Number(vehicleData.current_speed) * InternalParams.SPEED_MARGIN)
        > Number(vehicleData.maximum_speed_allowed)) {
        return StatusColor.ERROR;
      }

      return StatusColor.ACTIVE;
    };

    return (
      <>
        <div className="vehicle-summary__hardwares">
          {vehicleData.hardwares.map((hardware) => (
            <div
              className="vehicle-summary__hardware-icon"
              key={hardware.dev_id}
            >
              <ReactSVG
                className="icon"
                src={hardware.type.id_hardware_type === HardwareTypesID.RASTREADOR ? IconGpsSignal
                  : IconSensorSignal}
                style={{ fill: utils.getHardwareDataStyle(hardware, "color") }}
              />
            </div>
          ))}
        </div>
        <div>
          <ReactSVG
            className="icon"
            src={IconInvoice}
            style={{
              fill: getInvoiceColor(),
              width: 18,
              height: 18
            }}
            title={`Nº Nota fiscal: ${(vehicleData.current_travel && vehicleData.current_travel.num_doc ? vehicleData.current_travel.num_doc : 0)}`}
          />
        </div>
        <div
          className="vehicle-summary__speed"
          style={{ color: getSpeedColor() }}
        >
          {vehicleData.current_speed ? (Math.floor(vehicleData.current_speed * InternalParams.SPEED_MARGIN))
            : <IconRemove />}
        </div>
      </>

    );
  }, [isInPumpTimeline, isInRealTimePumpTimeline, getVehicleTracker, handleOpenDetails]);

  /**
   * Render state duration info
   * @param vehicleData Data of current vehicle
   */
  const generateStateDurationInfo = useCallback((vehicleData: Vehicle) => {

    const stateTypeID = vehicleData?.states[vehicleData?.states?.length - 1]?.status?.id_vehicle_state_type || "";
    const duration = utils.formatDateIfHave(
      utils.calcDataRange(
        vehicleData?.states[vehicleData?.states?.length - 1]?.registration_date || new Date(),
        new Date()
      ),
      "durationDescriptiveTime"
    );

    return (
      <>
        <div className="vehicle-details__icon--state">
          {utils.getStateIcon(stateTypeID)}
        </div>
        <div className="vehicle-details__description--state">
          <span style={{ color: utils.getStateColor(stateTypeID) }}>
            {t(VehiclesStatesTypesMessages[stateTypeID])}
          </span> {t(TimelineVehiclesItemMessages.for)} {duration}
        </div>
      </>
    );
  }, [t]);

  /**
   * Render previous state duration info
   * @param vehicleData Data of current vehicle
   */
  const generatePreviousStateDurationInfo = useCallback((vehicleData: Vehicle) => {

    const state = vehicleData?.states[vehicleData?.states?.length - 2];
    const stateTypeID = state?.status?.id_vehicle_state_type || "";

    if (state) {

      const duration = utils.formatDateIfHave(
        vehicleData?.states[vehicleData?.states?.length - 2]?.duration, "durationDescriptiveTime"
      ) || "";

      const stateDescription = (
        <span style={{ color: utils.getStateColor(stateTypeID) }}>
          {t(VehiclesStatesTypesMessages[stateTypeID])}
        </span>
      );

      return (
        <div className="vehicle-details__row--last-state">
          <div className="vehicle-details__block--last-state">
            <>{t(TimelineVehiclesItemMessages.before)} {stateDescription} {t(TimelineVehiclesItemMessages.during)} {duration}</>
          </div>
        </div>
      );
    }

    return null;
  }, [t]);

  const generateArrivalForecast = useCallback((vehicleData: Vehicle) => {
    if (!vehicleData.current_travel || !stateAccordion[vehicleData.id_vehicle]) return (<></>);

    const currentVehicleStateId = vehicleData.states[vehicleData.states.length - 1].status.id_vehicle_state_type;
    const vehicleIsOnTheWayWithDestination = (currentVehicleStateId === VehicleStatesTypesID.A_CAMINHO
      && !!vehicleData?.current_travel?.destination);

    if (currentVehicleStateId !== VehicleStatesTypesID.RETORNANDO && !vehicleIsOnTheWayWithDestination) return (<></>);

    const origin = `${vehicleData.hardwares[0].last_latitude},${vehicleData.hardwares[0].last_longitude}`;
    const destination = `${vehicleData.current_travel.destination.latitude},${vehicleData.current_travel.destination.longitude}`;

    // noinspection JSIgnoredPromiseFromCall (no need to await)
    refreshArrivalForecast(origin, destination, vehicleData.id_vehicle);

    const stateDestinationId = vehicleIsOnTheWayWithDestination
      ? VehicleStatesTypesID.EM_OBRA : VehicleStatesTypesID.NA_USINA;
    const destinationPlace = vehicleIsOnTheWayWithDestination
      ? t(TimelineVehiclesItemMessages.construction) : t(TimelineVehiclesItemMessages.plant);
    const tooltipContent = vehicleIsOnTheWayWithDestination
      ? vehicleData.current_travel.destination.name : vehicleData.current_travel.origin.name;

    const stateDescription = (
      <span style={{ color: utils.getStateColor(stateDestinationId) }}>
        {destinationPlace}
      </span>
    );

    const buttonRefresh = (
      <ReactSVG
        src={IconArrivalReturn}
        onClick={() => refreshArrivalForecast(origin, destination, vehicleData.id_vehicle, "refreshButton")}
      />
    );

    return (
      <div className="vehicle-details__row--arrival-forecast">
        {arrivalForecast[vehicleData.id_vehicle] && !arrivalForecast[vehicleData.id_vehicle].isLoading ? (
          <div className="vehicle-details__block--arrival-forecast">
            <div className="vehicle-details__description">
              { arrivalForecast[vehicleData.id_vehicle].duration } - { arrivalForecast[vehicleData.id_vehicle].distance }
              {t(TimelineVehiclesItemMessages.toThe)} {stateDescription}
            </div>
            <div className="vehicle-details__icon">
              {tooltipContent ? (
                <Tooltip
                  title={tooltipContent}
                  arrow
                  disableFocusListener
                  enterTouchDelay={0}
                  leaveTouchDelay={0}
                  placement="top"
                >
                  { buttonRefresh }
                </Tooltip>
              ) : (buttonRefresh)}
            </div>
          </div>
        ) : (<Skeleton width="80%"/>) }
      </div>
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateAccordion, arrivalForecast]);

  /**
   * Render improper discharge icon if vehicle has improper discharge alert
   * @param vehicleData Data of current vehicle
   */
  const checkAlertAndGenerateImproperDischargeIcon = useCallback((vehicleData: Vehicle) => {

    let hasAlert = false;

    for (const alert of vehicleData.alerts) {
      if (alert.type.id_alert_type === AlertTypesID.DESCARREGAMENTO_INDEVIDO) {
        hasAlert = true;
        break;
      }
    }

    if (!hasAlert) return (<></>);

    return (
      <div className="vehicle-details__row--alerts">
        <div className="vehicle-details__block--alerts">
          <div className="vehicle-details__icon">
            <ReactSVG
              src={IconDescarregamentoIndevido}
              style={{
                fill: StatusColor.ERROR,
                backgroundColor: StatusColor.ERROR
              }}
            />
          </div>
          <div className="vehicle-details__description">
            {t(AlertTypeMessages[AlertTypesID.DESCARREGAMENTO_INDEVIDO])}
          </div>
        </div>
      </div>
    );
  }, [t]);

  /**
   * Generate last update text info
   * @param vehicleData Data of current vehicle
   */
  const generateLastUpdateInfo = useCallback((vehicleData: Vehicle) => {

    let description = t(TimelineVehiclesItemMessages.latestUpdate);

    const timeElapsed = utils.formatDateIfHave(
      utils.calcDataRange(vehicleData.hardwares[0].last_send_date, new Date()),
      "durationDescriptiveTime", {
        durationDescriptiveTime: {
          onlyMinutes: true
        }
      }
    );

    if (timeElapsed === t(TimelineVehiclesItemMessages.rightNow)) {
      // Updated right now
      description += timeElapsed;
    } else {
      // Updated 31/12/2000, 2h 15m ago
      description += utils.formatDateIfHave(vehicleData.hardwares[0].last_send_date, "dateDescriptive");
      description += ", ";
      description += timeElapsed;
      description += ` ${t(TimelineVehiclesItemMessages.ago)}`;

    }

    return description;
  }, [t]);

  /**
   * Render accordion info
   * @param vehicleData Vehicle to render
   */
  const generateAccordionInfo = useCallback((vehicleData: Vehicle) => {

    // If it is currently shown on pump timeline, the accordion shows the mixers associated with the pump
    if (isInPumpTimeline && pumpAndMixerAssociations) {
      const associatedMixersElements: JSX.Element[] = [];

      Object.keys(pumpAndMixerAssociations).forEach((key) => {

        if (key === vehicleData.id_vehicle) {

          // For each mixer associated with the pump, create a subVehicle component
          pumpAndMixerAssociations[key].forEach((mixer) => {
            associatedMixersElements.push(
              <div className="accordionPumpTimeline" key={mixer.id_vehicle}>
                <SubVehicleOfPump vehicle={mixer} />
              </div>
            );
          });
        }
      });

      return (
        <>
          {associatedMixersElements.map((subVehicle) => (
            <div key={subVehicle.key}>{subVehicle}</div>
          ))}
        </>
      );
    }

    return (
      <>
        {showMap && (
          <TimeLineItemMap
            latitude={Number(vehicleData.hardwares[0].last_latitude)}
            longitude={Number(vehicleData.hardwares[0].last_longitude)}
            vehicleTypeID={vehicleData.type.id_vehicle_type}
            vehicleStateTypeID={vehicleData?.states[vehicleData?.states?.length - 1]?.status?.id_vehicle_state_type || ""}
            lastSendTrackerDate={vehicleData.hardwares[0].last_send_date}
          />
        )}
        <div className="vehicle-details__content">
          <div className="vehicle-details__row--state-odometer">
            <div className="vehicle-details__block--state">
              {generateStateDurationInfo(vehicleData)}
            </div>
            <div className="vehicle-details--travelInfo">
              <div className="vehicle-details__block--odometer">
                <div className="vehicle-details__icon--odometer">
                  <Tooltip
                    arrow
                    disableFocusListener
                    enterTouchDelay={0}
                    leaveTouchDelay={0}
                    placement="top"
                    title={t(TimelineVehiclesItemMessages.distanceTraveledOnTrip)}
                  >
                    <ReactSVG
                      src={IconOdometer}
                      style={{
                        fill: StatusColor.ACTIVE,
                        backgroundColor: StatusColor.ACTIVE
                      }}
                    />
                  </Tooltip>

                </div>
                <div className="vehicle-details__description--odometer">
                  {utils.getCurrentTravelMeteringInfo(vehicleData, "odometer")}
                </div>
              </div>
            </div>
          </div>
          {showTimeLine && (
            <div className="vehicle-details__row--timeline">
              <div className={classNames("vehicle-details__block--timeline",
                defineProps.defineColorTimelineAccordingState(
                  vehicleData?.states[vehicleData?.states?.length - 1]?.status?.id_vehicle_state_type || ""
                ))}
              >
                <ProgressBar
                  percent={defineProps.definePercentProgressAccordingState(
                    vehicleData?.states[vehicleData?.states?.length - 1]?.status?.id_vehicle_state_type || ""
                  )}
                >
                  <Step transition="scale">
                    {({ accomplished }) => (
                      generateTimelineTooltip(vehicleData.id_vehicle, accomplished, VehicleStatesTypesID.NA_USINA,
                        IconUsina)
                    )}
                  </Step>
                  <Step transition="scale">
                    {({ accomplished }) => (
                      generateTimelineTooltip(vehicleData.id_vehicle, accomplished, VehicleStatesTypesID.A_CAMINHO,
                        IconCaminho)
                    )}
                  </Step>
                  <Step transition="scale">
                    {({ accomplished }) => (
                      generateTimelineTooltip(vehicleData.id_vehicle, accomplished, VehicleStatesTypesID.EM_OBRA,
                        IconObra)
                    )}
                  </Step>
                  <Step transition="scale">
                    {({ accomplished }) => (
                      generateTimelineTooltip(vehicleData.id_vehicle, accomplished, VehicleStatesTypesID.DESCARREGANDO,
                        IconDescarregando)
                    )}
                  </Step>
                  <Step transition="scale">
                    {({ accomplished }) => (
                      generateTimelineTooltip(vehicleData.id_vehicle, accomplished, VehicleStatesTypesID.RETORNANDO,
                        IconRetornando)
                    )}
                  </Step>
                </ProgressBar>
              </div>
            </div>
          )}
          {generateArrivalForecast(vehicleData)}
          {generatePreviousStateDurationInfo(vehicleData)}
          <div className="vehicle-details__row--hourMeter">
            <div className="vehicle-details__block--hourMeter">
              <div className="vehicle-details__icon">
                <ReactSVG
                  src={IconHourMeter}
                  style={{
                    fill: StatusColor.ACTIVE,
                    backgroundColor: StatusColor.ACTIVE
                  }}
                />
              </div>
              <div className="vehicle-details__description">
                {t(TimelineVehiclesItemMessages.vehicleRunningFor)} {utils.getCurrentTravelMeteringInfo(vehicleData, "hourMeter")}
              </div>
            </div>
          </div>
          {checkAlertAndGenerateImproperDischargeIcon(vehicleData)}
          <div className="vehicle-details__row--tracker">
            <div className="vehicle-details__block--tracker">
              <div className="vehicle-details__icon">
                {(() => {
                  const tracker = getVehicleTracker(vehicleData);

                  if (tracker) {
                    return (
                      <ReactSVG
                        src={IconGpsSignal}
                        style={{ fill: utils.getHardwareDataStyle(tracker, "color") }}
                      />
                    );
                  }

                  return (<></>);
                })()}
              </div>
              <div className="vehicle-details__description">
                {generateLastUpdateInfo(vehicleData)}
              </div>
            </div>
            {vehicleData.blocked
              && (
                <> <br />
                  {generateBlockedVehicleInfo(vehicleData)}
                </>
              )}
          </div>
        </div>
        {position === "dashboard" && (<Divider />)}
        <div className="actions">
          <Button
            disableRipple
            className="details"
            onClick={() => { handleOpenDetails(vehicleData); }}
          >
            {t(TimelineVehiclesItemMessages.moreInfo)}
          </Button>
        </div>
      </>
    );
  }, [
    defineProps,
    generateBlockedVehicleInfo,
    generateStateDurationInfo,
    generatePreviousStateDurationInfo,
    checkAlertAndGenerateImproperDischargeIcon,
    generateLastUpdateInfo,
    generateTimelineTooltip,
    handleOpenDetails,
    isInPumpTimeline,
    position,
    showMap,
    showTimeLine,
    pumpAndMixerAssociations,
    getVehicleTracker,
    t
  ]);
  // endregion Functions

  return (
    <Container platform={screen.platform} isInTimelinePump={isInPumpTimeline}>
      {vehicles.map((vehicleData: Vehicle) => (
        (
          (!showAllVehicles
            && (vehicleData.type.id_vehicle_type === VehicleTypesID.BETONEIRA || vehicleData.type.id_vehicle_type
              === VehicleTypesID.CAMINHAO_BOMBA)
          ) || showAllVehicles
        ) && (
          <Fragment key={vehicleData.id_vehicle}>
            <Accordion
              square
              className="vehicle-summary__accordion"
              style={{ marginTop: screen.platform === "mobile" ? -5 : 0 }}
              expanded={stateAccordion[vehicleData.id_vehicle]}
              key={vehicleData.id_vehicle}
              onChange={() => handleAccordion(vehicleData.id_vehicle)}
            >
              <AccordionSummary
                className="vehicle-summary"
                aria-controls="bh-content"
              >
                <div className="vehicle-summary__content">
                  {generateBoxHeaderItem(vehicleData)}
                  <div className="vehicle-summary__block--description">
                    <Span className="vehicle-summary__code">{vehicleData.code}</Span>
                    <Span
                      className="vehicle-summary__plate"
                      style={vehicleData.blocked
                        ? { color: "#F00", fontWeight: "bold" }
                        : {}}
                    >
                      {vehicleData.license_plate} {vehicleData.blocked && ` - ${t(VehicleBlockMessages.blockedVehicle)}`}
                    </Span>
                    <Span className="vehicle-summary__driver">
                      {(() => {

                        if (vehicleData?.current_travel) {

                          // If vehicle has ibutton verified driver, show driver name with badge
                          if (vehicleData?.driver?.name && vehicleData?.driver_ibutton_verified) {
                            return (
                              <>
                                <CheckCircleOutlined
                                  className="vehicle-summary__driver--ibutton"
                                  titleAccess={t(TimelineVehiclesItemMessages.driverIbuttonVerified)}
                                />
                                { vehicleData?.driver?.name }
                              </>
                            );
                          }

                          // Driver of current travel
                          if (vehicleData.current_travel?.driver?.name) {
                            return vehicleData.current_travel.driver.name;
                          }

                          // Driver associated as default
                          if (vehicleData?.driver?.name) return vehicleData.driver.name;

                          return t(TimelineVehiclesItemMessages.noDriver);
                        }

                        if (vehicleData?.current_location) {
                          return `${t(LocationTypeMessages[vehicleData.current_location.type.id_location_type])}:
                          ${vehicleData.current_location.name || ""}`;
                        }

                        return t(TimelineVehiclesItemMessages.noCurrentAllocation);
                      })()}
                    </Span>
                  </div>
                  <div className="vehicle-summary__block--status">
                    {generateBoxIconStatus(vehicleData)}
                  </div>
                </div>
                <div className={`vehicle-summary__collapse-icon${stateAccordion[vehicleData.id_vehicle] ? "--open"
                  : "--close"}`}
                />
              </AccordionSummary>
              <AccordionDetails className="vehicle-details">
                {generateAccordionInfo(vehicleData)}
              </AccordionDetails>
            </Accordion>
            {position === "menu" && (<Divider />)}
          </Fragment>
        )
      ))}
      <div className="modals">
        {openModalVehicleAndTravelDetails && (

          <ModalVehicleAndTravelDetails
            vehicle={selectedVehicle}
            travel={selectedTravel}
            open={openModalVehicleAndTravelDetails}
            onClose={() => setOpenModalVehicleAndTravelDetails(false)}
          />
        )}
      </div>
    </Container>
  );

};

TimeLineItem.defaultProps = {
  showAllVehicles: true,
  showTimeLine: true,
  showMap: true,
  openDirect: false,
  position: "dashboard"
};

export default TimeLineItem;
