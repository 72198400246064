import styled from "styled-components";

export const ContainerMapFixedPointCoord = styled.div`
  .gm-style-iw-c {
    padding: 20px !important;
    flex-direction: row-reverse;
  }

  .gm-style-iw-chr {
    position: absolute;
    top: 0;
    right: 0;
  }

  .gm-style-iw-d {
    padding-right: 20px;
  }
`;

export const ContainerMapAllVehiclesCoords = styled.div`

  @media (min-width: 800px) { // Desktop

    .map {
      height: 70vh;
      overflow: hidden;
    }
    .vehicles {
      height: 62vh;
      overflow-y: scroll;
      overflow-x: hidden;
      display: block;
    }
    .filter {
      .input {
        width: calc(100% - 14px) !important;
        margin-left: 14px;
      }
    }
  }

  .filter {

    .gm-style-iw-d { // Google info window
      padding: 20px !important;
    }
    .input {
      width: 100%;
      margin-bottom: 10px;
    }
    .vehicles {

      .MuiAccordion-root {
        width: 100%;
        box-shadow: none;
        margin-top: 0;

        .MuiAccordionSummary-root {
          min-height: auto;

          .MuiAccordionSummary-content {
            margin: 5px 0 0 0 !important;
          }
        }
        .MuiAccordionDetails-root {
          display: block;
        }
      }
    }
    .vehicle-summary__accordion {
      width: 100%;
      margin-bottom: 0;
    }
  }
`;

export const MarkerInfo = styled.div`
  display: flex;

  &:first-child {
    margin-bottom: 15px;
    border-bottom: 1px solid gainsboro;
    padding-bottom: 5px;
  }
  & .col-left {
    width: 30px;
    display: flex;
    align-items: center;
    vertical-align: middle;
  }
  & .col-right {
    width: 100%;
    .content {
      .title {}
      .text {}
    }
  }
`;
