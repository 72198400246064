/* eslint-disable camelcase, max-classes-per-file */

import {
  Column,
  CreateDateColumn,
  Entity,
  JoinColumn,
  ManyToOne,
  OneToMany,
  OneToOne,
  PrimaryGeneratedColumn,
  UpdateDateColumn
} from "typeorm";
import { LineString } from "geojson";

import { Event } from "./reg_events.entity";
import { Travel } from "./reg_travels.entity";
import { Vehicle } from "./reg_vehicles.entity";
import { VehicleState } from "./reg_vehicles_states.entity";
import { Plant } from "./reg_plants.entity";
import { ZipCode } from "./reg_zip_codes.entity";
import { TpeLocation } from "./tpe_locations_types.entity";
import { Regional } from "./reg_regionals.entity";

@Entity("reg_locations")
export class Location {

  @PrimaryGeneratedColumn("uuid")
    id_location?: string;

  @Column({ type: "varchar", nullable: true, unique: true })
    external_id?: string;

  @Column({ type: "varchar", nullable: true, length: 50 })
    cep?: string;

  @Column({ type: "varchar", nullable: false })
    latitude?: string;

  @Column({ type: "varchar", nullable: false })
    longitude?: string;

  @Column({ type: "varchar", nullable: false })
    address?: string;

  @Column({ type: "varchar", nullable: true })
    district?: string;

  @Column({ type: "numeric",
    nullable: true,
    transformer: {
      to(value) {
        return value;
      },
      from(value) {
        return parseFloat(value);
      }
    }
  })
    number?: number;

  @Column({ type: "numeric",
    nullable: true,
    transformer: {
      to(value) {
        return value;
      },
      from(value) {
        return parseFloat(value);
      }
    }
  })
    radius?: number;

  @Column({ type: "varchar", nullable: true })
    complement?: string;

  @Column({ type: "varchar", nullable: true, length: 150 })
    name?: string;

  @Column({ type: "varchar", nullable: true })
    description?: string;

  @Column({ type: "varchar", nullable: false })
    county?: string;

  @Column({ type: "varchar", nullable: false })
    uf?: string;

  @Column({ type: "varchar", nullable: false })
    country?: string;

  @Column({ type: "boolean", nullable: false, default: false })
    finish_point?: boolean;

  @Column({ type: "varchar", nullable: true })
    source_latitude?: string;

  @Column({ type: "varchar", nullable: true })
    source_longitude?: string;

  @Column({ type: "boolean", nullable: false, default: false })
    unloaded?: boolean;

  @Column({ type: "boolean", nullable: false, default: true })
    active?:boolean;

  @Column("geometry", { spatialFeatureType: "LineString", srid: 4326, nullable: true })
    polygon_area?: LineString;

  @CreateDateColumn({ default: "(now() at time zone 'utc')" })
    registration_date?: Date;

  @UpdateDateColumn({ default: "(now() at time zone 'utc')" })
    updated_date?: Date;

  @ManyToOne(() => ZipCode, (zip_code) => zip_code.locations, { nullable: true })
  @JoinColumn({ name: "id_zip_code_fk" })
    zip_code?: TpeLocation;

  @ManyToOne(() => TpeLocation, (type) => type.locations, { nullable: false, onUpdate: "CASCADE" })
  @JoinColumn({ name: "id_location_type_fk" })
    type?: TpeLocation;

  @ManyToOne(() => Regional, (regional) => regional.id_regional, { nullable: true })
  @JoinColumn({ name: "id_regional_fk" })
    regional?: Regional;

  @OneToMany(() => Travel, (travels_destination) => travels_destination.destination)
    travels_destination?: Travel[];

  @OneToMany(() => Event, (events) => events.location)
    events?: Event[];

  @OneToMany(() => Travel, (travels_origin) => travels_origin.origin)
    travels_origin?: Travel[];

  @OneToMany(() => Travel, (travels_end) => travels_end.end)
    travels_end?: Travel[];

  @OneToMany(() => Vehicle, (vehicles) => vehicles.location)
    vehicles?: Vehicle[];

  @OneToMany(() => Vehicle, (vehicles) => vehicles.current_location)
    vehicles_at?: Vehicle[];

  @OneToMany(() => VehicleState, (vehicles_states) => vehicles_states.location)
    vehicles_states?: VehicleState[];

  @OneToOne(() => Plant, (plant) => plant.location)
    plant?: Plant;

  GetLabelName?(): string {
    return this.name ?? "";
  }

}

export class LocationErrors {

  static readonly LOCATION_TYPE_NOT_FOUND = "3.5.1 (TCON333531)";

  static readonly UNIQUE_ERROR = "3.5.2 (TCON333532)";

  static readonly LOCATION_IN_USE = "3.5.3 (TCON333533)";

}
