export enum GoogleApiRequestTypes {
  ADDRESS_FROM_ZIPCODE = "Get address from zipcode",
  CREATE_EVENT_ADDRESS_FROM_COORDS = "Create event: get address from coords",
  CREATE_UNLOADING_LOCATION = "Create unloading location: get address from coords",
  FIXED_POINT_MAP = "Fixed point map: get address from coords",
  SNAP_COORDS_TO_ROADS = "Snap coords to roads (travel path)",
  ALL_VEHICLES_MAP = "Generate all vehicles map",
  ARRIVAL_FORECAST = "Generate arrival forecast",
  INTEGRATION_CREATE_LOCATION_ZIPCODE = "Integration (create location): get zipcode",
  INTEGRATION_CREATE_LOCATION_COORDS = "Integration (create location): get coords from address",
  INTEGRATION_CREATE_LOCATION_ADDRESS = "Integration (create location): get address from coords",
  INTEGRATION_UPDATE_LOCATION_ZIPCODE = "Integration (update location): get zipcode",
  INTEGRATION_UPDATE_LOCATION_COORDS = "Integration (update location): get coords from address",
  INTEGRATION_UPDATE_LOCATION_ADDRESS = "Integration (update location): get address from coords"
}
