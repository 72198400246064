/* eslint-disable max-len */
import { LocationsModuleMessages, TranslationLocationsModuleMessages } from "../interfaces";

export const messages: TranslationLocationsModuleMessages = {

  /* region Frontend messages */
  [LocationsModuleMessages.name]: "Nombre",
  [LocationsModuleMessages.type]: "Tipo",
  [LocationsModuleMessages.address]: "Dirección",
  [LocationsModuleMessages.number]: "Número",
  [LocationsModuleMessages.district]: "Barrio",
  [LocationsModuleMessages.county]: "Ciudad",
  [LocationsModuleMessages.state]: "Estado",
  [LocationsModuleMessages.zipCode]: "Código Postal",
  [LocationsModuleMessages.regional]: "Regional",
  [LocationsModuleMessages.noNumber]: "Sin número",
  [LocationsModuleMessages.noNameRegistered]: "Sin nombre registrado",
  [LocationsModuleMessages.dataTableTitle]: "ubicaciones",
  [LocationsModuleMessages.deleteConfirmationTitle]: "Confirmación de eliminación",
  [LocationsModuleMessages.deleteConfirmationText]: "¿Realmente desea eliminar la ubicación",
  [LocationsModuleMessages.searching]: "Buscando ubicaciones...",
  [LocationsModuleMessages.formZipCodeIncomplete]: "Código postal incompleto",
  [LocationsModuleMessages.formExternalIdAlreadyRegistered]: "ID externo ya registrado",
  [LocationsModuleMessages.formTypeRequired]: "El tipo es obligatorio",
  [LocationsModuleMessages.formAddressRequired]: "La dirección es obligatoria",
  [LocationsModuleMessages.formDistrictRequired]: "El barrio es obligatorio",
  [LocationsModuleMessages.formCountyRequired]: "La ciudad es obligatoria",
  [LocationsModuleMessages.formNameRequired]: "El nombre es obligatorio",
  [LocationsModuleMessages.formStateRequired]: "El estado es obligatorio",
  [LocationsModuleMessages.toastCoordsNotDefinedTitle]: "Coordenadas no definidas",
  [LocationsModuleMessages.toastCoordsNotDefinedDescription]: "Defina las coordenadas estableciéndolas en el mapa o"
  + " buscando por código postal",
  [LocationsModuleMessages.toastNoZipCodeDataFoundDescription]: "No se encontraron datos para el código postal",
  [LocationsModuleMessages.toastSelectLocationInMapDescription]: "Seleccione una ubicación en el mapa",
  [LocationsModuleMessages.toastDataLoadedDescription]: "Datos cargados con éxito",
  [LocationsModuleMessages.toastLocationModifiedWithoutAddress]: "Ubicación modificada sin cambios de dirección",
  [LocationsModuleMessages.updateConfirmationTitle]: "Confirmación para actualizar datos de dirección",
  [LocationsModuleMessages.updateConfirmationText]: "¿Desea actualizar los siguientes datos de ubicación?",
  [LocationsModuleMessages.detailsLocationRegisterTitle]: "Registro de ubicación",
  [LocationsModuleMessages.noNameRegistered]: "Sin nombre registrado",
  [LocationsModuleMessages.detailsMapPosition]: "Posición en el mapa",
  [LocationsModuleMessages.detailsEditLabel]: "Editar",
  [LocationsModuleMessages.externalId]: "ID externo",
  [LocationsModuleMessages.detailsExternalIdHelper]: "Ref. a integraciones",
  [LocationsModuleMessages.detailsType]: "Tipo de ubicación",
  [LocationsModuleMessages.detailsRadius]: "Valla (Radio)",
  [LocationsModuleMessages.detailsRadiusHelper]: "En metros (m)",
  [LocationsModuleMessages.searching]: "Buscando...",
  [LocationsModuleMessages.detailsSearchZipCode]: "Buscar código postal",
  [LocationsModuleMessages.complement]: "Complemento",
  [LocationsModuleMessages.detailsAllowTravelClosure]: "Permite cierre de viaje",
  [LocationsModuleMessages.mapButtonConfirmLocation]: "Confirmar ubicación",
  [LocationsModuleMessages.active]: "Activo",
  [LocationsModuleMessages.inactive]: "Inactivo",
  [LocationsModuleMessages.activeLocation]: "Ubicación activa",
  /* endregion Frontend messages */
  /* region Backend messages */
  [LocationsModuleMessages.getLocationDataByGoogleSuccess]: "Datos devueltos con éxito",
  [LocationsModuleMessages.getLocationDataByGoogleError]: "Error al buscar datos del local",
  [LocationsModuleMessages.readLocationTypeSuccess]: "Tipos de ubicación devueltos con éxito",
  [LocationsModuleMessages.noLocationTypeFound]: "Ningún tipo de ubicación registrado",
  [LocationsModuleMessages.readLocationTypeError]: "Falla al buscar tipos de ubicación",
  [LocationsModuleMessages.createLocationSuccess]: "Ubicación creada con éxito",
  [LocationsModuleMessages.readLocationSuccess]: "Ubicación(es) devuelta(s) con éxito",
  [LocationsModuleMessages.updateLocationSuccess]: "Ubicación actualizada con éxito",
  [LocationsModuleMessages.deleteLocationSuccess]: "Ubicación eliminada con éxito",
  [LocationsModuleMessages.getPlantsLocationsSuccess]: "Centrales devueltas con éxito",
  [LocationsModuleMessages.createLocationError]: "Falla al crear ubicación",
  [LocationsModuleMessages.readLocationError]: "Falla al buscar ubicación(es)",
  [LocationsModuleMessages.noLocationFound]: "Ninguna ubicación registrada",
  [LocationsModuleMessages.locationNotFound]: "Ubicación no registrada en nuestro sistema",
  [LocationsModuleMessages.updateLocationError]: "Falla al actualizar ubicación",
  [LocationsModuleMessages.deleteLocationError]: "Falla al eliminar ubicación",
  [LocationsModuleMessages.noPlantFound]: "Ninguna central registrada",
  [LocationsModuleMessages.getPlantsLocationsError]: "Falla al buscar centrales",
  [LocationsModuleMessages.externalID]: "ID externo",
  [LocationsModuleMessages.createPlantSuccess]: "Central creada con éxito",
  [LocationsModuleMessages.createPlantError]: "Falla al crear central",
  [LocationsModuleMessages.updatePlantSuccess]: "Central actualizada con éxito",
  [LocationsModuleMessages.updatePlantError]: "Falla al actualizar central",
  [LocationsModuleMessages.plantNotFound]: "Central no registrada en nuestro sistema",
  [LocationsModuleMessages.deletePlantSuccess]: "Central eliminada con éxito",
  [LocationsModuleMessages.deletePlantError]: "Falla al eliminar central",

  /* region Integration messages */
  [LocationsModuleMessages.addressNotFound]: "Dirección(es) no encontrada(s)",
  [LocationsModuleMessages.externalIDAlreadyRegistered]: "ID(s) externo(s) ya registrado(s)",
  [LocationsModuleMessages.needToInformLocationData]: "Debe informar una latitud y longitud válidas o información de la dirección (county, uf, district, ...)",
  [LocationsModuleMessages.noAddressChanges]: "La ubicación no ha tenido cambios de dirección",
  [LocationsModuleMessages.locationLinkedToAVehicleState]: "Ubicación vinculada a un estado de vehículo",
  /* endregion Integration messages */

  /* endregion Backend messages */
  /* region Location Types */
  [LocationsModuleMessages.plant]: "Central",
  [LocationsModuleMessages.construction]: "Obra",
  [LocationsModuleMessages.autoElectricalWorkshop]: "Auto Eléctrica",
  [LocationsModuleMessages.tireRepairShop]: "Borracharia",
  [LocationsModuleMessages.gasStation]: "Estación de servicio",
  [LocationsModuleMessages.workshop]: "Taller mecánico",
  [LocationsModuleMessages.parkingLot]: "Estacionamiento",
  [LocationsModuleMessages.others]: "Otros",
  [LocationsModuleMessages.supplier]: "Proveedor",
  [LocationsModuleMessages.client]: "Cliente"
  /* endregion */
};
