/* eslint-disable import/prefer-default-export */
import { AlertMessages, TranslationAlertMessages } from "../interfaces";

export const messages: TranslationAlertMessages = {

  /* region Frontend messages */
  [AlertMessages.noPermissionToJustify]: "Você não tem permissão para confirmar alertas do tipo",
  [AlertMessages.siteRegistration]: "Cadastro do local",
  [AlertMessages.searchLocation]: "Busque a localização primeiro",
  [AlertMessages.alertConfirmation]: "Confirmação de alerta",
  [AlertMessages.vehicleAtTheAlertMoment]: "Veículo no momento da alerta",
  [AlertMessages.expirationDate]: "Data de vencimento",
  [AlertMessages.occurrenceDate]: "Data de ocorrência",
  [AlertMessages.expiresIn]: "Vencimento em",
  [AlertMessages.justification]: "Justificativa",
  [AlertMessages.details]: "Detalhes",
  [AlertMessages.expiredIn]: "Vencido em",
  [AlertMessages.unauthorizedStop]: "Parada indevida",
  [AlertMessages.block]: "Bloqueio",
  [AlertMessages.concreteAboutToExpire]: "Concreto próximo ao vencimento",
  [AlertMessages.expiredConcrete]: "Concreto vencido",
  [AlertMessages.mainBatteryConnection]: "Conexão de bateria principal",
  [AlertMessages.mainBatteryDisconnection]: "Desconexão de bateria principal",
  [AlertMessages.unauthorizedUnloading]: "Descarregamento indevido",
  [AlertMessages.speeding]: "Excesso de velocidade",
  [AlertMessages.speedingTravel]: "Excesso de velocidade na viagem",
  [AlertMessages.hardwareInactivity]: "Inatividade de hardware",
  [AlertMessages.maintenance]: "Manutenção de veículo",
  [AlertMessages.jammingDetected]: "Evento de Jammer",
  [AlertMessages.observation]: "Observação",
  [AlertMessages.licensePlate]: "Placa",
  [AlertMessages.justificationDeleteConfirmation]: "Deseja excluir a justificativa:",
  [AlertMessages.intervalOfDays]: "Sua licença de uso expirará em {{days}} dia(s)!<br/>"
    + "Gentileza entrar em contato com nosso financeiro até {{limitDate}} para regularizar situação.<br/>"
    + "Contatos: WhatsApp / Telefone (11) 3564-3314 ou E-mail: financeiro@topconsuite.com.",
  [AlertMessages.blockedPlataforom]: "Plataforma bloqueada aos usuários.",
  [AlertMessages.zmrc]: "Restrição de circulação",
  [AlertMessages.enteringTrustedLocation]: "Entrada em local confiável",

  /* region Alerts History messages */
  [AlertMessages.alert]: "Alerta",
  [AlertMessages.confirmationDate]: "Data de confirmação",
  [AlertMessages.authorizer]: "Autorizador",
  [AlertMessages.alertsHistoryTitle]: "Histórico de alertas",
  [AlertMessages.startDate]: "Data inicial",
  [AlertMessages.finishDate]: "Data final",
  [AlertMessages.type]: "Tipo",
  /* endregion */

  /* region Hardware Inactivity Diagnostic messages */
  [AlertMessages.noTxCommunication]: "Sem comunicação com sensor TX por mais de 48 horas",
  [AlertMessages.noRxCommunication]: "Sem comunicação com sensor RX por mais de 24 horas",
  [AlertMessages.lowBattery]: "Bateria baixa do sensor",
  [AlertMessages.possibleViolation]: "Possível violação do sensor",
  [AlertMessages.unknown]: "Diagnóstico de status de sensor desconhecido",
  /* endregion */

  /* endregion Frontend messages */
  /* region Backend messages */
  [AlertMessages.readAlertTypesSuccess]: "Tipos de alerta(s) retornado(s) com sucesso",
  [AlertMessages.readAlertSuccess]: "Alerta(s) retornado(s) com sucesso",
  [AlertMessages.confirmAlertSuccess]: "Alerta confirmado com sucesso",
  [AlertMessages.createAlertSuccess]: "Alerta criado com sucesso Tipo: ",
  [AlertMessages.noAlertType]: "Nenhum tipo de alerta encontrado",
  [AlertMessages.readAlertTypesError]: "Falha ao buscar tipo(s) de alerta(s)",
  [AlertMessages.noAlertFound]: "Nenhum alerta encontrado",
  [AlertMessages.readAlertError]: "Falha ao buscar alertas",
  [AlertMessages.alertNotRegistered]: "Alerta não cadastrado em nosso sistema",
  [AlertMessages.noVehicleLinkedToHardware]: "Nenhum veículo vinculado ao hardware",
  [AlertMessages.alertNotMapped]: "Alerta não homologado",
  [AlertMessages.checkVehicleTrackerAlertError]: "Falha ao verificar comando de alerta de veículo",
  [AlertMessages.createAlertJustificationSuccess]: "Justificativa salva com sucesso",
  [AlertMessages.createAlertJustificationError]: "Falha ao criar justificativa",
  [AlertMessages.readAlertJustificationSuccess]: "Justificativa(s) retornada(s) com sucesso",
  [AlertMessages.readAlertJustificationError]: "Falha ao buscar justificativa(s)",
  [AlertMessages.deleteAlertJustificationSuccess]: "Justificativa deletada com sucesso",
  [AlertMessages.deleteAlertJustificationError]: "Falha ao deletar justificativa",
  [AlertMessages.alertjustificationNotRegistered]: "Justificativa não cadastrada em nosso sistema",
  [AlertMessages.noAlertJustificationFound]: "Nenhuma justificativa encontrada",

  /* region Integrations messages */
  [AlertMessages.invalidDatePeriod]: "Período de data inválido"
  /* endregion */

  /* endregion Backend messages */
};
