/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-new */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useGoogleMaps } from "react-hook-google-maps";

// region Libraries
import $ from "jquery";
import _ from "lodash";
// endregion Libraries
// region Constants
import { AlertTypes } from "@shared/constants/alert-types.enum";
import { EventTypes } from "@shared/constants/event-types.enum";
import { Icons } from "@shared/constants/icons.enum";
// endregion Constants
// region Interfaces
import { CommandPointer, MarkerOptions, PolylineOptions } from "@shared/interfaces/map.interface";
import { TravelAnalytics } from "@shared/interfaces/travel-analytics.interface";
// endregion Interfaces
// region Languages
import useTranslation from "src/translations/useTranslation";
import { GlobalMessages, MapMessages } from "@shared/languages/interfaces";
// endregion Languages
/* region Services */
import api from "@services/api";
/* endregion Services */
/* region Hooks */
import { useAuth } from "@hooks/useAuth";
/* endregion Hooks */
// region Utils
import mapDefaultParameters from "@utils/parameters";
import { GoogleApiRequestTypes } from "@shared/constants/google-api-request-types.enum";
import utils from "../../utils/useful-functions";
// endregion Utils
// region Components
import { MapAllVehiclesCoords, MarkersVisualizationTypes } from "./AllVehiclesCoords";
// endregion Components
// region Styles
import { ContainerMapFixedPointCoord, MarkerInfo } from "./styles";
// endregion Styles

interface Coord { lat: number, lng: number }

type MapFixedPointCoordProps = {
  mapHeight: number,
  latitude: number,
  longitude: number,
  tooltip?: { title: string, content?: string },
  alertMarker?: boolean,
  customMarker?: { url: string },
  customControl?: { element: any, position: google.maps.ControlPosition, action: () => void }
  returnAddressData?: (addressData: google.maps.GeocoderResult) => void,
  dontGetAddress?: boolean
}
const MapFixedPointCoord = React.memo<MapFixedPointCoordProps>((
  { mapHeight, latitude, longitude, tooltip, alertMarker, customMarker, customControl, returnAddressData, dontGetAddress }
) => {

  const { t } = useTranslation();
  const { user } = useAuth();

  const GoogleApiKey = localStorage.getItem("@Fleet:google");
  const { ref, map } = useGoogleMaps(
    `${GoogleApiKey}&libraries=places,drawing&language=pt-BR`,
    {
      center: mapDefaultParameters.center,
      zoom: 15
    }
  );

  /* eslint-disable */
  const [stateMarker, setStateMarker] = useState<google.maps.Marker | null>(null);
  const [stateInfoWindow, setStateInfoWindow] = useState<google.maps.InfoWindow | null>(null);
  /* eslint-enable */

  const [control, setControl] = useState<HTMLDivElement>();

  /** Find address according coords and set info in state
   * @param latLng Coords to search address
   */
  const getAddress = useCallback(async (latLng) => {

    if (dontGetAddress) return null;

    const geocoder = new google.maps.Geocoder();
    const request = { location: latLng };

    return new Promise((resolve) => {

      geocoder.geocode(request, (results, status) => {

        if (status === google.maps.GeocoderStatus.OK && results[0]) {
          if (returnAddressData) returnAddressData(results[0]);
          resolve(results[0].formatted_address);
        }

        resolve(t(MapMessages.errorFetchingAddressData));
      });
    }).finally(async () => {
      try {
        await api.post("/logs/save", {
          context: "Frontend",
          description: GoogleApiRequestTypes.FIXED_POINT_MAP,
          user: {
            name: user.name,
            email: user.email
          }
        });
      } catch (error) {
        console.log(error);
      }
    });

  }, [returnAddressData, dontGetAddress, t, user.name, user.email]);

  /** Draw map with point in coords passed in props
   */
  const drawPoint = useCallback(async () => {

    const location: google.maps.LatLng = new google.maps.LatLng({ lat: latitude, lng: longitude });
    const infoWindow: google.maps.InfoWindow = new google.maps.InfoWindow();

    let infoWindowContent;

    // If not pass a address data to show in info window,
    // we search the data with coords to show
    if (!tooltip) infoWindowContent = await getAddress(location);
    else infoWindowContent = `<b>${tooltip.title}</b><br />${tooltip.content || await getAddress(location)}`;

    // Set marker on map
    const marker = new google.maps.Marker({ position: location, map, animation: google.maps.Animation.BOUNCE });

    // Center marker on map
    map.setCenter(location);

    // Verify what marker have to put (Custom, Alert or Default)
    if (customMarker) {
      marker.setIcon({
        url: customMarker.url,
        scaledSize: new google.maps.Size(40, 40)
      });
    } else if (alertMarker) {
      marker.setIcon({
        // eslint-disable-next-line max-len
        url: "data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56.652 80.932'%3e%3cg id='Grupo_1305' data-name='Grupo 1305' transform='translate(-670.037 -2412)'%3e%3cpath id='Caminho_914' fill='red' data-name='Caminho 914' d='M478.422,480.607A28.306,28.306,0,0,0,450.1,508.934c0,21.244,28.327,52.605,28.327,52.605s28.325-31.361,28.325-52.605A28.306,28.306,0,0,0,478.422,480.607Z' transform='translate(219.942 1931.393)'/%3e%3cpath id='Caminho_856' data-name='Caminho 856' d='M473.006,510.874h3.759v3.759h-3.759Zm0-15.037h3.759v11.278h-3.759Zm1.864-9.4a18.8,18.8,0,1,0,.033,0Zm.019,33.836a15.037,15.037,0,1,1,15.034-15.04v0a15.034,15.034,0,0,1-15.031,15.037h0Z' transform='translate(223.479 1934.834)' fill='%23fff'/%3e%3c/g%3e%3c/svg%3e",
        scaledSize: new google.maps.Size(40, 40)
      });
    }

    // Open info window in marker
    infoWindow.setContent(infoWindowContent);
    if (infoWindowContent) infoWindow.open(map, marker);

    setStateMarker(marker);
    setStateInfoWindow(infoWindow);

    // REVIEW Make component element generic according parameter 'customControl.element'
    // If have a custom control inside map, configure according prop and add your action
    if (customControl && !control) {

      const controlDiv = document.createElement("div");
      const controlUI = document.createElement("div");
      const controlIcon = document.createElement("div");

      controlUI.style.background = "none rgb(255, 255, 255)";
      controlUI.style.border = "2px solid #fff";
      controlUI.style.borderRadius = "2px";
      controlUI.style.boxShadow = "rgb(0 0 0 / 30%) 0px 1px 4px -1px";
      controlUI.style.cursor = "pointer";
      controlUI.style.margin = "10px";
      controlUI.title = t(MapMessages.updateVehicleCurrentPosition);
      controlDiv.appendChild(controlUI);

      // Set CSS for the control interior.
      // eslint-disable-next-line max-len
      controlIcon.innerHTML = "<svg width='24' height='24' xmlns='http://www.w3.org/2000/svg' fill-rule='evenodd' clip-rule='evenodd'><path d='M7 9h-7v-7h1v5.2c1.853-4.237 6.083-7.2 11-7.2 6.623 0 12 5.377 12 12s-5.377 12-12 12c-6.286 0-11.45-4.844-11.959-11h1.004c.506 5.603 5.221 10 10.955 10 6.071 0 11-4.929 11-11s-4.929-11-11-11c-4.66 0-8.647 2.904-10.249 7h5.249v1z'/></svg>";
      controlIcon.style.display = "flex";
      controlIcon.style.padding = "5px";
      controlUI.appendChild(controlIcon);

      // Add action on control and put inside map
      controlDiv.addEventListener("click", () => customControl.action());
      map.controls[customControl.position].push(controlDiv);

      setControl(controlDiv as HTMLDivElement);
    }

  }, [getAddress, latitude, longitude, map, tooltip, alertMarker, customMarker,
    customControl, control, setStateInfoWindow, setStateMarker, t
  ]);

  useEffect(() => {

    // When map is render, draw according point
    if (map) drawPoint().then();

    // Clear map of previous markers and info windows
    return () => {

      setStateInfoWindow(null);
      setStateMarker((state) => {
        if (state) state.setMap(null);

        return null;
      });
    };

  }, // drawPoint function duplicates request to Google API if it's present in dependency array
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [map, latitude, longitude, setStateMarker, setStateInfoWindow]);

  return (
    <ContainerMapFixedPointCoord ref={ref} style={{ width: "100%", height: mapHeight }} />
  );
});

type MapAnalyticsProps = {
  mapHeight: number | string,
  polygons?: Omit<google.maps.PolygonOptions, "map">[],
  markers?: MarkerOptions[],
  polylines?: PolylineOptions[],
  circles?: Omit<google.maps.CircleOptions, "map">[],
  polygonPlaces?: Omit<google.maps.PolygonOptions, "map">[],
  commandsPointer?: CommandPointer,
  filter?: {class: string, status: boolean}[],
  snapToRoads?: boolean,
}
const MapAnalytics: React.FC<MapAnalyticsProps> = (
  { mapHeight, polygons, markers, polylines, circles, polygonPlaces, commandsPointer, filter, snapToRoads }
) => {

  interface StatePolyline extends google.maps.Polyline {
    class?: string;
  }
  const markersCount = markers ? markers.length : 0;
  const GoogleApiKey = localStorage.getItem("@Fleet:google");
  const [stateMarkers, setStateMarkers] = useState<google.maps.Marker[]>([]);
  const [statePolylines, setStatePolylines] = useState<StatePolyline[]>([]);
  const [stateInfoWindows, setStateInfoWindows] = useState<google.maps.InfoWindow[]>([]);
  const [stateLastOpenedWindow, setStateLastOpenedWindow] = useState<number | null>(null);

  /* eslint-disable */
  const [statePolygons, setStatePolygons] = useState<google.maps.Polygon[]>([]);
  const [stateCircles, setStateCircles] = useState<google.maps.Circle[]>([]);
  const [statePolygonPlaces, setStatePolygonPlaces] = useState<google.maps.Polygon[]>([]);
  /* eslint-enable */

  const { user } = useAuth();

  const { ref, map } = useGoogleMaps(
    `${GoogleApiKey}&libraries=places,drawing&language=pt-BR`,
    {
      center: mapDefaultParameters.center,
      mapTypeId: "roadmap",
      zoom: 18
    }
  );

  // Reduce elements on map filters to one Object {...obj, [class]: status } and add last_position permanent visible
  const mapFilter = useMemo(() => {

    if (filter) {
      return filter.reduce((acc, cv) => ({ ...acc, [cv.class]: cv.status }), { "last_position": true });
    }

    return {};
  }, [filter]);

  /** Pin a marker in map
   * @param marker Marker to be pinned in the map
   */
  const placeMarker = useCallback((marker: MarkerOptions, id: number) => {

    if (!_.isEmpty(marker)) {

      const instancedMarker = new google.maps.Marker({ ...marker, map, optimized: markersCount > 256 ? false : undefined });
      const infoWindow = new google.maps.InfoWindow(); // Instance the info window component for each marker

      // Set InfoWindow content
      infoWindow.setContent(`<div id="marker-info-${id}">${marker.infoContent}</div>`);

      // Create event click in marker with his info
      google.maps.event.addListener(instancedMarker, "click", ((instancedMarker, infoWindow) => () => {
        infoWindow.open(map, instancedMarker);
      })(instancedMarker, infoWindow));

      // Save in states to manipulate later
      setStateInfoWindows((res) => [...res, infoWindow]);
      setStateMarkers((res) => [...res, instancedMarker]);
    }

  }, [map, setStateMarkers, setStateInfoWindows]);

  /** Draw a polyline in map
   * @param polyline Polyline to be drawn in the map
   */
  const placePolyline = useCallback((polyline: google.maps.PolylineOptions) => {

    if (!_.isEmpty(polyline)) {

      const instancedPolyline = new google.maps.Polyline(polyline);

      instancedPolyline.setMap(map);
      setStatePolylines((res) => [...res, instancedPolyline]);
    }

  }, [map]);

  /** Place a Polygon in map
   * @param polygon Polygon to be placed in the map
   */
  const placePolygon = useCallback((polygon: google.maps.PolygonOptions) => {

    if (!_.isEmpty(polygon)) {

      const instancedPolygon = new google.maps.Polygon({
        ...polygon,
        map
      });

      setStatePolygons((state) => [...state, instancedPolygon]);
    }

  }, [map]);

  /** Place a Circle in map
   * @param circle Circle to be placed in the map
   */
  const placeCircle = useCallback((circle: google.maps.CircleOptions) => {

    if (!_.isEmpty(circle)) {

      // TODO Save circle in a state
      const instancedCircle = new google.maps.Circle({
        ...circle,
        map
      });

      setStateCircles((state) => [...state, instancedCircle]);
    }

  }, [map]);

  /** Place a place in polygon format in map
   * @param polygon Polygon to be placed in the map
   */
  const placePolygonPlace = useCallback((polygon: google.maps.PolygonOptions) => {

    if (!_.isEmpty(polygon)) {

      const instancedPolygonPlace = new google.maps.Polygon({
        ...polygon,
        map
      });

      setStatePolygonPlaces((state) => [...state, instancedPolygonPlace]);
    }

  }, [map]);

  // Remove markers from map
  const clearMarkers = useCallback(() => {
    setStateInfoWindows([]);
    setStateMarkers((state) => state.map((mark) => {
      mark.setMap(null);

      return mark;
    }).filter((f) => false));
  }, [setStateMarkers]);

  // Remove polylines from map
  const clearPolylines = useCallback(() => {
    setStatePolylines((state) => state.map((polyline) => {
      polyline.setMap(null);

      return polyline;
    }).filter((f) => false));
  }, [setStatePolylines]);

  // Remove markers from map
  const clearCircles = useCallback(() => {
    setStateCircles((state) => state.map((circle) => {
      circle.setMap(null);

      return circle;
    }).filter((f) => false));
  }, [setStateCircles]);

  // Remove markers from map
  const clearPolygons = useCallback(() => {
    setStatePolygons((state) => state.map((polygon) => {
      polygon.setMap(null);

      return polygon;
    }).filter((f) => false));
  }, [setStatePolygons]);

  // Remove markers from map
  const clearPolygonPlaces = useCallback(() => {
    setStatePolygonPlaces((state) => state.map((polygon) => {
      polygon.setMap(null);

      return polygon;
    }).filter((f) => false));
  }, [setStatePolygonPlaces]);

  // Set path going routes and coming routes (if have)
  const getSnappedCoord = useCallback(async (polyline: PolylineOptions) => {

    // Snap to roads
    const path = (polyline.path ?? []) as google.maps.LatLngLiteral[];
    const pathMain: any[] = [];
    let pathValues: any[] = [];

    // Mount the array of polyline points
    path.forEach((point, i) => {
      pathValues.push(`${point.lat.toFixed(6)},${point.lng.toFixed(6)}`);

      // We separated the data into groups of 100 in 100
      // (Google API roads can request a maximum of 100 polylines at a time)
      if (pathValues.length === 100 || i === path.length - 1) {
        pathMain.push(pathValues);
        pathValues = [];
      }
    });

    const snapCoordToRoads = async (curPath: any[]) => new Promise((resolve, reject) => {
      $.ajax({
        url: "https://roads.googleapis.com/v1/snapToRoads",
        type: "GET",
        data: {
          interpolate: true,
          key: GoogleApiKey,
          path: curPath.join("|")
        },
        success(data) {
          const snappedCoordinates: Coord[] = [];

          if (!$.isEmptyObject(data)) {
            for (const snappedPoint of data.snappedPoints) {
              const latlng = {
                lat: snappedPoint.location.latitude,
                lng: snappedPoint.location.longitude
              };

              snappedCoordinates.push(latlng);
            }
          }
          resolve(snappedCoordinates);
        },
        error(error) {
          reject(error);
        }
      });
    }).finally(async () => {
      try {
        await api.post("/logs/save", {
          context: "Frontend",
          description: GoogleApiRequestTypes.SNAP_COORDS_TO_ROADS,
          user: {
            name: user.name,
            email: user.email
          }
        });
      } catch (error) {
        console.log(error);
      }
    });

    // Get coords according polylines (With road points)
    const promises = pathMain.map((curPath) => snapCoordToRoads(curPath));
    const groupedPath = (await Promise.all(promises)) as Coord[][];

    return {
      ...polyline,
      path: groupedPath.reduce((acc, cv) => [...acc, ...cv], [])
    };
  }, [GoogleApiKey, user.name, user.email]);

  // Init map, places Markers, Polygons and Circles
  useEffect(() => {

    // Init map
    if (map) {

      // Object to fit map according markers position
      const bounds = new google.maps.LatLngBounds();

      // Place Markers on map
      if (markers) {

        markers.forEach((marker, idx) => {
          placeMarker(marker, idx);
          bounds.extend(marker.position as google.maps.LatLng);
        });
      }

      // Draw polylines on map
      if (polylines) {
        if (snapToRoads) {
          // Snap polyline to roads
          Object.keys(polylines).forEach((key) => {
            getSnappedCoord(polylines[key]).then((polyline) => {
              placePolyline(polyline);
            });
          });
        } else {
          Object.keys(polylines).forEach((key) => {
            placePolyline(polylines[key]);
          });
        }
      }

      // Place Polygons on map
      if (polygons) {
        polygons.forEach((arrayPolygon) => {
          for (let i = 0; i < (arrayPolygon as any).length; i++) {
            const polygon = arrayPolygon[i];

            placePolygon(polygon);
          }
        });
      }

      // Place Circles on map
      if (circles) {
        circles.forEach((circle) => {
          placeCircle(circle);
          bounds.extend(circle.center as google.maps.LatLng);
        });
      }

      // Place Polygon Places on map
      if (polygonPlaces) {
        polygonPlaces.forEach((polygon) => {
          placePolygonPlace(polygon);
        });
      }

      // Always fit map based on Markers and Circles
      map.fitBounds(bounds);
    }

    return () => {
      clearMarkers();
      clearPolylines();
      clearCircles();
      clearPolygons();
      clearPolygonPlaces();
    };

  }, [map, polygons, markers, polylines, circles, polygonPlaces, snapToRoads, placeCircle, placeMarker, placePolyline,
    placePolygon, placePolygonPlace, clearMarkers, clearPolylines, clearCircles, clearPolygons, clearPolygonPlaces, getSnappedCoord]);

  // Control click and hover markers
  useEffect(() => {

    // Variable to backup icon
    let icon: google.maps.Icon;

    if (map) {

      // Function to handle when item is clicked in list
      const handleSelected = () => {

        if (commandsPointer?.selected !== undefined && commandsPointer?.selected !== null) {

          // Don't open info windows if already opened (prevent multiples windows in render)
          if (stateLastOpenedWindow !== commandsPointer?.selected) {

            setStateLastOpenedWindow(commandsPointer?.selected);
            const instancedMarker = stateMarkers[commandsPointer?.selected];

            stateInfoWindows[commandsPointer?.selected].open(map, instancedMarker);
          }
        }
      };

      // Function to handle when item is hovered in list
      const handleHovered = () => {
        if (commandsPointer?.hovered !== undefined && commandsPointer?.hovered !== null && stateMarkers[commandsPointer.hovered]) {
          const size = { width: 38, height: 76 } as google.maps.Size;

          // Save icon to restore after hover
          icon = stateMarkers[commandsPointer.hovered].getIcon() as google.maps.Icon;
          // Set icon increased size
          stateMarkers[commandsPointer.hovered].setIcon({ ...icon, size, scaledSize: size });

        }
      };

      // Prevent execute handlers before markers init
      if (markers && markers.length > 0) {

        // If hovered and selected is the same, execute Selected Handler
        if (!_.isNull(commandsPointer?.selected)) handleSelected();
        else if (!_.isNull(commandsPointer?.hovered)) handleHovered();
      }
    }

    return () => {
      // If icon expanded, reset to original size
      if (icon) {
        // Check if markers are instanced
        if (markers && markers.length > 0 && commandsPointer?.hovered !== undefined && commandsPointer?.hovered !== null) {
          if (stateMarkers[commandsPointer.hovered]) {
            stateMarkers[commandsPointer.hovered].setIcon(icon);
          }
        }
      }
    };
  }, [commandsPointer, markers, map, stateInfoWindows, stateLastOpenedWindow, stateMarkers]);

  // Filter markers and polylines using setVisible()
  useEffect(() => {

    // Verify markers to filter
    if (markers) {
      for (let i = 0; i < stateMarkers.length; i++) {
        const visible = !!mapFilter[markers[i]?.class || ""];

        stateMarkers[i].setVisible(visible);
        if (stateMarkers[i]?.getAnimation() != null) {
          stateMarkers[i].setAnimation(stateMarkers[i].getAnimation() || null);
        }
      }
    }

    // Verify polylines to filter
    if (polylines) {
      for (const poly of statePolylines) {
        const visible = !!mapFilter[poly?.class || ""];

        poly.setVisible(visible);
      }
    }

    // Verifiy polygons to filter
    if (polygons)
      for (const polygon of statePolygons) {
        const visible = !!mapFilter[polygon["class"] || ""];

        polygon.setVisible(visible);
      }

  }, [filter, mapFilter, markers, polylines, stateMarkers, statePolylines, polygons]);

  // Return Google Maps Component
  return (<div className="map" ref={ref} style={{ width: "100%", height: mapHeight }} />);
};

type PopperMarkerProps = {
  index: number;
  isGoing: boolean;
  record: TravelAnalytics;
}

type InfoInterface = {
  description?: string;
  startDate?: string | Date;
  finishDate?: string | Date;
  duration?: string | Date;
  icon?: JSX.Element;
  speed?: number;
  custom?: [{ title: string, content: string }];
}

const PopperMarker = React.memo<PopperMarkerProps>((
  { index, isGoing, record: recordParam }
) => {

  const record = recordParam as TravelAnalytics;

  const { t } = useTranslation();

  const generateInfo = (info: InfoInterface) => (
    <>
      <MarkerInfo>
        <div className="col-left">
          {info.icon && (
            info.icon
          )}
        </div>
        <div className="col-right">
          {info.description && (
            <div className="content">
              <div className="title">{t(GlobalMessages.type)}</div>
              <div className="text">{info.description}</div>
            </div>
          )}
          {info.startDate && (
            <div className="content">
              <div className="title">{t(GlobalMessages.start)}</div>
              <div className="text">{utils.formatDateIfHave(info.startDate, "fullDate")}</div>
            </div>
          )}
          {info.finishDate && (
            <div className="content">
              <div className="title">{t(GlobalMessages.end)}</div>
              <div className="text">{utils.formatDateIfHave(info.finishDate, "fullDate")}</div>
            </div>
          )}
          {info.duration && (
            <div className="content">
              <div className="title">{t(GlobalMessages.duration)}</div>
              <div className="text">
                {
                  typeof info.duration === "string"
                    ? info.duration
                    : utils.formatDateIfHave(info.duration, "durationDescriptiveTime")
                }
              </div>
            </div>
          )}
          {info.speed && (
            <div className="content">
              <div className="title">{t(GlobalMessages.speed)}</div>
              <div className="text">{Math.floor(info.speed)}</div>
            </div>
          )}
          {info.custom && info.custom.map((custom, i) => (
            <div className="content" key={custom.title}>
              <div className="title">{custom.title}</div>
              <div className="text">{custom.content}</div>
            </div>
          ))}
        </div>
      </MarkerInfo>
    </>
  );

  let state: InfoInterface | undefined;
  let event: InfoInterface | undefined;
  let alert: InfoInterface | undefined;
  let speed: InfoInterface | undefined;

  const defaultInfo: InfoInterface = {
    startDate: record.occurrence_date,
    icon: <img alt="" width="17" src={Icons.MAP_MARKER as string} />
  };

  if (record.vehicleState) {
    state = {
      description: record.vehicleState.status.description,
      startDate: record.vehicleState.registration_date,
      finishDate: record.vehicleState.finish_date,
      duration: record.vehicleState.duration
        ? utils.formatDateIfHave(record.vehicleState.duration, "durationDescriptiveTime")
        : utils.formatDateIfHave(utils.calcDataRange(record.vehicleState.registration_date, new Date()), "durationDescriptiveTime"),
      icon: <img alt="" width="20" src={utils.getMarkerAccordingVehicleState(record.vehicleState.status.id_vehicle_state_type)} />
    };
  }
  if (record.alert) {

    alert = {
      description: record.alert.type.description,
      startDate: record.alert.occurrence_date,
      finishDate: record.alert?.event?.finish_date,
      icon: <img alt="" width="20" src={utils.getMarkerAccordingVehicleAlert(record.alert.type.description as AlertTypes)} />
    };
    if (record.alert?.event?.finish_date && record.alert?.event?.start_date) {
      alert.duration = utils.formatDateIfHave(
        utils.calcDataRange(new Date(record.alert?.event?.start_date), new Date(record.alert?.event?.finish_date)),
        "durationDescriptiveTime"
      );
    }

    // Remove properties and update record speed when alert type is Excesso Velocidade
    if (AlertTypes.EXCESSO_DE_VELOCIDADE === (record.alert?.event?.type?.description as AlertTypes)) {
      delete alert.duration;
      delete alert.finishDate;
      alert.speed = record.alert.event?.speed;

      record.content["SPD"].content = alert?.speed?.toString() ?? record.content["SPD"].content;
    }

  }
  if (record.event) {
    event = {
      description: record.event.type.description,
      startDate: record.event.start_date,
      finishDate: record.event.finish_date,
      duration: utils.formatDateIfHave(utils.calcDataRange(
        new Date(record.event.start_date), new Date(record.event.finish_date)
      ), "durationDescriptiveTime"),
      speed: record.event?.speed,
      icon: <img alt="" width="15" src={utils.getMarkerAccordingVehicleEvent(record.event.type.description as EventTypes)} />
    };
  }
  if (record.content?.SPD) {

    speed = {
      custom: [{
        title: t(GlobalMessages.speed),
        content: `${parseInt(record.content?.SPD.content, 10)} KM/h`
      }],
      icon: <img alt="" width="17" src={Icons.SPEED_STATUS as string} />
    };
  }

  // TODO Put block, content, title into styled component markerInfo
  return (
    <div id={`marker-info-${index}-content`} className="vehicle-travel-details__map-info-content">
      <div className="block second">
        <MarkerInfo>
          <div className="col-left">
            [{index}]
          </div>
          <div className="col-right">
            <div className="content">
              <div className="title">{t(MapMessages.route)}</div>
              <div className="text">{isGoing ? t(MapMessages.outwardTrip) : t(MapMessages.returnTrip)}</div>
            </div>
          </div>
        </MarkerInfo>
        {!state && !event && !alert && generateInfo(
          defaultInfo
        )}
        {state && generateInfo(state)}
        {alert && generateInfo(alert)}
        {event && generateInfo(event)}
        {speed && generateInfo(speed)}
      </div>
    </div>
  );
});

export {
  MapFixedPointCoord,
  MapAllVehiclesCoords,
  MapAnalytics,
  PopperMarker
};
export type { MarkersVisualizationTypes };
