import { LineString } from "geojson";

/** @type {number} Earth's radius (at the Equator) of 6378137 meters. */
export const EARTH_RADIUS = 6378137;

/** @param {number} radians */
export function toDegrees(radians) {
  return (radians * 180) / Math.PI;
}

/** @param {number} angleDegrees */
export function toRadians(angleDegrees) {
  return (angleDegrees * Math.PI) / 180.0;
}

/**
 * Transforms LineString geojson to an array of LatLng objects.
 * @param {LineString} lineString LineString geojson object.
 * @returns Array of LatLng objects.
 */
export function lineStringToLatLngs(lineString: LineString): { lat: number, lng: number }[] {
  if (!lineString?.coordinates) return [];

  return lineString.coordinates.map(([lng, lat]) => ({ lat, lng }));
}

/**
 * Transforms an array of LatLng objects to a LineString geojson object.
 * @param {LatLng[]} latLngs Array of LatLng objects.
 */
export function latLngsToLineString(latLngs: { lat: number, lng: number }[]): LineString {
  return {
    type: "LineString",
    coordinates: latLngs.map(({ lng, lat }) => [lng, lat])
  };
}
