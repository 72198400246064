/* eslint-disable import/prefer-default-export */
import { TranslationMapMessages, MapMessages } from "../interfaces";

export const messages: TranslationMapMessages = {
  [MapMessages.updateVehicleCurrentPosition]: "Atualize a posição atual do veículo",
  [MapMessages.errorFetchingAddressData]: "Erro ao buscar dados de endereço",
  [MapMessages.locationSearchTitle]: "Busca de localização",
  [MapMessages.typeLocationLabel]: "Digite a localização",
  [MapMessages.allVehiclesSearchLabel]: "Busca por placa, descrição ou cód.",
  [MapMessages.route]: "Rota",
  [MapMessages.outwardTrip]: "Ida",
  [MapMessages.returnTrip]: "Volta",
  [MapMessages.names]: "Nomes",
  [MapMessages.licensePlates]: "Placas",
  [MapMessages.alerts]: "Alertas",
  [MapMessages.states]: "Estados",
  [MapMessages.events]: "Eventos",
  [MapMessages.viewLocation]: "Visualizar localização",
  [MapMessages.zmrc]: "ZMRC",
  [MapMessages.invalidPolygon]: "Polígono inválido"
};
