export enum AlertTypes {
  PARADA_INDEVIDA = "Parada indevida",
  DESCARREGAMENTO_INDEVIDO = "Descarregamento indevido",
  INATIVIDADE_DE_HARDWARE = "Inatividade de hardware",
  EXCESSO_DE_VELOCIDADE = "Excesso de velocidade",
  DESCONEXAO_DE_BATERIA_PRINCIPAL = "Desconexao da bateria principal",
  CONEXAO_DE_BATERIA_PRINCIPAL = "Conexao da bateria principal",
  BLOQUEIO = "Bloqueio de veículo",
  CONCRETO_PROXIMO_AO_VENCIMENTO = "Concreto próximo ao vencimento",
  CONCRETO_VENCIDO = "Concreto vencido",
  MANUTENCAO = "Manutenção de veículo",
  EXCESSO_DE_VELOCIDADE_VIAGEM = "Excesso de velocidade definido para a viagem",
  JAMMING_DETECTADO = "Evento de Jammer",
  ZMRC = "ZMRC",
  ENTRADA_EM_LOCAL_CONFIAVEL = "Entrada em local confiável"
}

export enum AlertTypesID {
  PARADA_INDEVIDA = "c08f4b9c-9d9b-4e9c-a89e-87c44d179228",
  DESCARREGAMENTO_INDEVIDO = "578d3d06-0e9b-49e6-bb95-6097ef1ba647",
  INATIVIDADE_DE_HARDWARE = "4b76ca40-8c29-4617-837d-a49a2d265570",
  EXCESSO_DE_VELOCIDADE = "14152c34-0dc7-4d9d-9433-6549ab222427",
  DESCONEXAO_DE_BATERIA_PRINCIPAL = "f604e712-27a9-4357-b087-8db2b7978056",
  CONEXAO_DE_BATERIA_PRINCIPAL = "64eec395-bcc3-4414-b9c0-25b76d0bc7b1",
  BLOQUEIO = "6cdc4ea0-7667-11ed-a1eb-0242ac120002",
  CONCRETO_PROXIMO_AO_VENCIMENTO = "afcdbf0a-147b-48a3-b167-8fbf339c1ee5",
  CONCRETO_VENCIDO = "28390a93-85c3-41cb-8b6c-05008479e529",
  MANUTENCAO = "0e9aa889-855a-4d2d-9cc4-22cad04153a7",
  EXCESSO_DE_VELOCIDADE_VIAGEM = "ca06d296-c079-4efb-b806-9e6619dbe89d",
  JAMMING_DETECTADO = "20354d7a-e4fe-47af-8ff6-187bca92f3f9",
  ZMRC = "8b1add22-3198-4981-9198-718649a232a8",
  ENTRADA_EM_LOCAL_CONFIAVEL = "49ddd949-38d2-427e-ac63-ac7423211ab4"
}

/**
 * Get all alert types keys
 */
export function getAllAlertTypeKeys(): string[] {
  return Object.keys(AlertTypesID) as (keyof typeof AlertTypesID)[];
}

/**
 * Get alert type ID by key. Returns undefined if not found.
 * @param key Alert type key
 */
export function getAlertTypeID(key: string): string | undefined {
  const alertTypes = Object.entries(AlertTypesID);

  for (const [enumKey, enumValue] of alertTypes) {
    if (enumKey === key) return enumValue;
  }

  return undefined;
}
