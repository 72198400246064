/* eslint-disable import/prefer-default-export */
import { TimelineVehiclesItemMessages, TranslationTimelineVehiclesItemMessages } from "../interfaces";

export const messages: TranslationTimelineVehiclesItemMessages = {
  [TimelineVehiclesItemMessages.latestUpdate]: "Actualizado ",
  [TimelineVehiclesItemMessages.rightNow]: "ahora mismo",
  [TimelineVehiclesItemMessages.for]: "hace",
  [TimelineVehiclesItemMessages.before]: "Antes",
  [TimelineVehiclesItemMessages.during]: "durante",
  [TimelineVehiclesItemMessages.ago]: "atrás",
  [TimelineVehiclesItemMessages.vehicleRunningFor]: "Vehículo en funcionamiento por",
  [TimelineVehiclesItemMessages.moreInfo]: "Más informaciónes",
  [TimelineVehiclesItemMessages.driverIbuttonVerified]: "Conductor verificado por Ibutton",
  [TimelineVehiclesItemMessages.noDriver]: "Sin conductor asignado",
  [TimelineVehiclesItemMessages.noCurrentAllocation]: "Sin asignación actual",
  [TimelineVehiclesItemMessages.distanceTraveledOnTrip]: "Distancia recorrida en el viaje",
  [TimelineVehiclesItemMessages.toThe]: " a la",
  [TimelineVehiclesItemMessages.plant]: "central",
  [TimelineVehiclesItemMessages.construction]: "construcción",
  [TimelineVehiclesItemMessages.errorLoadingArrivalForecast]: "Error al cargar la previsión de llegada"
};
