/* eslint-disable import/prefer-default-export */
import {
  TranslationGlobalMessages,
  GlobalMessages
} from "../interfaces";

export const messages: TranslationGlobalMessages = {
  [GlobalMessages.dialogLandscape]: "Rotate your device to landscape mode.",
  [GlobalMessages.loading]: "Loading...",
  [GlobalMessages.noNameRegistered]: "No name registered",
  [GlobalMessages.actionNotPermitted]: "Action not permitted",
  [GlobalMessages.noAccess]: "Seems like you don't have access to the platform. Please contact Topcon support for assistance.",
  [GlobalMessages.success]: "Success!",
  [GlobalMessages.alert]: "Alert!",
  [GlobalMessages.error]: "Error",
  [GlobalMessages.connectionNotEstablished]: "Connection not established",
  [GlobalMessages.close]: "Close",
  [GlobalMessages.confirm]: "Confirm",
  [GlobalMessages.confirmTravel]: "Do you want to confirm new speed?",
  [GlobalMessages.model]: "Model",
  [GlobalMessages.speed]: "Speed",
  [GlobalMessages.fullSpeed]: "Full Speed",
  [GlobalMessages.duration]: "Duration",
  [GlobalMessages.address]: "Address",
  [GlobalMessages.travelDetails]: "Trip details",
  [GlobalMessages.registerLocation]: "Register location",
  [GlobalMessages.required]: "Required",
  [GlobalMessages.date]: "Date",
  [GlobalMessages.start]: "Start",
  [GlobalMessages.time]: "Time",
  [GlobalMessages.end]: "End",
  [GlobalMessages.noData]: "No data",
  [GlobalMessages.noRegisteredClients]: "No registered clients",
  [GlobalMessages.type]: "Type",
  [GlobalMessages.vehicle]: "Vehicle",
  [GlobalMessages.driver]: "Driver",
  [GlobalMessages.observations]: "Observations",
  [GlobalMessages.register]: "Register",
  [GlobalMessages.yes]: "Yes",
  [GlobalMessages.no]: "No",
  [GlobalMessages.noClientRegistered]: "No client registered",
  [GlobalMessages.quantity]: "Quantity",
  [GlobalMessages.save]: "Save",
  [GlobalMessages.active]: "Active",
  [GlobalMessages.inactive]: "Inactive",
  [GlobalMessages.selectAll]: "Select all",
  [GlobalMessages.search]: "Search",
  [GlobalMessages.apply]: "Apply",
  [GlobalMessages.internalErrorMessage]: "Internal error, contact our support",
  [GlobalMessages.alreadyRegistered]: "already registered",
  [GlobalMessages.notRegistered]: "not registered",
  [GlobalMessages.verifyFieldError]: "Failed to verify field",
  [GlobalMessages.wrongIDFormat]: "ID(s) not in the correct format",
  [GlobalMessages.verifyFieldError]: "Failed to verify field",
  [GlobalMessages.other]: "Other",
  [GlobalMessages.edit]: "Edit",
  [GlobalMessages.filter]: "Filter",
  [GlobalMessages.exceeded]: "exceeded ",
  [GlobalMessages.exceededPT]: "exceeded ",
  [GlobalMessages.exceededBy]: "exceeded by ",
  [GlobalMessages.onTheWay]: "on the way",
  [GlobalMessages.invalidFilter]: "Invalid filter",
  [GlobalMessages.moment]: "Moment",
  [GlobalMessages.back]: "Back",
  [GlobalMessages.updateRegister]: "Update register",
  [GlobalMessages.errorLoadingGoogleMaps]: "Error loading Google Maps API"
};
