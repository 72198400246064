/* eslint-disable import/prefer-default-export */
import { TimelineVehiclesItemMessages, TranslationTimelineVehiclesItemMessages } from "../interfaces";

export const messages: TranslationTimelineVehiclesItemMessages = {
  [TimelineVehiclesItemMessages.latestUpdate]: "Atualizado ",
  [TimelineVehiclesItemMessages.rightNow]: "agora mesmo",
  [TimelineVehiclesItemMessages.for]: "há",
  [TimelineVehiclesItemMessages.before]: "Antes",
  [TimelineVehiclesItemMessages.during]: "durante",
  [TimelineVehiclesItemMessages.ago]: "atrás",
  [TimelineVehiclesItemMessages.vehicleRunningFor]: "Veículo ligado por",
  [TimelineVehiclesItemMessages.moreInfo]: "Mais informações",
  [TimelineVehiclesItemMessages.driverIbuttonVerified]: "Motorista verificado por Ibutton",
  [TimelineVehiclesItemMessages.noDriver]: "Sem motorista alocado",
  [TimelineVehiclesItemMessages.noCurrentAllocation]: "Sem alocação atual",
  [TimelineVehiclesItemMessages.distanceTraveledOnTrip]: "Distância percorrida na viagem",
  [TimelineVehiclesItemMessages.toThe]: " até a",
  [TimelineVehiclesItemMessages.plant]: "central",
  [TimelineVehiclesItemMessages.construction]: "obra",
  [TimelineVehiclesItemMessages.errorLoadingArrivalForecast]: "Erro ao carregar previsão de chegada"
};
