import "react-dual-listbox/lib/react-dual-listbox.css";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { useSelector } from "react-redux";
import DualListBox from "react-dual-listbox";
import Loading from "react-fullscreen-loading";

import * as Yup from "yup";
import * as _ from "lodash";

import { Plant } from "@shared/entities/reg_plants.entity";

import Autocomplete from "@material-ui/lab/Autocomplete";
import EditIcon from "@material-ui/icons/Edit";
import InfoIcon from "@material-ui/icons/Info";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  Popper,
  Tooltip
} from "@material-ui/core";

import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";

import { Checkbox, Switch, TextField } from "unform-material-ui";

import { User } from "@shared/interfaces/user.interface";

import { AlertTypesID } from "@shared/constants/alert-types.enum";
import { TpeAlert } from "@shared/entities/tpe_alerts_types.entity";
import { Hardware } from "@shared/interfaces/hardware.interface";
import { Status } from "@shared/types/status.enum";
import { Vehicle } from "@shared/entities/reg_vehicles.entity";
import { VehicleTypesID } from "@shared/constants/vehicle-types.enum";

import utils from "@utils/useful-functions";

import IconButton from "@material-ui/core/IconButton";
import NewDataTable, { DataTableActions, DataTableButtons, DataTableColumns } from "@molecules/NewDataTable";
import { ScreenPlatform } from "@store/ducks/Screen/screen.type";
import {
  AlertTypeMessages,
  DataTableMessages,
  DualListboxMessages,
  FilterMessages,
  GlobalMessages,
  ManagementMessages,
  MenuMessages,
  ToastMessages
} from "@shared/languages/interfaces";
import useTranslation from "src/translations/useTranslation";
import LimitedChipsField from "@atoms/LimitedChipsField";
import Header from "../../../components/Header";
import ButtonLoading from "../../../components/Button/ButtonLoading";
import ButtonTable from "../../../components/Button/ButtonTable";
import DialogConfirmAction from "../../../components/Dialog/ConfirmAction";

import api from "../../../services/api";
import getValidationErrors from "../../../hooks/getValidationErrors";
import { useToast } from "../../../hooks/useToast";

import { Container, ContainerModalFormUser } from "./styles";

interface ValidateFields {email: boolean;}

interface TpeAlertWithFunctionality extends TpeAlert {
  isAFunctionality?: boolean;
}

const Users: React.FC = () => {

  const { addToast } = useToast();
  const { t, i18n } = useTranslation();

  const [optionsPlants, setOptionsPlants] = useState<Plant[]>([] as Plant[]);
  const [optionsPermissions, setOptionsPermissions] = useState<
    TpeAlertWithFunctionality[]>([] as TpeAlertWithFunctionality[]);
  const [optionsRestrictVehicles, setOptionsRestrictVehicles] = useState<Vehicle[]>([] as Vehicle[]);

  const [openDialogConfirmDelete, setOpenDialogConfirmDelete] = useState(false);
  const [openDialogConfirmSendValidationEmail, setOpenDialogConfirmSendValidationEmail] = useState(false);
  const [openModalFormUser, setOpenModalFormUser] = useState(false);
  const [openOptionsPlants, setOpenOptionsPlants] = useState(false);
  const [openOptionsPermissions, setOpenOptionsPermissions] = useState(false);

  const [activeOptionsPlants, setActiveOptionsPlants] = useState<Plant[]>([] as Plant[]);
  const [activeOptionsPermissions, setActiveOptionsPermissions] = useState<
    TpeAlertWithFunctionality[]>([] as TpeAlertWithFunctionality[]);
  const [activeRestrictVehicles, setActiveRestrictVehicles] = useState<Vehicle[] | undefined>(undefined);
  const [activeRestrictVehicleIds, setActiveRestrictVehicleIds] = useState<string[]>([] as string[]);

  const [loadingUserDetails, setLoadingUserDetails] = useState(false);
  const [loadingUserList, setLoadingUserList] = useState(false);
  const [loadingResendConfirmAccountMail, setLoadingResendConfirmAccountMail] = useState(false);
  const [loadingValidateFieldEmail, setLoadingValidateFieldEmail] = useState(false);
  const loadingOptionsPlants = openOptionsPlants && optionsPlants.length === 0;
  const loadingOptionsPermissions = openOptionsPermissions && optionsPermissions.length === 0;

  const [users, setUsers] = useState<User[]>([] as Array<User>);
  const [userDetails, setUserDetails] = useState<User>({} as User);

  const [modalType, setModalType] = useState<"details" | "update" | "register">("details");

  const [table, setTable] = useState<DataTables.Api>({} as DataTables.Api);
  const [tableClickedRow, setTableClickedRow] = useState<JQuery<HTMLTableRowElement>>(
    {} as JQuery<HTMLTableRowElement>
  );

  const formRef = useRef<FormHandles>(null);
  const [activeCheckbox, setActiveCheckbox] = useState<boolean>(true);
  const [dailyReportCheckbox, setDailyReportCheckbox] = useState<boolean>(false);
  const [weeklyReportCheckbox, setWeeklyReportCheckbox] = useState<boolean>(false);
  const [adminCheckbox, setAdminCheckbox] = useState<boolean>(false);
  const [checkAllPermissions, setCheckAllPermissions] = React.useState(false);
  const [validateFields, setValidateFields] = useState<ValidateFields>({ email: false });
  const { screen } = useSelector((screen) => screen);

  // Functionalities alert Types
  const ALLOWED_CHANGE_PARAMS = {
    id: "db519d0b-fd2e-4296-a2a7-401ac8141b6e",
    description: t(ManagementMessages.allowedChangeParams),
    isAFunctionality: true
  } as TpeAlertWithFunctionality;

  const ALLOWED_CHANGE_VEHICLES = {
    id: "fbfd6de9-6550-4cf0-846e-b6c90dcbe1cb",
    description: t(ManagementMessages.allowedChangeVehicles),
    isAFunctionality: true
  } as TpeAlertWithFunctionality;

  const ALLOWED_CHANGE_DRIVERS = {
    id: "9c582eca-90ca-4cb4-a7db-073865b5177d",
    description: t(ManagementMessages.allowedChangeDrivers),
    isAFunctionality: true
  } as TpeAlertWithFunctionality;

  const ALLOWED_CHANGE_LOCATIONS = {
    id: "0be99450-9ae5-4ddb-8ea3-698f4ed8b9c3",
    description: t(ManagementMessages.allowedChangeLocations),
    isAFunctionality: true
  } as TpeAlertWithFunctionality;

  const ALLOWED_BLOCK_VEHICLE = {
    id: AlertTypesID.BLOQUEIO,
    description: t(AlertTypeMessages[AlertTypesID.BLOQUEIO]),
    isAFunctionality: true
  } as TpeAlertWithFunctionality;

  // Data table settings
  const dataTableSettings: DataTables.Settings = {
    order: [[0, "asc"]],
    columnDefs: [{ className: "dt-center", targets: -1 }]
  };
  const dataTableActions: DataTableActions[] = [
    {
      ref: ".modules-users-list-details",
      callback: async (rowData: User) => await handleUserDetails(rowData)
    },
    {
      ref: ".modules-users-list-delete",
      callback: (rowData: User) => handleUserDelete(rowData)
    }
  ];
  const dataTableColumns: DataTableColumns[] = [
    { // Nome
      title: t(ManagementMessages.dataTableName),
      data: (user: User) => user.name,
      defaultContent: "",
      filterable: true,
      propertyName: "name"
    },
    { // Email
      title: t(ManagementMessages.dataTableEmail),
      data: (user: User) => user.email,
      defaultContent: "",
      filterable: true,
      propertyName: "email"
    },
    { // Data de cadastro
      title: t(ManagementMessages.dataTableRegisterDate),
      data: (user: User) => user.registration_date,
      render: (data, type) => (type === "sort" ? data : utils.formatDateIfHave(new Date(data), "fullDate")),
      defaultContent: "",
      filterable: true,
      propertyName: "registration_date"
    },
    { // Ultimo acesso
      title: t(ManagementMessages.dataTableLastAccess),
      data: (user: User) => user.last_access,
      render: (data, type) => (type === "sort" ? data : utils.formatDateIfHave(new Date(data), "fullDate")),
      defaultContent: "",
      filterable: true,
      propertyName: "last_access"
    },
    { // Ativo
      title: t(DataTableMessages.status),
      data: (user: User) => (
        ReactDOMServer.renderToString(
          <b style={{ color: user.active ? "rgb(70, 193, 125)" : "rgb(230, 74, 25)" }}>
            {user.active ? t(GlobalMessages.active).toUpperCase() : t(GlobalMessages.inactive).toUpperCase()}
          </b>
        )
      ),
      filterable: true,
      propertyName: "active"
    },
    { // Ações
      title: t(DataTableMessages.actions),
      orderable: false,
      searchable: false,
      data: () => ReactDOMServer.renderToString(
        <Grid container spacing={1}>
          <Grid item xs sm md lg xl>
            <ButtonTable
              className="action-button modules-users-list-details"
            >
              <FormatListBulletedIcon />
            </ButtonTable>
          </Grid>
          <Grid item xs sm md lg xl>
            <ButtonTable
              className="action-button modules-users-list-delete"
            >
              <DeleteForeverIcon />
            </ButtonTable>
          </Grid>
        </Grid>
      ),
      width: "130px",
      filterable: false
    }
  ];
  const dataTableButtons: DataTableButtons[] = [
    {
      name: t(DataTableMessages.buttonsAddNew),
      key: "add",
      callback: () => FhandleUserCreate()
    },
    {
      name: t(DataTableMessages.buttonsRefresh),
      key: "refresh",
      callback: () => readUsers()
    },
    {
      name: t(DataTableMessages.buttonsPrint),
      key: "print",
      callback: () => utils.clickButtonDomElement("button-print"),
      extend: "print",
      className: "button-print",
      exportOptions: {
        columns: "th:not(:last-child)"
      }
    },
    {
      name: t(DataTableMessages.buttonsExport),
      callback: () => utils.clickButtonDomElement("button-export"),
      extend: "csv",
      key: "export",
      fieldSeparator: ";",
      className: "button-export",
      filename: `relatorio_usuarios_${new Date().toISOString().split("T")[0]}`,
      exportOptions: {
        columns: "th:not(:last-child)"
      }
    }
  ];

  // Dual list box settings
  const vehicleOptions = optionsRestrictVehicles.map((vehicle) => ({
    label: `${vehicle.code} - ${vehicle.license_plate}`,
    value: vehicle.id_vehicle,
    type: vehicle.type
  }));

  // TODO: add aggregate trucks vehicles
  const formattedRestrictVehicleOptions = [
    {
      label: t(FilterMessages.concreteMixers),
      options: vehicleOptions.filter(
        (vehicle) => vehicle?.type?.id_vehicle_type === VehicleTypesID.BETONEIRA
      )
    },
    {
      label: t(FilterMessages.pumpTrucks),
      options: vehicleOptions.filter(
        (vehicle) => vehicle?.type?.id_vehicle_type === VehicleTypesID.CAMINHAO_BOMBA
      )
    },
    {
      label: t(FilterMessages.supportVehicles),
      options: vehicleOptions.filter(
        (vehicle) => vehicle?.type?.id_vehicle_type === VehicleTypesID.VEICULO_DE_APOIO
      )
    },
    {
      label: t(FilterMessages.loaders),
      options: vehicleOptions.filter(
        (vehicle) => vehicle?.type?.id_vehicle_type === VehicleTypesID.PA_CARREGADEIRA
      )
    }
  ];

  const dualListboxLang = {
    availableFilterHeader: t(DualListboxMessages.availableFilterHeader),
    availableHeader: t(DualListboxMessages.permittedVehiclesHeader),
    moveAllLeft: t(DualListboxMessages.moveAllLeft),
    moveAllRight: t(DualListboxMessages.moveAllRight),
    moveLeft: t(DualListboxMessages.moveLeft),
    moveRight: t(DualListboxMessages.moveRight),
    moveBottom: t(DualListboxMessages.moveBottom),
    moveDown: t(DualListboxMessages.moveDown),
    moveUp: t(DualListboxMessages.moveUp),
    moveTop: t(DualListboxMessages.moveTop),
    noAvailableOptions: t(DualListboxMessages.noAvailableOptions),
    noSelectedOptions: t(DualListboxMessages.noSelectedOptions),
    selectedFilterHeader: t(DualListboxMessages.selectedFilterHeader),
    selectedHeader: t(DualListboxMessages.restrictedVehiclesHeader)
  };

  // Actions users list

  /*
  * Open modal to create new user
  * */
  const FhandleUserCreate = useCallback(() => {
    setModalType("register");
    setOpenModalFormUser(true);
  }, []);

  const handleUserDetails = useCallback(async (user: User) => {

    setLoadingUserDetails(true);

    const permissions = [...user.alerts_permissions];
    
    // if user has permission to access parameters, mark as selected
    if (user.allowed_change_params
      && !permissions.find((alert) => alert["id"] === ALLOWED_CHANGE_PARAMS["id"])
    ) permissions.push(ALLOWED_CHANGE_PARAMS);

    // if user has permission to access vehicles, mark as selected
    if (user.allowed_change_vehicles
      && !permissions.find((alert) => alert["id"] === ALLOWED_CHANGE_VEHICLES["id"])
    ) permissions.push(ALLOWED_CHANGE_VEHICLES);

    // if user has permission to access drivers, mark as selected
    if (user.allowed_change_drivers
      && !permissions.find((alert) => alert["id"] === ALLOWED_CHANGE_DRIVERS["id"])
    ) permissions.push(ALLOWED_CHANGE_DRIVERS);

    // if user has permission to access locations, mark as selected
    if (user.allowed_change_locations
      && !permissions.find((alert) => alert["id"] === ALLOWED_CHANGE_LOCATIONS["id"])
    ) permissions.push(ALLOWED_CHANGE_LOCATIONS);

    // if user is allowed to block, but still do not have a block alert permission, insert it
    if (user.allowed_vehicle_block
      && !user.alerts_permissions.find((alert) => alert["id"] === ALLOWED_BLOCK_VEHICLE["id"])
    ) permissions.push(ALLOWED_BLOCK_VEHICLE);

    const restrictedVehicles = await getRestrictedVehicles(user.id_user);

    setUserDetails(user);
    setActiveOptionsPlants(user.plants);
    setActiveCheckbox(user.active);
    setAdminCheckbox(user.admin);
    setValidateFields({ email: true });
    setDailyReportCheckbox(user.receive_daily_report);
    setWeeklyReportCheckbox(user.receive_weekly_report);
    setActiveOptionsPermissions(permissions);    
    setActiveRestrictVehicles(restrictedVehicles);
    setActiveRestrictVehicleIds(restrictedVehicles.map((vehicle) => vehicle.id_vehicle!));

    setModalType("details");
    setOpenModalFormUser(true);
    setLoadingUserDetails(false);

  }, [ALLOWED_CHANGE_PARAMS, ALLOWED_CHANGE_VEHICLES, ALLOWED_CHANGE_DRIVERS, ALLOWED_CHANGE_LOCATIONS, t]);

  const handleUserDelete = useCallback((user: User) => {

    setUserDetails(user);
    setOpenDialogConfirmDelete(true);

  }, []);

  // Actions close dialogs and modals
  const handleCloseModalForm = useCallback(() => {

    setOpenModalFormUser(false);

    setTimeout(() => {
      setActiveCheckbox(true);
      setActiveOptionsPlants([]);
      setActiveOptionsPermissions([]);
      setAdminCheckbox(false);
      setUserDetails({} as User);
      setValidateFields({ email: false });
      setActiveRestrictVehicles(undefined);
      setActiveRestrictVehicleIds([]);
      setOptionsRestrictVehicles([]);
    }, 0);

  }, []);

  /** Validations of unique fields
   */
  const validations = {

    validateFieldError: (fieldName: string) => {
      if (formRef.current?.getFieldError(fieldName)?.length) formRef.current?.setFieldError(fieldName, "");
    },
    validateEmail: async (email: string) => {

      setValidateFields({ ...validateFields, email: false });

      // Validate re just have 3 or more characters in on blur
      if (email.trim().length >= 3 && email.trim() !== userDetails.email) {

        try {

          setLoadingValidateFieldEmail(true);

          // Verify if Email is already registered
          const { data } = await api.get(`users/verify-unique-field/email/${email.trim()}`);

          if (data.status === "alert") formRef.current?.setFieldError("email", t(ManagementMessages.formEmailRegistered));
          else formRef.current?.setFieldError("email", "");

          setValidateFields({ ...validateFields, email: true });

        } catch (error) {
          if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
          else {
            addToast(
              { type: "error", title: error.response.data.backend, description: error.response.data.message }
            );
          }
        } finally {
          setLoadingValidateFieldEmail(false);
        }
      }

    },
    validateForm: async (formData: User, action: "details" | "register" | "update") => {

      try {

        // Revalidate unique fields (If not validate yet)
        if (!validateFields.email && formData.email.trim().length) await validations.validateEmail(formData.email);

        // Define the validation types
        const schema = Yup.object().shape({
          name: Yup.string().trim().required(t(ManagementMessages.formRequiredName)),
          email: Yup.string().trim().required(t(ManagementMessages.formRequiredEmail))
            .email(t(ManagementMessages.formValidEmail))
            .test("validateEmail", formRef.current?.getFieldError("email"),
              () => !(formRef.current?.getFieldError("email")?.length)),
          plants: Yup.string().test("hasPlants", t(ManagementMessages.formRequiredPlant),
            (val) => (activeOptionsPlants.length !== 0)),
          password: Yup.string().trim().test("validatePassword", t(ManagementMessages.formValidPassword),
            (val) => _.isEmpty(val) || val.length >= 6),
          passwordConfirm: Yup.string().trim().oneOf([Yup.ref("password"), null], t(ManagementMessages.formEqualsPassword))
            .test("validatePasswordConfirm", t(ManagementMessages.formValidConfirmPassword),
              (val) => _.isEmpty(val) || val.length >= 6)
        });

        // Validate inputs
        await schema.validate(formData, { abortEarly: false });

        // Register or update driver (According action selected)
        if (action === "register") {

          // If have password on register -> Create User
          // If not have password on register -> Open dialog so send email to validate account
          formData.password.length > 0 ? await createUser(formData, false) : setOpenDialogConfirmSendValidationEmail(
            true
          );

        } else await updateUser(formData);

      } catch (error) {
        formRef.current?.setErrors(getValidationErrors(error));
      }
    }
  };

  /** Check what modal type to show corresponding values and actions
   * @param detailValue
   * @param updateValue
   * @param registerValue
   */
  const defineValueAccordingModalType = useCallback((detailValue, updateValue, registerValue) => {

    switch (modalType) {
    case "details":
      return detailValue;
    case "update":
      return updateValue;
    case "register":
      return registerValue;
    default:
      return "S/N";
    }
  }, [modalType]);

  /** Get all users data
   */
  const readUsers = useCallback(async () => {

    try {

      // Get all users
      setLoadingUserList(true);
      const { data } = await api.get("users/read", {
        params: {
          linkedPlants: true,
          lastSentAlert: true
        }
      });

      if (data.status === "success") setUsers(data.result);
      else addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });

    } catch (error) {

      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

    } finally {
      setLoadingUserList(false);
    }

  }, [addToast, t]);

  /**
   * Get restricted vehicles from user
   * @param userId User id
   */
  const getRestrictedVehicles = useCallback(async (userId: string) => {

    try {

      // Get all restricted vehicles from user
      const { data } = await api.get(`users/read/restricted-vehicles/${userId}`);

      if (data.status === "success") return data.result as Vehicle[];

      addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });

      return [] as Vehicle[];

    } catch (error) {

      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

      return [] as Vehicle[];
    }
  }, [addToast, t]);

  /** Create a user
   * @param user User to create
   * @param sendMail If send email to confirm and change password or not
   */
  const createUser = useCallback(async (user: User, sendMail: boolean) => {

    // eslint-disable-next-line no-param-reassign
    delete user.passwordConfirm;

    try {

      let insertUser = { ...user, sendMail };

      // Fill authorized plants and permissions in user
      if (activeOptionsPlants.length) insertUser.plants = activeOptionsPlants;
      if (activeOptionsPermissions.length) {

        insertUser.alerts_permissions = activeOptionsPermissions;

        // Block permission is determined according to the vehicle block alert permission
        insertUser.allowed_vehicle_block = insertUser.alerts_permissions.some(
          (permittedAlertType) => permittedAlertType.id_alert_type === AlertTypesID.BLOQUEIO
        );

        // Access permission to parameters is determined according to access permission to settings
        insertUser.allowed_change_params = insertUser.alerts_permissions.some(
          (permittedAlertType) => permittedAlertType.description === ALLOWED_CHANGE_PARAMS.description
        );

        // Access permission to vehicles is determined according to access vehicles module
        insertUser.allowed_change_vehicles = insertUser.alerts_permissions.some(
          (permittedAlertType) => permittedAlertType.description === ALLOWED_CHANGE_VEHICLES.description
        );

        // Access permission to drivers is determined according to access drivers module
        insertUser.allowed_change_drivers = insertUser.alerts_permissions.some(
          (permittedAlertType) => permittedAlertType.description === ALLOWED_CHANGE_DRIVERS.description
        );

        // Access permission to locations is determined according to access locations module
        insertUser.allowed_change_locations = insertUser.alerts_permissions.some(
          (permittedAlertType) => permittedAlertType.description === ALLOWED_CHANGE_LOCATIONS.description
        );
      }

      // Fill restricted vehicles in user
      if (activeRestrictVehicleIds.length > 0) {
        insertUser.restricted_vehicles = activeRestrictVehicleIds.map(
          (idVehicle) => ({ id_vehicle: idVehicle })
        ) as Vehicle[];
      } else insertUser.restricted_vehicles = [] as Vehicle[];

      // Remove properties without value
      Object.keys(insertUser).forEach((key) => {
        if (insertUser[key].length <= 0) delete insertUser[key];
      });

      // Create a user
      setLoadingUserList(true);

      const { data } = await api.post("users/create", insertUser);

      if (data.status === "success") {

        handleCloseModalForm();
        setOpenDialogConfirmSendValidationEmail(false);
        addToast({ type: "success", title: t(ToastMessages.success), description: data.message });

        insertUser = data.result;

        if (!users.length) readUsers();
        else {

          // Add inserted user in the table
          table.row.add(insertUser);
          table.draw();
        }

        // Search the inserted user and list
        table.search(user.email).draw();

      } else addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });

    } catch (error) {

      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

    } finally {
      setLoadingUserList(false);
    }

  }, [
    addToast, table, users, activeOptionsPlants, activeRestrictVehicleIds, t,
    readUsers, handleCloseModalForm, activeOptionsPermissions,
    ALLOWED_CHANGE_PARAMS.description, ALLOWED_CHANGE_VEHICLES.description,
    ALLOWED_CHANGE_DRIVERS.description, ALLOWED_CHANGE_LOCATIONS.description]);

  /** Update a user
   * @param user User to update
   */
  const updateUser = useCallback(async (user: User) => {

    // eslint-disable-next-line no-param-reassign
    delete user.passwordConfirm;

    try {

      const updateUser = { ...user };

      // Fill authorized plants and alert permissions in user
      if (activeOptionsPlants.length) updateUser.plants = activeOptionsPlants;
      if (activeOptionsPermissions.length) {

        updateUser.alerts_permissions = activeOptionsPermissions;

        // Block permission is determined according to the vehicle block alert permission
        updateUser.allowed_vehicle_block = updateUser.alerts_permissions.some(
          (permittedAlertType) => permittedAlertType.id_alert_type === AlertTypesID.BLOQUEIO
        );

        // Access permission to parameters is determined according to access permission to settings
        updateUser.allowed_change_params = updateUser.alerts_permissions.some(
          (permittedAlertType) => permittedAlertType.description === ALLOWED_CHANGE_PARAMS.description
        );

        // Access permission to vehicles is determined according to access vehicles module
        updateUser.allowed_change_vehicles = updateUser.alerts_permissions.some(
          (permittedAlertType) => permittedAlertType.description === ALLOWED_CHANGE_VEHICLES.description
        );

        // Access permission to drivers is determined according to access drivers module
        updateUser.allowed_change_drivers = updateUser.alerts_permissions.some(
          (permittedAlertType) => permittedAlertType.description === ALLOWED_CHANGE_DRIVERS.description
        );

        // Access permission to locations is determined according to access locations module
        updateUser.allowed_change_locations = updateUser.alerts_permissions.some(
          (permittedAlertType) => permittedAlertType.description === ALLOWED_CHANGE_LOCATIONS.description
        );

      } else {

        // If there is no permission, remove all permissions
        updateUser.alerts_permissions = [];
        updateUser.allowed_vehicle_block = false;
        updateUser.allowed_change_params = false;
        updateUser.allowed_change_vehicles = false;
        updateUser.allowed_change_drivers = false;
        updateUser.allowed_change_locations = false;
      }

      // Fill restricted vehicles in user
      if (activeRestrictVehicleIds.length > 0) {
        updateUser.restricted_vehicles = activeRestrictVehicleIds.map(
          (idVehicle) => ({ id_vehicle: idVehicle })
        ) as Vehicle[];
      } else updateUser.restricted_vehicles = [] as Vehicle[];

      // Remove properties without value
      Object.keys(updateUser).forEach((key) => {
        if (updateUser[key].length <= 0 && key !== "telegram_id" && key !== "restricted_vehicles") delete updateUser[key];
      });

      // Update a user
      setLoadingUserList(true);

      const { data } = await api.patch(`users/update/${userDetails.id_user}`, updateUser);

      if (data.status === "success") {

        handleCloseModalForm(); // Close modal
        addToast({ type: "success", title: t(ToastMessages.success), description: data.message });

        setUserDetails(Object.assign(userDetails, updateUser));

        // Update row in table with updated driver data
        table.row(tableClickedRow).data(userDetails);
        table.draw();

      } else addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });

    } catch (error) {

      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

    } finally {
      setLoadingUserList(false);
    }

  }, [
    addToast,
    table,
    tableClickedRow,
    userDetails,
    activeOptionsPlants,
    handleCloseModalForm,
    activeOptionsPermissions,
    ALLOWED_CHANGE_PARAMS.description,
    ALLOWED_CHANGE_VEHICLES.description,
    ALLOWED_CHANGE_DRIVERS.description,
    ALLOWED_CHANGE_LOCATIONS.description,
    t,
    activeRestrictVehicleIds
  ]);

  /** Delete selected user
   * @param user User to delete
   */
  const deleteUser = useCallback(async (user: User) => {

    try {

      setLoadingUserList(true);
      const { data } = await api.delete(`users/delete/${user.id_user}`);

      if (data.status === "success") {

        setOpenDialogConfirmDelete(false); // Close dialog confirm delete
        addToast({ type: "success", title: t(ToastMessages.success), description: data.message });

        table.row(tableClickedRow).remove(); // Removes the deleted record from the table (WE NEED THE REFERENCE OF ROW)
        table.search("");
        table.draw();

      } else addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });

    } catch (error) {

      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

    } finally {
      setLoadingUserList(false);
    }

  },
  // eslint-disable-next-line
    [table, tableClickedRow]);

  /** Resend the confirmation account mail to user
   */
  const resendConfirmAccountMail = useCallback(async () => {

    try {

      setLoadingResendConfirmAccountMail(true);
      const { data } = await api.post("auth/send-confirm-account-mail", { email: userDetails.email });

      if (data.status === "success") addToast({ type: "success", title: t(ToastMessages.success), description: data.message });
      else addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });

    } catch (error) {

      if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
      else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

    } finally {
      setLoadingResendConfirmAccountMail(false);
    }

  }, [addToast, userDetails.email, t]);

  /** Create a helper text about send alerts to telegram feature
   */
  const getHelperTextToTelegram = useCallback(() => {

    const helperText = (userDetails.sent_alerts_history
      && userDetails.sent_alerts_history?.length > 0
      && userDetails.sent_alerts_history[0].status === Status.ERROR)
      ? t(ManagementMessages.detailsTelegramIdInfo)
      : t(ManagementMessages.detailsTelegramError);

    return helperText;
  }, [userDetails, t]);

  const handleCheckAllPermissionsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckAllPermissions(event.target.checked);
    if (event.target.checked) {
      setActiveOptionsPermissions(optionsPermissions);
    } else {
      setActiveOptionsPermissions([]);
    }
  };

  // Used to disable and change text when is searching for data
  React.useEffect(() => {
    const btnPesquisar = document.querySelector(".btn-pesquisar") as HTMLElement;

    if (btnPesquisar) {
      btnPesquisar.innerText = loadingUserList ? "Buscando usuários..." : "Pesquisar";

      if (loadingUserList) {
        btnPesquisar.setAttribute("disabled", "");
      } else {
        btnPesquisar.removeAttribute("disabled");
      }
    }
  }, [loadingUserList]);

  // Plants options
  useEffect(() => {
    if (!openOptionsPlants) setOptionsPlants([]);
  }, [openOptionsPlants]);
  useEffect(() => {

    let active = true;

    if (!loadingOptionsPlants) return undefined;

    (async () => {

      try {

        // Get all locations with type = 'Usina'
        const { data } = await api.get("plants/read");

        if (data.status === "success") {
          if (active) setOptionsPlants(data.result);
        } else {
          setOpenOptionsPlants(false);
          addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });
        }

      } catch (error) {

        setOpenOptionsPlants(false);

        if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
        else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

      }

    })();

    return () => { active = false; };

  }, [loadingOptionsPlants, addToast, t]);

  // Restricted vehicles options
  useEffect(() => {

    // If activeRestrictVehicles is not ready, do not set options
    if (!activeRestrictVehicles) {
      // If modal is register, set empty options and activeRestrictVehicles is ready
      if (modalType === "register") setActiveRestrictVehicles([]);

      return;
    }

    if (activeOptionsPlants?.length <= 0) {
      setOptionsRestrictVehicles(activeRestrictVehicles);

      return;
    }

    (async () => {

      try {

        // Filter vehicles according to the current plants selected
        const customPlantsFilter = activeOptionsPlants.map((plant) => plant.id_plant);

        // Get all vehicles with linked hardware and filter by current plant permissions
        const { data } = await api.get("vehicles/read", { params: { linkedHardware: true, customPlantsFilter } });

        if (data.status === "success") {

          const result = data.result as Vehicle[];

          for (const vehicle of activeRestrictVehicles) {

            if (!result.find((vehicleResult) => vehicleResult.id_vehicle === vehicle.id_vehicle)) {
              result.push(vehicle);
            }
          }

          result.sort((a, b) => a.code!.localeCompare(b.code!));

          setOptionsRestrictVehicles(result);

        } else {
          addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });
        }

      } catch (error) {

        if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
        else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });
      }

    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeOptionsPlants, activeRestrictVehicles, addToast, t]);

  // Permissions options
  useEffect(() => {
    if (!openOptionsPermissions) setOptionsPermissions([]);
  }, [openOptionsPermissions]);
  useEffect(() => {

    let active = true;

    if (!loadingOptionsPermissions) return undefined;

    (async () => {

      try {

        // Get all alert types
        const { data } = await api.get("alerts/types/read");

        // Get all hardwares
        const hardwareData = await api.get("hardwares/read");

        if (data.status === "success" && hardwareData.data.status === "success") {

          // Filter and set alert types to options
          if (active) {

            let options = [] as TpeAlertWithFunctionality[];

            // If there is any block capable hardware, include the feature option 'Bloqueio de veículo'
            if (hardwareData.data.result.some((hardware: Hardware) => hardware.block_capable)) {

              options = data.result.filter(
                (type: TpeAlertWithFunctionality) => type.id_alert_type !== AlertTypesID.INATIVIDADE_DE_HARDWARE
                  && type.id_alert_type !== AlertTypesID.MANUTENCAO
              );

              const blockIndex = options.findIndex((type: TpeAlertWithFunctionality) => type.id_alert_type === AlertTypesID.BLOQUEIO);

              if (blockIndex !== -1) options[blockIndex].isAFunctionality = true;

            } else {

              options = data.result.filter(
                (type: TpeAlertWithFunctionality) => type.id_alert_type !== AlertTypesID.BLOQUEIO
                  && type.id_alert_type !== AlertTypesID.INATIVIDADE_DE_HARDWARE
                  && type.id_alert_type !== AlertTypesID.MANUTENCAO
              );
            }

            options.push(ALLOWED_CHANGE_PARAMS as TpeAlertWithFunctionality);
            options.push(ALLOWED_CHANGE_VEHICLES as TpeAlertWithFunctionality);
            options.push(ALLOWED_CHANGE_DRIVERS as TpeAlertWithFunctionality);
            options.push(ALLOWED_CHANGE_LOCATIONS as TpeAlertWithFunctionality);

            setOptionsPermissions(options);

          }

        } else {
          setOpenOptionsPermissions(false);
          addToast({ type: "info", title: t(ToastMessages.alert), description: data.message });
        }

      } catch (error) {

        setOpenOptionsPermissions(false);

        if (!error.response) addToast({ type: "error", title: t(ToastMessages.error), description: t(ToastMessages.connectionNotEstablished) });
        else addToast({ type: "error", title: error.response.data.backend, description: error.response.data.message });

      }
    })();

    return () => { active = false; };

  }, [
    loadingOptionsPermissions,
    addToast,
    optionsPermissions,
    ALLOWED_CHANGE_PARAMS,
    ALLOWED_CHANGE_VEHICLES,
    ALLOWED_CHANGE_DRIVERS,
    ALLOWED_CHANGE_LOCATIONS,
    t
  ]);

  // Get all users on first render and when language changes
  useEffect(() => {
    setUsers([]);

    readUsers().then();
  }, [readUsers, i18n.language]);

  return (
    <>
      <Header title={t(MenuMessages.users)} />
      <Container platform={screen.platform as ScreenPlatform} className="page">
        <Loading loading={loadingUserDetails} />
        <Loading loading={loadingUserList} />
        {
          !loadingUserList && (
            <NewDataTable
              title={t(MenuMessages.users).toLowerCase()}
              data={users}
              filters
              columns={dataTableColumns}
              settings={dataTableSettings}
              actions={dataTableActions}
              buttons={dataTableButtons}
              returnTable={(table) => setTable(table)}
              returnClickedRow={(clickedRow) => setTableClickedRow(clickedRow)}
            />
          )
        }
        <DialogConfirmAction
          open={openDialogConfirmDelete}
          onClose={() => setOpenDialogConfirmDelete(false)}
          title={t(ManagementMessages.deleteConfirmationTitle)}
          actions={[
            { text: t(GlobalMessages.no).toUpperCase(), action: () => setOpenDialogConfirmDelete(false) },
            { text: t(GlobalMessages.yes).toUpperCase(), action: () => deleteUser(userDetails) }
          ]}
        >
          {t(ManagementMessages.deleteConfirmationText)} <br /><br />
          {t(ManagementMessages.dataTableName)}: {userDetails.name} <br />
          {t(ManagementMessages.dataTableEmail)}: {userDetails.email}?
        </DialogConfirmAction>
        <DialogConfirmAction
          open={openDialogConfirmSendValidationEmail}
          onClose={() => setOpenDialogConfirmSendValidationEmail(false)}
          title={t(ManagementMessages.validationTitle)}
          actions={[
            { text: t(GlobalMessages.no).toUpperCase(), action: () => setOpenDialogConfirmSendValidationEmail(false) },
            {
              text: t(GlobalMessages.yes).toUpperCase(),
              action: () => createUser(formRef.current?.getData() as User, true)
            }
          ]}
        >
          {t(ManagementMessages.validationText)}
          <b> {formRef.current?.getFieldValue("email")}</b><br /><br />
          {t(ManagementMessages.validationConfirmation)}
        </DialogConfirmAction>
        <ContainerModalFormUser id="modalFormUser">
          <Dialog
            open={openModalFormUser}
            onClose={handleCloseModalForm}
            scroll="paper"
            container={document.getElementById("modalFormUser")}
          >
            <DialogTitle className="mHeader">
              <div className="content">
                <div className="title">{defineValueAccordingModalType(userDetails.name, userDetails.name,
                  t(ManagementMessages.detailsHeaderRegister))}
                </div>
                <div className="subtitle">{defineValueAccordingModalType(userDetails.email, userDetails.email,
                  "")}
                </div>
              </div>
              <div className="actions">
                {modalType === "details"
                  && (
                    <Button disableRipple onClick={() => setModalType("update")}><EditIcon /></Button>
                  )}
              </div>
            </DialogTitle>
            <DialogContent dividers className="mContent">
              <Form
                className="form"
                ref={formRef}
                onSubmit={(formData) => validations.validateForm(formData, modalType)}
              >
                <DialogContentText tabIndex={-1} component="div">
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={12} lg={12} className="checkboxes">
                      <FormControlLabel
                        className="checkbox"
                        control={(
                          <Checkbox
                            onChange={(event) => setActiveCheckbox(event.target.checked)}
                            checked={activeCheckbox}
                            name="active"
                            style={{ paddingRight: "0px" }}
                            disabled={modalType === "details"}
                          />
                        )}
                        label={t(ManagementMessages.detailsActiveUser)}
                        style={{ paddingRight: "10px" }}
                      />
                      <FormControlLabel
                        className="checkbox"
                        control={(
                          <Checkbox
                            onChange={(event) => setDailyReportCheckbox(event.target.checked)}
                            checked={dailyReportCheckbox}
                            name="receive_daily_report"
                            style={{ paddingRight: "0px" }}
                            disabled={modalType === "details"}
                          />
                        )}
                        label={t(ManagementMessages.detailsDailyReport)}
                        style={{ paddingRight: "10px" }}
                      />
                      <FormControlLabel
                        className="checkbox"
                        control={(
                          <Checkbox
                            onChange={(event) => setWeeklyReportCheckbox(event.target.checked)}
                            checked={weeklyReportCheckbox}
                            name="receive_weekly_report"
                            style={{ paddingRight: "0px" }}
                            disabled={modalType === "details"}
                          />
                        )}
                        label={t(ManagementMessages.detailsWeeklyReport)}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <TextField
                        className="name"
                        label={t(ManagementMessages.detailsFullname)}
                        margin="dense"
                        variant="outlined"
                        name="name"
                        InputLabelProps={{
                          shrink: formRef.current?.getFieldValue("name").length > 0 ? true : undefined
                        }}
                        disabled={modalType === "details"}
                        helperText={t(GlobalMessages.required)}
                        defaultValue={defineValueAccordingModalType(userDetails.name, userDetails.name, "")}
                        onChange={() => validations.validateFieldError("name")}
                      />
                    </Grid>
                    <Grid item xs={12} md={8} lg={8}>
                      <TextField
                        className="email"
                        label={t(ManagementMessages.detailsEmail)}
                        margin="dense"
                        variant="outlined"
                        name="email"
                        type="email"
                        InputLabelProps={{
                          shrink: formRef.current?.getFieldValue("email").length > 0 ? true : undefined
                        }}
                        InputProps={{
                          endAdornment: (
                            <>
                              {loadingValidateFieldEmail ? <CircularProgress color="inherit" size={20} /> : null}
                            </>
                          )
                        }}
                        disabled={modalType === "details"}
                        helperText={t(GlobalMessages.required)}
                        defaultValue={defineValueAccordingModalType(userDetails.email, userDetails.email, "")}
                        onBlur={() => validations.validateEmail(formRef.current?.getFieldValue("email"))}
                        onChange={() => validations.validateFieldError("email")}
                      />
                    </Grid>
                    <Grid item xs={12} md={4} lg={4} className="admin">
                      <FormControlLabel
                        control={(
                          <Switch
                            checked={adminCheckbox}
                            onChange={(event) => setAdminCheckbox(event.target.checked)}
                            name="admin"
                            disabled={modalType === "details"}
                            color="primary"
                          />
                        )}
                        label={t(ManagementMessages.detailsAdmin)}
                        labelPlacement="start"
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <Autocomplete
                        multiple
                        fullWidth
                        renderTags={(value, getTagProps) => (
                          <LimitedChipsField
                            data={value}
                            limit={2}
                            getTagProps={getTagProps}
                            labelsFormat={(plant: Plant) => plant.name ?? ""}
                            keysFormat={(plant: Plant) => plant.id_plant ?? ""}
                          />
                        )}
                        limitTags={2}
                        disableCloseOnSelect
                        open={openOptionsPlants}
                        onOpen={() => setOpenOptionsPlants(true)}
                        onClose={() => setOpenOptionsPlants(false)}
                        onChange={(event, value) => {
                          setActiveOptionsPlants(value);
                          validations.validateFieldError("plants");
                        }}
                        getOptionSelected={(option, value) => option.id_plant === value.id_plant}
                        getOptionLabel={(option) => option.name as string}
                        options={optionsPlants.sort((a, b) => (b.name ? -b.name.localeCompare(a.name as string) : 0))}
                        disabled={modalType === "details"}
                        loading={loadingOptionsPlants}
                        defaultValue={defineValueAccordingModalType(userDetails.plants, userDetails.plants, [])}
                        renderOption={(option, { selected }) => (
                          <>
                            <Checkbox
                              name="undefined"
                              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                              checkedIcon={<CheckBoxIcon fontSize="small" />}
                              checked={selected}
                            />
                            {option.name}
                          </>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="plants"
                            label={t(ManagementMessages.detailsAuthorizedPlants)}
                            margin="dense"
                            variant="outlined"
                            helperText={t(GlobalMessages.required)}
                            InputLabelProps={{
                              shrink: activeOptionsPlants.length > 0
                                || formRef.current?.getFieldValue("plants").length > 0
                            }}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {loadingOptionsPlants ? <CircularProgress color="inherit" size={20} /> : null}
                                  {params.InputProps.endAdornment}
                                </>
                              )
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        className="password"
                        label={t(ManagementMessages.detailsPassword)}
                        margin="dense"
                        variant="outlined"
                        name="password"
                        type="password"
                        autoComplete="new-password"
                        disabled={modalType === "details"}
                        onChange={() => validations.validateFieldError("password")}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        className="passwordConfirm"
                        label={t(ManagementMessages.detailsConfirmPassword)}
                        margin="dense"
                        variant="outlined"
                        name="passwordConfirm"
                        type="password"
                        disabled={modalType === "details"}
                        onChange={() => validations.validateFieldError("passwordConfirm")}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        className="telegram_id"
                        label={t(ManagementMessages.detailsTelegramId)}
                        margin="dense"
                        variant="outlined"
                        name="telegram_id"
                        InputLabelProps={{
                          shrink: formRef.current?.getFieldValue("telegram_id").length > 0 ? true : undefined
                        }}
                        disabled={modalType === "details"}
                        defaultValue={defineValueAccordingModalType(userDetails.telegram_id, userDetails.telegram_id,
                          "")}
                      />
                    </Grid>
                    <Grid container alignItems="center" xs={12} md={2} lg={4}>
                      {
                        screen.platform === "mobile" ? (
                          <a
                            href="https://www.notion.so/topconsuite/Documentation-Alerts-TELEGRAM-Integration-7f29e2aaf29b4b8cbcd6a007ec696b3c"
                            className="mobileHelperText"
                          >
                            {getHelperTextToTelegram()}
                          </a>
                        ) : (
                          <Tooltip
                            title={(
                              <p className="tooltipText" style={{ fontSize: "12px" }}>
                                {getHelperTextToTelegram()}
                              </p>
                            )}
                          >
                            <IconButton onClick={() => {
                              window.open(
                                "https://www.notion.so/topconsuite/Documentation-Alerts-TELEGRAM-Integration-7f29e2aaf29b4b8cbcd6a007ec696b3c",
                                "_blank"
                              );
                            }}
                            >
                              <InfoIcon
                                height="100%"
                                color={
                                  (
                                    userDetails.sent_alerts_history
                                    && userDetails.sent_alerts_history?.length > 0
                                    && userDetails.sent_alerts_history[0].status === Status.ERROR)
                                    ? "error"
                                    : "disabled"
                                }
                              />
                            </IconButton>
                          </Tooltip>
                        )
                      }
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <Autocomplete
                        multiple
                        fullWidth
                        disableCloseOnSelect
                        renderTags={(value, getTagProps) => (
                          <LimitedChipsField
                            data={value}
                            limit={2}
                            getTagProps={getTagProps}
                            labelsFormat={(permission: TpeAlertWithFunctionality) => permission.description ?? ""}
                            keysFormat={(permission: TpeAlertWithFunctionality) => permission.id_alert_type ?? ""}
                          />
                        )}
                        limitTags={2}
                        value={activeOptionsPermissions}
                        open={openOptionsPermissions}
                        onOpen={() => setOpenOptionsPermissions(true)}
                        onClose={() => setOpenOptionsPermissions(false)}
                        onChange={(event, newValue, reason) => {
                          if (reason === "select-option") {
                            setActiveOptionsPermissions(newValue);
                          } else if (reason === "remove-option") {
                            setCheckAllPermissions(false);
                            setActiveOptionsPermissions(newValue);
                          } else if (reason === "clear") {
                            setCheckAllPermissions(false);
                            setActiveOptionsPermissions([]);
                          }
                          validations.validateFieldError("permissions");
                        }}
                        getOptionLabel={(option) => option.description as string}
                        getOptionSelected={(option, value) => option.description === value.description}
                        options={optionsPermissions.sort(
                          (a, b) => (b.description ? -b.description.localeCompare(a.description as string) : 0)
                        )}
                        disabled={modalType === "details"}
                        loading={loadingOptionsPermissions}
                        groupBy={(option) => (option.isAFunctionality
                          ? t(ManagementMessages.functionalityGroup)
                          : t(ManagementMessages.alertVisualizationGroup))}
                        defaultValue={defineValueAccordingModalType(activeOptionsPermissions, activeOptionsPermissions, [])}
                        PopperComponent={(param) => (
                          <Popper {...param} style={{ border: "1px solid rgba(149,157,165,0.2)" }}>
                            <Box {...param} />
                            <Divider />
                            <Box
                              style={{
                                backgroundColor: "white",
                                height: "45px",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap"
                              }}
                            >
                              <Checkbox
                                name="undefined"
                                checked={checkAllPermissions}
                                onChange={handleCheckAllPermissionsChange}
                                id="check-all"
                                onMouseDown={(event) => event.preventDefault()}
                              />
                              {t(GlobalMessages.selectAll)}
                            </Box>
                          </Popper>
                        )}
                        renderOption={(option, { selected }) => (
                          <>
                            <Checkbox
                              name="undefined"
                              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                              checkedIcon={<CheckBoxIcon fontSize="small" />}
                              checked={selected || checkAllPermissions}
                            />
                            {option.description}
                          </>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="permissions"
                            label={t(ManagementMessages.detailsPermissions)}
                            margin="dense"
                            variant="outlined"
                            InputLabelProps={{
                              shrink: activeOptionsPermissions.length > 0
                                || formRef.current?.getFieldValue("permissions").length > 0
                            }}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {loadingOptionsPermissions ? <CircularProgress color="inherit" size={20} /> : null}
                                  {params.InputProps.endAdornment}
                                </>
                              )
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <DualListBox
                        options={formattedRestrictVehicleOptions}
                        selected={activeRestrictVehicleIds}
                        onChange={(newValue) => {
                          setActiveRestrictVehicleIds(newValue as string[]);
                          setActiveRestrictVehicles(newValue.map(
                            (id) => optionsRestrictVehicles.find(
                              (vehicle) => vehicle.id_vehicle === id
                            ) as Vehicle
                          ));
                        }}
                        disabled={modalType === "details"}
                        canFilter
                        filterPlaceholder={t(DualListboxMessages.filterPlaceholder)}
                        lang={dualListboxLang}
                        showHeaderLabels
                      />
                    </Grid>
                  </Grid>
                </DialogContentText>
              </Form>
              {modalType !== "register" && (
                <ButtonLoading
                  className="btnResendMail"
                  loading={loadingResendConfirmAccountMail}
                  loadingContent="Reenviando email..."
                  onClick={() => resendConfirmAccountMail()}
                >
                  {t(ManagementMessages.detailsResendEmail)}
                </ButtonLoading>
              )}
            </DialogContent>
            <DialogActions className="mFooter">
              <div className="actions">
                {
                  modalType === "details"
                    ? <></>
                    : modalType === "register"
                      ? (
                        <Button
                          disableRipple
                          type="submit"
                          color="primary"
                          onClick={() => formRef.current?.submitForm()}
                        >{t(GlobalMessages.register)}
                        </Button>
                      )
                      : (
                        <Button
                          disableRipple
                          type="submit"
                          color="primary"
                          onClick={() => formRef.current?.submitForm()}
                        >{t(GlobalMessages.save)}
                        </Button>
                      )
                }
              </div>
              <Button disableRipple onClick={() => handleCloseModalForm()} color="primary">{t(GlobalMessages.close)}</Button>
            </DialogActions>
          </Dialog>
        </ContainerModalFormUser>
      </Container>
    </>
  );
};

export default Users;
