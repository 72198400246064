import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactDOMServer from "react-dom/server";

// region Imports - External Libraries
import _ from "lodash";
// endregion Imports - External Libraries
// region Imports - Hooks
import useTranslation from "src/translations/useTranslation";
import { useAuth } from "@hooks/useAuth";
import { useGoogleMaps } from "react-hook-google-maps";
import { useToast } from "@hooks/useToast";
// endregion Imports - Hooks
// region Imports - Utils
import mapDefaultParameters from "@utils/parameters";
import utils from "@utils/useful-functions";
// endregion Imports - Utils
// region Imports - Material-UI
import { CircularProgress, TextField } from "@material-ui/core";
// endregion Imports - Material-UI
// region Imports - Shared
import { MapMessages } from "@shared/languages/interfaces";
import { GoogleApiRequestTypes } from "@shared/constants/google-api-request-types.enum";
import { VehicleStatesTypesID } from "@shared/constants/vehicle-states-types.enum";
import { MapConfig } from "@shared/constants/map.enum";
// endregion Imports - Shared
// region Imports - Services
import api from "@services/api";
// endregion Imports - Services
// region Imports - Styles
import { MapUserDrawingContainer } from "./styles";
// endregion Imports - Styles

// region Types
export type Area =
  | {
  type: "circle";
  location: google.maps.LatLngLiteral;
  radius: number;
}
  | {
  type: "polygon";
  location: google.maps.LatLngLiteral[];
};

type MapUserDrawingProps = {
  mapHeight: number,
  type: "details" | "update" | "register",
  initialArea?: Area,
  returnLocation?: (
    addressData: google.maps.GeocoderResult,
    area: Area
  ) => void
}
// endregion Types

const MapUserDrawing: React.FC<MapUserDrawingProps> = (
  { mapHeight, type, returnLocation, initialArea }
) => {

  // region Constants
  const GoogleApiKey = localStorage.getItem("@Fleet:google");
  const defaultRadius = (initialArea?.type === "circle" ? initialArea.radius : MapConfig.RADIUS) as number;
  // endregion Constants
  // region States
  const [placeAutoComplete, setPlaceAutoComplete] = useState<google.maps.places.Autocomplete>({} as google.maps.places.Autocomplete);
  const [currentOverlay, setCurrentOverlay] = useState<google.maps.Circle | google.maps.Polygon | null>(null);
  const [addressData, setAddressData] = useState<google.maps.GeocoderResult>({} as google.maps.GeocoderResult);
  const [infoWindow, setInfoWindow] = useState<google.maps.InfoWindow>({} as google.maps.InfoWindow);

  const [infoWindowContent, setInfoWindowContent] = useState<string>("");
  // endregion States
  // region Hooks
  const { t, i18n } = useTranslation();
  const { ref, map } = useGoogleMaps(
    `${GoogleApiKey}&libraries=places,drawing&language=${i18n.language}`,
    {
      center: mapDefaultParameters.center,
      mapTypeId: "roadmap",
      zoom: 18
    }
  );
  const { user } = useAuth();
  const { addToast } = useToast();
  // endregion Hooks
  // region Refs
  const isFirstRenderRef = useRef(true);
  const markerRef = useRef<google.maps.Marker>({} as google.maps.Marker);
  const placeAutoCompleteInputRef = useRef<HTMLInputElement>(null);
  const previousOverlayRef = useRef<google.maps.Circle | google.maps.Polygon | null>(currentOverlay);
  // endregion Refs
  // region Functions

  /**
   * Validate if the polygon is valid (at least 3 points)
   * @param polygon Polygon to validate
   */
  const isPolygonValid = (polygon: google.maps.Polygon): boolean => {
    return polygon.getPath().getArray().length >= 3;
  };

  /**
   * Convert polygon to coords
   * @param polygon Polygon to convert
   * @returns Coords of polygon
  */
  const polygonToCoords = (polygon: google.maps.Polygon): google.maps.LatLngLiteral[] => {
    const path = polygon.getPath();
    const coords: google.maps.LatLngLiteral[] = [];

    path.getArray().forEach((vertex) => {
      coords.push({ lat: vertex.lat(), lng: vertex.lng() });
    });

    return coords;
  };

  /**
   * Get centroid of polygon
   * @param path Path of polygon
   */
  const getCentroid = (path: google.maps.LatLngLiteral[]): google.maps.LatLngLiteral => {

    let latSum = 0;
    let lngSum = 0;

    path.forEach((vertex) => {
      latSum += vertex.lat;
      lngSum += vertex.lng;
    });

    return { lat: latSum / path.length ?? 1, lng: lngSum / path.length ?? 1 };
  };

  /**
   * Find address according coords and set info in state
   * @param latLng Coords to search address
   */
  const getAddressByCoord = useCallback(async (latLng) => {

    const geocoder = new google.maps.Geocoder();
    const request = { location: latLng };

    setInfoWindowContent(ReactDOMServer.renderToString(<CircularProgress />));
    if (_.isEmpty(infoWindow)) setInfoWindow(new google.maps.InfoWindow());

    geocoder.geocode(request, (results, status) => {

      if (status === google.maps.GeocoderStatus.OK) {
        if (results[0]) {

          setInfoWindowContent(results[0].formatted_address);
          setAddressData({ ...results[0], ...{ geometry: { location: latLng } } } as google.maps.GeocoderResult);
        }

      } else setInfoWindowContent(t(MapMessages.errorFetchingAddressData));
    });

    try {
      await api.post("/logs/save", {
        context: "Frontend",
        description: GoogleApiRequestTypes.FIXED_POINT_MAP,
        user: {
          name: user.name,
          email: user.email
        }
      });
    } catch (error) {
      console.log(error);
    }

  }, [infoWindow, t, user.name, user.email]);

  /**
   * Pin a marker in map where clicked
   * @param location Location (lat, lng) to marker in map
   */
  const placeMarker = useCallback((location: google.maps.LatLngLiteral, originCallback: "click" | "search" = "click") => {

    if (!_.isEmpty(markerRef.current)) {
      markerRef.current.setPosition(location);
    } else {
      markerRef.current = new google.maps.Marker({
        position: location,
        animation: google.maps.Animation.BOUNCE,
        map
      });
    }

    // If origin callback from search address by click on map, clear input search
    if (originCallback === "click") {
      if (placeAutoCompleteInputRef.current) placeAutoCompleteInputRef.current.value = "";
    }

    // noinspection JSIgnoredPromiseFromCall (It's not necessary to wait the response)
    getAddressByCoord(location);

  }, [map, getAddressByCoord]);

  /**
   * Refresh place marker by new overlay
   * @param overlay Overlay to get the center and set the marker
   */
  const refreshPlaceMarkerByNewOverlay = useCallback((overlay: google.maps.Circle | google.maps.Polygon) => {

    let location: google.maps.LatLngLiteral = { lat: 0, lng: 0 };
    let originCallback: "click" | "search" = "click";

    if (overlay instanceof google.maps.Circle) {
      location = { lat: overlay.getCenter().lat(), lng: overlay.getCenter().lng() };

      const currentRadius = overlay.getRadius();

      // If the radius is equal to the default radius, the origin is the first render or the user probably clicked on the map
      if (currentRadius === defaultRadius) originCallback = "search";
    }

    if (overlay instanceof google.maps.Polygon) location = getCentroid(polygonToCoords(overlay));

    placeMarker(location, originCallback);
  }, [placeMarker, defaultRadius]);

  /**
   * Get place according selected on place autocomplete box
   */
  const placeChange = useCallback(() => {

    const place = placeAutoComplete.getPlace();
    const location: google.maps.LatLngLiteral = {
      lat: place.geometry?.location.lat() as number,
      lng: place.geometry?.location.lng() as number
    };

    // If pass a coordinates in search box, split the string by ',' and search the address
    if (!place.geometry?.location.lat()) {
      location.lat = Number(place.name.split(",")[0]);
      location.lng = Number(place.name.split(",")[1]);
    }

    map?.setCenter(new google.maps.LatLng(location.lat, location.lng));

    previousOverlayRef.current?.setMap(null);

    setCurrentOverlay(new google.maps.Circle({
      center: location,
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: utils.getStateColor(VehicleStatesTypesID.EM_LOCAL_CONFIAVEL),
      fillOpacity: 0.35,
      editable: type !== "details",
      draggable: type !== "details",
      radius: defaultRadius,
      map
    }));

  }, [defaultRadius, map, placeAutoComplete, type]);
  // endregion Functions
  // region Effects

  // Init map, places autocomplete and marker
  useEffect(() => {
    // Init map
    if (map && isFirstRenderRef.current) {
      let initialDrawingMode: google.maps.drawing.OverlayType | null = google.maps.drawing.OverlayType.CIRCLE;

      // Get current position of user and set in the center of map or the parameter coords
      if (initialArea?.type === "circle") {

        // Set the initial drawing mode to null (already has a location)
        initialDrawingMode = null;

        map.setCenter(new google.maps.LatLng(initialArea.location));
        setCurrentOverlay(new google.maps.Circle({
          center: initialArea.location,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: utils.getStateColor(VehicleStatesTypesID.EM_LOCAL_CONFIAVEL),
          fillOpacity: 0.35,
          editable: type !== "details",
          draggable: type !== "details",
          radius: defaultRadius,
          map
        }));

      } else if (initialArea?.type === "polygon") {
        // Set the initial drawing mode to null (already has a location)
        initialDrawingMode = null;

        setCurrentOverlay(new google.maps.Polygon({
          paths: initialArea.location,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: utils.getStateColor(VehicleStatesTypesID.EM_LOCAL_CONFIAVEL),
          fillOpacity: 0.35,
          editable: type !== "details",
          draggable: type !== "details",
          map
        }));

        map.setCenter(getCentroid(initialArea.location));
      } else {
        navigator.geolocation.getCurrentPosition((position) => {
          if (map.getCenter().lat() === 0) map.setCenter(new google.maps.LatLng(position.coords.latitude, position.coords.longitude));
        });
      }

      // Init autocomplete
      const autoComplete = new google.maps.places.Autocomplete(placeAutoCompleteInputRef.current as HTMLInputElement, {
        fields: ["formatted_address", "geometry", "name"],
        strictBounds: false,
        types: []
      });

      setTimeout(() => { setPlaceAutoComplete(autoComplete); }, 50);

      // Initialize DrawingManager
      if (google?.maps?.drawing && type !== "details") {
        const drawingManager = new google.maps.drawing.DrawingManager({
          drawingMode: initialDrawingMode,
          drawingControl: true,
          drawingControlOptions: {
            position: google.maps.ControlPosition.TOP_CENTER,
            drawingModes: [google.maps.drawing.OverlayType.CIRCLE, google.maps.drawing.OverlayType.POLYGON]
          },
          circleOptions: {
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: utils.getStateColor(VehicleStatesTypesID.EM_LOCAL_CONFIAVEL),
            fillOpacity: 0.35,
            editable: true,
            draggable: true
          },
          polygonOptions: {
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: utils.getStateColor(VehicleStatesTypesID.EM_LOCAL_CONFIAVEL),
            fillOpacity: 0.35,
            editable: true,
            draggable: true
          }
        });

        // Add click marker as event
        google.maps.event.addListener(
          drawingManager,
          "overlaycomplete",
          (event: google.maps.drawing.OverlayCompleteEvent) => {

            if (event.type === google.maps.drawing.OverlayType.POLYGON && !isPolygonValid(event.overlay as google.maps.Polygon)) {
              addToast({title: t(MapMessages.invalidPolygon), type: "error"});

              event.overlay.setMap(null);

              return;
            }

            previousOverlayRef.current?.setMap(null);

            setCurrentOverlay(event.overlay as google.maps.Circle | google.maps.Polygon);

            // Remove drawing tool from user (the drawing is already done)
            drawingManager.setDrawingMode(null);
          }
        );

        drawingManager.setMap(map);

        isFirstRenderRef.current = false;
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, initialArea, type, placeMarker, refreshPlaceMarkerByNewOverlay]);

  // Set events of Places autocomplete
  useEffect(() => {

    let listenerPlaceChange: google.maps.MapsEventListener;
    const listenerAutocompleteTypes: {name: string, listener: any}[] = [] as {name: string, listener: any}[];

    if (!_.isEmpty(placeAutoComplete)) {

      // Bind the map's bounds (viewport) property to the autocomplete object,
      // so that the autocomplete requests use the current map bounds for the
      // bounds option in the request.
      placeAutoComplete.bindTo("bounds", map);

      // Set event listener of change address in search
      listenerPlaceChange = placeAutoComplete.addListener("place_changed", () => placeChange());
    }

    return () => {
      google.maps.event.removeListener(listenerPlaceChange);
      listenerAutocompleteTypes.forEach((element) => {
        document.getElementById(element.name)?.removeEventListener("click", element.listener);
      });
    };

  }, [map, placeAutoComplete, placeChange]);

  // Update the info window with the updated address set on the map
  useEffect(() => {

    // Set content of infoWindow and open
    if (!_.isEmpty(infoWindow)) {
      infoWindow.setContent(infoWindowContent.replace(/,/g, "<br>"));
      infoWindow.open(map, markerRef.current);
    }

    return () => {
      if (!_.isEmpty(infoWindow)) infoWindow.close();
    };

  }, [infoWindow, infoWindowContent, map]);

  // Update the overlay of map
  useEffect(() => {
    if (!currentOverlay || _.isEqual(previousOverlayRef.current, currentOverlay)) return;

    previousOverlayRef.current = currentOverlay;

    if (currentOverlay instanceof google.maps.Circle) {
      console.log("radius", currentOverlay.getRadius());
      currentOverlay.addListener("dragend", () => refreshPlaceMarkerByNewOverlay(currentOverlay));
      currentOverlay.addListener("radius_changed", () => refreshPlaceMarkerByNewOverlay(currentOverlay));
    }

    if (currentOverlay instanceof google.maps.Polygon) {
      let isDragging = false;

      currentOverlay.addListener("dragstart", () => isDragging = true);
      currentOverlay.addListener("dragend", () => {
        isDragging = false;
        refreshPlaceMarkerByNewOverlay(currentOverlay);
      });

      const path = currentOverlay.getPath();

      path.addListener("insert_at", () => {
        if (!isDragging) refreshPlaceMarkerByNewOverlay(currentOverlay);
      });

      path.addListener("remove_at", () => {
        if (!isDragging) refreshPlaceMarkerByNewOverlay(currentOverlay);
      });

      path.addListener("set_at", () => {
        if (!isDragging) refreshPlaceMarkerByNewOverlay(currentOverlay);
      });
    }

    refreshPlaceMarkerByNewOverlay(currentOverlay);

  }, [currentOverlay, refreshPlaceMarkerByNewOverlay]);

  // Update the address data and location and return to father component
  useEffect(() => {

    if (!_.isEmpty(addressData) && !_.isEmpty(currentOverlay) && returnLocation && type !== "details") {

      if (currentOverlay instanceof google.maps.Circle) {
        returnLocation(
          addressData,
          { type: "circle", location: currentOverlay.getCenter().toJSON(), radius: _.round(currentOverlay.getRadius(), 6) }
        );
      } else {
        returnLocation(addressData, { type: "polygon", location: polygonToCoords(currentOverlay as google.maps.Polygon) });
      }
    }

    // eslint-disable-next-line
  }, [addressData]);
  // endregion Effects

  return (
    <MapUserDrawingContainer>
      {type !== "details"
        && (
          <div className="pac-card" id="pac-card">
            <div>
              <div id="title">{t(MapMessages.locationSearchTitle)}</div>
            </div>
            <div id="pac-container">
              <TextField
                inputRef={placeAutoCompleteInputRef}
                id="pac-input"
                label={t(MapMessages.typeLocationLabel)}
                variant="outlined"
                margin="dense"
              />
            </div>
          </div>
        )}
      <div className="map" ref={ref} style={{ width: "100%", height: mapHeight }} />
    </MapUserDrawingContainer>
  );

};

export default MapUserDrawing;
