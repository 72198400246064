export enum GlobalMessages {
  dialogLandscape = "global.dialogLandscape",
  loading = "global.loading",
  noNameRegistered = "global.noNameRegistered",
  noAccess = "global.noAccess",
  actionNotPermitted = "global.actionNotPermitted",
  success = "global.success",
  alert = "global.alert",
  error = "global.error",
  connectionNotEstablished = "global.connectionNotEstablished",
  close = "global.close",
  confirm = "global.confirm",
  confirmTravel = "global.confirmTravel",
  model = "global.model",
  speed = "global.speed",
  fullSpeed = "global.fullSpeed",
  duration = "global.duration",
  address = "global.address",
  travelDetails = "global.travelDetails",
  registerLocation = "global.registerLocation",
  required = "global.required",
  date = "global.date",
  start = "global.start",
  time = "global.time",
  end = "global.end",
  noData = "global.noData",
  noRegisteredClients = "global.RegisteredClient",
  type = "global.type",
  vehicle = "global.vehicle",
  driver = "global.driver",
  observations = "global.observations",
  register = "global.register",
  yes = "global.yes",
  no = "global.no",
  noClientRegistered = "global.noClientRegistered",
  quantity = "global.quantity",
  save = "global.save",
  active = "global.active",
  inactive = "global.inactive",
  selectAll = "global.selectAll",
  search = "global.search",
  apply = "global.apply",
  other = "global.other",
  internalErrorMessage = "global.internalErrorMessage",
  alreadyRegistered = "global.alreadyRegistered",
  notRegistered = "global.notRegistered",
  verifyFieldError = "global.verifyFieldError",
  wrongIDFormat = "global.wrongIDFormat",
  filter = "global.filter",
  edit = "global.edit",
  exceeded = "global.exceeded",
  exceededPT = "global.exceededPT",
  exceededBy = "global.exceededAt",
  onTheWay = "global.onTheWay",
  invalidFilter = "global.invalidFilter",
  moment = "global.moment",
  back = "global.back",
  updateRegister = "global.updateRegister",
  errorLoadingGoogleMaps = "global.errorLoadingGoogleMaps"
}

export type TranslationGlobalMessages = {
  [key in GlobalMessages]: string;
}
